.catalog {
    &__title {
        margin-bottom: 48px;
        @include m {
            margin-bottom: 32px; } }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: -32px -16px 0;
        @include m {
            display: block;
            margin: 0; } }
    &__list .card {
        flex: 0 0 calc(33.333% - 32px);
        width: calc(33.333% - 32px);
        margin: 32px 16px 0;
        @include t {
            flex: 0 0 calc(50% - 32px);
            width: calc(50% - 32px); }
        @include m {
            width: 100%;
            margin: 32px 0 0; } }
    &__cards {
        display: flex;
        flex-wrap: wrap;
        margin: -32px -16px 0;
        @include m {
            display: block;
            margin: 0; } }
    &__cards .card {
        flex: 0 0 calc(50% - 32px);
        width: calc(50% - 32px);
        margin: 32px 16px 0;
        @include x {
            flex: 0 0 calc(33.333% - 32px);
            width: calc(33.333% - 32px); }
        @include t {
            flex: 0 0 calc(50% - 32px);
            width: calc(50% - 32px); }
        @include m {
            width: 100%;
            margin: 32px 0 0; }
        &:nth-child(n+10) {
            @include x {
                display: none; }
            @include t {
                display: block; } } }
    &__btns {
        margin-top: 48px;
        text-align: center;
        @include m {
            margin-top: 32px; } }
    &__btns &__button {
        .loader {
            margin: 0 16px 0 2px;
            transform: scale(.8); } } }
