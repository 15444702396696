.upload {
    .control {
        margin-bottom: 80px;
        @include t {
            margin-bottom: 48px; } }
    &__inner {
        display: flex;
        align-items: flex-start;
        @include t {
            display: block; } }
    &__wrapper {
        flex: 0 0 calc(100% - 352px);
        width: calc(100% - 352px);
        padding-right: 128px;
        @include x {
            padding-right: 64px; }
        @include d {
            flex: 0 0 calc(100% - 304px);
            width: calc(100% - 304px);
            padding-right: 32px; }
        @include t {
            width: 100%;
            padding: 0; } }
    &__preview {
        flex-shrink: 0;
        width: 352px;
        @include d {
            width: 304px; }
        @include t {
            display: flex;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 48px;
            background: rgba($n1,.9);
            overflow: auto;
            z-index: 999;
            visibility: hidden;
            opacity: 0;
            transition: all .4s;
            @include dark {
                background: rgba($n2,.9); }
            &.visible {
                visibility: visible;
                opacity: 1; } }
        @include m {
            padding: 32px 16px; } }
    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        @include d {
            flex-direction: column-reverse;
            align-items: flex-start; }
        @include m {
            margin-bottom: 32px; }
        .upload__button {
            flex-shrink: 0;
            margin-left: 24px;
            @include d {
                margin: 0 0 16px; }
            @include dark {
                box-shadow: inset 0 0 0 2px $n3; } } }
    &__title {
        margin-right: auto;
        @include m {
            font-size: 32px; } }
    &__list {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid $n6;
        @include m {
            margin-bottom: 32px;
            padding-bottom: 32px; }
        @include dark {
            border-color: $n3; } }
    &__item {
        &:not(:last-child) {
            margin-bottom: 40px;
            @include m {
                margin-bottom: 32px; } } }
    &__note {
        margin-top: 4px;
        @include caption-2;
        color: $n4; }
    &__file {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 182px;
        margin-top: 16px;
        border-radius: 16px;
        overflow: hidden;
        background: $n7;
        @include dark {
            background: $n2; } }
    &__input {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 400px;
        opacity: 0; }
    &__icon {
        margin-bottom: 10px;
        .icon {
            width: 24px;
            height: 24px;
            fill: $n4; } }
    &__format {
        @include caption-2;
        color: $n4; }
    &__category {
        @include body-bold-2; }
    &__item {
        .upload__button {
            .icon {
                width: 16px;
                height: 16px; }
            @include dark {
                box-shadow: inset 0 0 0 2px $n3; } }
        &:not(:first-child) {
            .upload__category {
                margin-bottom: 24px; } } }
    &__fieldset {
        .field {
            &:not(:last-child) {
                margin-bottom: 24px;
                @include m {
                    margin-bottom: 20px; } } } }
    &__label {
        margin-bottom: 12px;
        @include hairline-2;
        color: $n5; }
    &__row {
        display: flex;
        margin: 0 -10px;
        @include m {
            display: block;
            margin: 0; }
        &:not(:last-child) {
            margin-bottom: 24px;
            @include m {
                margin-bottom: 20px; } } }
    &__col {
        margin: 0 10px;
        &_w30 {
            flex: 0 0 calc(30% - 20px);
            width: calc(30% - 20px);
            @include m {
                width: 100%;
                margin: 0;
                &:not(:last-child) {
                    margin-bottom: 20px; } } }
        &_w33 {
            flex: 0 0 calc(33.333% - 20px);
            width: calc(33.333% - 20px);
            @include m {
                width: 100%;
                margin: 0;
                &:not(:last-child) {
                    margin-bottom: 20px; } } }
        &_w50 {
            flex: 0 0 calc(50% - 20px);
            width: calc(50% - 20px);
            @include m {
                width: 100%;
                margin: 0;
                &:not(:last-child) {
                    margin-bottom: 20px; } } }
        &_w70 {
            flex: 0 0 calc(70% - 20px);
            width: calc(70% - 20px);
            @include m {
                width: 100%;
                margin: 0;
                &:not(:last-child) {
                    margin-bottom: 20px; } } } }
    &__line {
        display: flex;
        height: 48px;
        box-shadow: inset 0 0 0 2px $n6;
        border-radius: 12px;
        align-items: center;
        @include dark {
            box-shadow: inset 0 0 0 2px $n3; } }

    &__cell {
        flex: 1;
        .field {
            width: 100%; }
        &:not(:last-child) {
            border-right: 1px solid $n6;
            @include dark {
                border-color: $n3; } } }
    &__foot {
        display: flex;
        align-items: center;
        @include m {
            display: block;
            text-align: center; }
        .upload__button {
            .icon {
                width: 10px;
                height: 10px; }
            @include m {
                width: 100%; }
            &:first-child {
                display: none;
                @include t {
                    display: inline-block; } }
            &:not(:last-child) {
                margin-right: 24px;
                @include m {
                    margin: 0 0 12px; } } } }
    &__saving {
        display: inline-flex;
        align-items: center;
        margin-left: auto;
        @include button-1;
        .loader {
            margin-left: 24px; } }
    &__wrap {
        @include t {
            position: relative;
            max-width: 455px;
            margin: auto;
            padding: 24px;
            border-radius: 24px;
            background: $n8; }
        @include m {
            padding: 24px 16px; }
        @include dark {
            background: $n1; } }
    &__subtitle {
        margin-bottom: 40px;
        @include body-bold-1; }
    &__close {
        display: none;
        position: absolute;
        top: 20px;
        right: 24px;
        width: 40px;
        height: 40px;
        background: $n8;
        border: 2px solid $n6;
        border-radius: 50%;
        font-size: 0;
        @include t {
            display: inline-block; }
        .icon {
            width: 14px;
            height: 14px;
            fill: $n2;
            transition: transform .2s; }
        @include dark {
            border-color: $n3;
            background: $n1;
            .icon {
                fill: $n8; } } }
    .card {
        box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08); } }

