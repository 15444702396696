.checkout {
    .control {
        margin-bottom: 80px;
        @include t {
            margin-bottom: 48px; } }
    &__wrapper {
        display: flex;
        align-items: flex-start;
        @include t {
            flex-direction: column-reverse; } }
    &__inner {
        flex-grow: 1;
        padding-right: 80px;
        @include x {
            padding-right: 48px; }
        @include d {
            padding-right: 32px; }
        @include t {
            width: 100%;
            padding: 0; } }
    &__title {
        margin-bottom: 32px;
        padding-bottom: 32px;
        border-bottom: 1px solid $n6;
        @include dark {
            border-color: $n2; } }
    &__data {
        margin-top: 24px;
        &_flex {
            display: flex;
            margin-left: -8px;
            margin-right: -8px;
            @include m {
                display: block;
                margin-left: 0;
                margin-right: 0; } } }
    &__data_flex &__el {
        flex: 0 0 calc(50% - 16px);
        width: calc(50% - 16px);
        margin: 0 8px;
        @include m {
            width: 100%;
            margin: 0;
            @include nl {
                margin-bottom: 12px; } } }
    &__datepicker {
        position: relative;
        padding: 8px 56px 8px 16px;
        background: $n7;
        border-radius: 12px;
        @include dark {
            background: $n2; } }
    &__label {
        @include caption-2;
        color: $n4; }
    &__input {
        width: 100%;
        height: 24px;
        background: none;
        box-shadow: none;
        border-radius: 0;
        @include poppins;
        @include body-bold-2;
        color: $n2;
        pointer-events: none;
        @include dark {
            color: $n8; } }
    &__controls {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%); }
    &__edit {
        .icon {
            width: 24px;
            height: 24px;
            fill: $n5;
            transition: fill .2s; }
        &:hover {
            .icon {
                fill: $n4; } }
        &:nth-child(2) {
            display: none;
            .icon {
                fill: $p1; } } }
    &__section {
        &:not(:first-child) {
            margin-top: 48px;
            padding-top: 48px;
            border-top: 1px solid $n6;
            @include dark {
                border-color: $n2; } } }
    &__category {
        @include body-bold-1; }
    &__box {
        & > .category {
            margin-bottom: 24px; }
        &:not(:last-child) {
            margin-bottom: 32px; } }
    &__top {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        & > .checkout__category {
            margin-right: auto; } }
    &__group {
        display: flex;
        margin: 0 -8px;
        @include m {
            display: block; } }
    &__line {
        display: flex;
        align-items: center;
        margin-bottom: 40px; }
    &__subtitle {
        margin-right: auto;
        @include body-bold-2; }
    &__cards {
        display: flex;
        align-items: center; }
    &__item {
        display: none; }
    &__fieldset {
        & > .field {
            margin-bottom: 32px; } }
    &__row {
        display: flex;
        margin: 0 -8px;
        .field {
            flex: 0 0 calc(50% - 16px);
            width: calc(50% - 16px);
            margin: 0 8px; } }
    .checkbox {
        margin-top: 32px; }
    &__message {
        margin-top: 24px;
        .checkout__category {
            margin-bottom: 24px; } }
    &__item > &__button {
        margin-top: 32px; }
    &__logo {
        img {
            max-height: 24px; } }
    &__control {
        display: flex;
        align-items: center;
        .checkout__button {
            margin: 0 16px 0 0; } }
    &__verified {
        img {
            max-height: 48px; } }
    .date-picker-wrapper {
        top: calc(100% + 16px) !important;
        bottom: auto !important;
        @include m {
            top: 100% !important;
            bottom: auto !important; } }
    &_stays .price {
        flex-shrink: 0;
        width: 544px;
        @include x {
            width: 500px; }
        @include d {
            width: 416px; }
        @include t {
            width: 100%;
            margin-bottom: 48px; } }
    &_tasks .price,
    &_cars .price {
        flex-shrink: 0;
        width: 448px;
        @include d {
            width: 416px; }
        @include t {
            width: auto;
            margin-bottom: 48px; } } }

