.reviews {
    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        @include m {
            display: block; } }
    &__title {
        margin-right: auto;
        @include body-bold-1;
        @include m {
            margin: 0 0 32px; } }
    &__item {
        display: flex;
        padding-top: 32px;
        border-top: 1px solid $n6;
        @include dark {
            border-color: $n2; }
        &:not(:first-child) {
            margin-top: 32px; } }
    &__avatar {
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        margin-right: 20px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover; } }
    &__details {
        flex-grow: 1; }
    &__author {
        margin-bottom: 4px;
        font-weight: 500;
        color: $n1;
        @include dark {
            color: $n8; } }
    &__content {
        margin-bottom: 8px;
        color: $n3;
        @include dark {
            color: $n4; } }
    &__foot {
        display: flex;
        align-items: center; }
    &__time {
        @include caption-2;
        color: $n4; }
    &__actions {
        display: flex; }
    &__action {
        margin-left: 16px;
        @include poppins;
        @include caption-bold-2;
        transition: color .2s;
        &:hover {
            color: $p1; }
        @include dark {
            color: $n8;
            &:hover {
                color: $p1; } } } }

