.travelers {
    position: relative;
    &.active {
        overflow: visible;
        .travelers__head {
            box-shadow: 0px 32px 32px 0 rgba(15, 15, 15, 0.08);
            @include t {
                box-shadow: none; }
            @include dark {
                background: $n1;
                @include t {
                    background: none; } } }
        .travelers__body {
            visibility: visible;
            opacity: 1; }
        .travelers__edit {
            .icon {
                fill: $p1; } } }
    &__head {
        position: relative;
        border-radius: 16px;
        user-select: none;
        transition: all .2s; }
    &__top {
        padding: 8px 56px 8px 16px;
        background: $n7;
        border-radius: 12px;
        @include dark {
            background: $n2; } }
    &__label {
        @include caption-2;
        color: $n4; }
    &__value {
        @include body-bold-2; }
    &__edit {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        .icon {
            width: 24px;
            height: 24px;
            fill: $n5;
            transition: fill .2s; }
        &:hover {
            .icon {
                fill: $n4; } } }
    &__content {
        display: flex;
        width: 100%;
        height: 96px;
        padding: 20px 116px 0 56px;
        border-radius: 16px;
        @include text-overflow;
        @include body-bold-1;
        color: $n2;
        cursor: pointer;
        @include x {
            padding-right: 96px; }
        @include d {
            padding: 25px 96px 0 48px;
            font-size: 18px; }
        @include t {
            height: 64px;
            padding: 12px 64px 0 0; }
        @include m {
            padding-left: 32px; }
        @include dark {
            color: $n8; } }
    &__icon {
        position: absolute;
        top: 20px;
        left: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        pointer-events: none;
        @include d {
            left: 10px; }
        @include t {
            display: none; }
        @include m {
            top: 8px;
            left: 0;
            display: flex;
            width: 24px; }
        .icon {
            width: 24px;
            height: 24px;
            fill: $n5;
            @include d {
                width: 20px;
                height: 20px; } } }
    &__description {
        position: absolute;
        left: 56px;
        bottom: 20px;
        pointer-events: none;
        @include body-2;
        color: $n4;
        @include d {
            left: 48px;
            font-size: 14px; }
        @include t {
            left: 0;
            bottom: 4px; }
        @include m {
            left: 32px; } }
    &__body {
        position: absolute;
        right: 0;
        bottom: calc(100% + 16px);
        z-index: 10;
        width: 426px;
        padding: 16px 40px;
        box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
        border-radius: 24px;
        background: $n8;
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        @include d {
            width: 350px;
            padding: 8px 24px; }
        @include t {
            box-shadow: 0px 16px 32px rgba(15, 15, 15, 0.12); }
        @include m {
            bottom: 100%;
            left: -24px;
            right: -24px;
            width: auto; }
        @include dark {
            background: $n1; } }
    &__item {
        display: flex;
        align-items: flex-start;
        padding: 24px 0 16px;
        @include m {
            padding: 16px 0 12px; }
        &:not(:last-child) {
            border-bottom: 1px solid $n6;
            @include dark {
                border-color: $n3; } } }
    &__details {
        flex-grow: 1;
        padding-right: 24px; }
    &__category {
        @include body-bold-2; }
    &__text {
        @include caption-2;
        color: $n4; }
    &_small &__content {
        height: 72px;
        padding: 0 116px 0 64px;
        line-height: 72px;
        @include d {
            padding: 0 92px 0 48px; }
        @include t {
            height: 64px;
            padding: 0 72px 0 0;
            line-height: 66px; }
        @include m {
            padding-left: 32px; } }
    &_small &__icon {
        @include m {
            top: 50%;
            transform: translateY(-50%); } }
    &_down &__body {
        top: calc(100% + 16px);
        bottom: auto;
        @include m {
            top: 100%;
            bottom: auto; } } }


