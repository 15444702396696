.support {
    position: relative;
    padding: 308px 0 376px;
    overflow: hidden;
    @include d {
        padding: 252px 0; }
    @include m {
        padding: 200px 0; }
    &__center {
        max-width: 746px; }
    &__title {
        margin-bottom: 16px;
        text-align: center; }
    &__info {
        max-width: 482px;
        margin: 0 auto 32px;
        text-align: center;
        @include body-2;
        color: $n4; }
    .subscription {
        box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
        @include t {
            max-width: 480px;
            margin: 0 auto; } }
    &__preview {
        position: absolute;
        z-index: -1;
        &:first-child {
            top: 28px;
            left: calc(50% - 790px);
            @include m {
                top: -50px;
                left: calc(50% - 240px);
                img {
                    width: 300px; } } }
        &:nth-child(2) {
            top: 45px;
            right: calc(50% - 640px);
            @include d {
                top: 0; }
            @include m {
                top: 45px;
                right: calc(50% - 310px);
                img {
                    width: 300px; } } }
        &:nth-child(3) {
            right: calc(50% - 740px);
            bottom: 100px; }
        &:nth-child(4) {
            left: calc(50% - 360px);
            bottom: 80px;
            @include d {
                bottom: 10px; }
            @include m {
                left: calc(50% - 200px);
                img {
                    width: 300px; } } } } }

