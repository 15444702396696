.user {
    &__body {
        position: relative;
        z-index: 3;
        padding: 80px 0 136px;
        @include d {
            padding-bottom: 112px; }
        @include t {
            padding: 0 0 92px; }
        @include m {
            padding: 0 0 64px; } }
    &__center {
        display: flex;
        align-items: flex-start;
        @include t {
            display: block; } }
    .profile {
        flex-shrink: 0;
        width: 352px;
        margin-top: -193px;
        text-align: center;
        @include t {
            width: auto;
            margin: 0 -16px 48px; } }
    &__wrapper {
        flex-grow: 1;
        padding-left: 80px;
        @include d {
            padding-left: 48px; }
        @include t {
            padding-left: 0; } }
    &__details {
        margin-bottom: 64px;
        @include m {
            margin-bottom: 40px; } }
    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        @include m {
            flex-direction: column-reverse;
            align-items: flex-start;
            margin-bottom: 24px; } }
    &__title {
        margin-right: auto;
        @include body-bold-1;
        @include m {
            margin: 24px 0 0; } }
    &__content {
        margin-bottom: 32px;
        color: $n4; }
    &__option {
        display: flex;
        align-items: flex-start;
        &:not(:last-child) {
            margin-bottom: 20px; } }
    &__category {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: 152px;
        padding-right: 24px;
        color: $n4;
        @include m {
            width: 130px; }
        .icon {
            width: 20px;
            height: 20px;
            margin-right: 12px;
            fill: $n4; } }
    &__text {
        font-weight: 500; } }






