.card {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    overflow: hidden;
    background: $n8;
    border: 1px solid $n6;
    color: $n1;
    transition: border-color .2s;
    &:hover {
        border-color: $n5;
        .card__preview {
            img {
                transform: scale(1.1); } } }
    @include dark {
        background: $n1;
        border-color: $n3;
        color: $n8; }
    &__preview {
        position: relative;
        overflow: hidden;
        &:before {
            content: "";
            display: block;
            padding-bottom: 68.6%; }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 1s;
            @include m {
                border-radius: 24px 24px 0 0; } } }
    &__category {
        position: absolute;
        top: 16px;
        left: 16px;
        z-index: 2; }
    &__body {
        flex-grow: 1;
        padding: 24px 22px; }
    &__line {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 8px; }
    &__title {
        @include body-bold-2; }
    &__price {
        margin-left: 16px;
        padding: 6px 8px;
        box-shadow: inset 0 0 0 2px $p4;
        border-radius: 4px;
        text-align: center;
        @include hairline-2;
        color: $p4; }
    &__old {
        margin-bottom: 6px;
        text-decoration: line-through;
        color: $n5; }
    &__date {
        margin-right: 20px;
        @include caption-2;
        color: $n4; }
    &__options {
        display: flex;
        flex-wrap: wrap;
        margin: -8px 0 16px -12px; }
    &__option {
        display: flex;
        margin: 8px 0 0 12px;
        @include caption-2;
        color: $n4;
        .icon {
            flex-shrink: 0;
            width: 16px;
            height: 16px;
            margin: 1px 6px 0 0;
            fill: $n4; } }
    &__foot {
        padding-top: 16px;
        border-top: 1px solid $n6;
        @include dark {
            border-color: $n2; } }
    &__comment {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        @include caption-2;
        color: $n4; }
    &__avatar {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        margin-right: 12px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover; } }
    &__flex {
        display: flex;
        justify-content: space-between; }
    &__cost {
        @include caption-bold-2; }
    &__rating {
        display: flex;
        align-items: center;
        .icon {
            width: 12px;
            height: 12px;
            margin-right: 4px;
            fill: $s3; } }
    &__number {
        margin-right: 4px;
        @include caption-bold-2; }
    &__reviews {
        @include caption-2;
        color: $n4; }
    &_row {
        flex-direction: row;
        border-radius: 16px;
        @include x {
            display: block; }
        .card__preview {
            flex-shrink: 0;
            width: 256px;
            @include x {
                width: 100%; }
            &:before {
                display: none;
                @include x {
                    display: block; } }
            img {
                @include m {
                    border-radius: 16px 16px 0 0; } } }
        .card__body {
            display: flex;
            flex-direction: column;
            flex-grow: 1; }
        .card__line {
            margin-bottom: 16px; }
        .card__price {
            display: flex;
            flex-direction: column-reverse; }
        .card__old {
            margin: 6px 0 0;
            text-decoration: none; }
        .card__options {
            flex-wrap: nowrap;
            @include x {
                flex-wrap: wrap; } }
        .card__option {
            &:nth-child(2) {
                flex-shrink: 0; } }
        .card__foot {
            margin-top: auto; } }
    &_car {
        .card__line {
            margin-bottom: 0;
            @include d {
                margin-bottom: 8px; } }
        .card__price {
            display: flex;
            flex-direction: column-reverse; }
        .card__old {
            margin: 6px 0 0;
            text-decoration: none; }
        .card__option {
            &:nth-child(2) {
                flex-shrink: 0; } } } }


