.places {
    &__center {
        max-width: 1440px;
        @include w {
            padding: 0 40px; }
        @include t {
            padding: 0; } }
    &__inner {
        padding: 112px 80px;
        background: $n7;
        border-radius: 24px;
        @include w {
            padding: 80px 64px; }
        @include x {
            padding: 64px 40px; }
        @include t {
            border-radius: 0; }
        @include m {
            padding: 64px 24px; }
        @include dark {
            background: #18191D;
            box-shadow: inset 0 0 0 1px $n3; } }
    &__head {
        margin-bottom: 80px;
        text-align: center;
        @include d {
            margin-bottom: 64px; }
        @include m {
            margin-bottom: 40px; } }
    &__title {
        margin-bottom: 12px; }
    &__slider {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -64px -10px 0;
        @include x {
            margin: -48px -16px 0; }
        @include m {
            display: block;
            margin: 0;
            padding-bottom: 80px;
            visibility: hidden;
            &.slick-initialized {
                visibility: visible; } } }
    &__item {
        flex: 0 0 calc(20% - 48px);
        width: calc(20% - 48px);
        margin: 64px 24px 0;
        padding: 8px 8px 24px;
        border-radius: 16px;
        background: $n8;
        color: $n2;
        transition: all .2s;
        @include x {
            flex: 0 0 calc(20% - 32px);
            width: calc(20% - 32px);
            margin: 48px 16px 0; }
        @include d {
            flex: 0 0 calc(25% - 32px);
            width: calc(25% - 32px); }
        @include t {
            flex: 0 0 calc(33.333% - 32px);
            width: calc(33.333% - 32px); }
        @include m {
            display: block;
            width: 100%;
            margin: 0; }
        &:hover {
            box-shadow: inset 0 0 0 1px $n5; }
        @include dark {
            background: $n2;
            &:hover {
                box-shadow: inset 0 0 0 1px $n3; } }
        &:nth-child(n+8) {
            @include d {
                display: none; }
            @include t {
                display: block; } } }
    &__preview {
        position: relative;
        width: 80px;
        margin: 0 auto 16px;
        border-radius: 50%;
        overflow: hidden;
        &:before {
            content: '';
            display: block;
            padding-bottom: 100%; }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__number {
        margin-bottom: 16px; }
    &__subtitle {
        margin-bottom: 4px;
        text-align: center;
        @include body-bold-2;
        @include dark {
            color: $n8; } }
    &__time {
        text-align: center;
        @include caption-2;
        color: $n4; }
    .slick-list {
            overflow: visible;
            @include m {
                margin: 0 -144px 0 0; } }
    .slick-slide {
        margin: 0 8px;
        opacity: 0;
        visibility: hidden;
        transition: all .4s;
        &.slick-active {
            opacity: 1;
            visibility: visible; } }
    .slick-arrow {
       bottom: 0; }
    .slick-prev {
        left: calc(50% - 44px); }
    .slick-next {
        right: calc(50% - 44px); } }
