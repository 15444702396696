.adventure {
    &__head {
        margin-bottom: 64px;
        text-align: center;
        @include m {
            margin-bottom: 32px; } }
    &__wrapper {
        margin: 0 -16px; }
    &__slider {
        padding-bottom: 106px;
        visibility: hidden;
        @include d {
            padding-bottom: 80px; }
        &.slick-initialized {
            visibility: visible; } }
    &__item {
        display: flex !important;
        align-items: center; }
    &__preview {
        flex-shrink: 0;
        width: 160px;
        @include d {
            width: 112px; }
        img {
            width: 100%; } }
    &__details {
        flex-grow: 1;
        padding: 0 24px 0 16px;
        @include x {
            padding-right: 0; }
        @include d {
            padding: 0 0 0 16px; } }
    &__subtitle {
        margin-bottom: 8px;
        @include body-bold-2; }
    .slick-list {
        overflow: visible;
        @include s {
            margin: 0 -265px 0 0; } }
    .slick-slide {
        margin: 0 16px;
        opacity: 0;
        visibility: hidden;
        transition: all .4s;
        &.slick-active {
            opacity: 1;
            visibility: visible; } }
    .slick-arrow {
        bottom: 0; }
    .slick-prev {
        left: calc(50% - 44px); }
    .slick-next {
        right: calc(50% - 44px); } }

