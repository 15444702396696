.best {
    &__center {
        max-width: 1440px;
        @include w {
            padding: 0 40px; }
        @include m {
            padding: 0; } }
    &__inner {
        padding: 80px 80px 100px;
        background: $n7;
        border-radius: 24px;
        @include w {
            padding: 80px 40px; }
        @include m {
            padding: 64px 24px;
            border-radius: 0; }
        @include dark {
            background: #18191D; } }
    &__head {
        margin-bottom: 80px;
        text-align: center;
        @include d {
            margin-bottom: 64px; }
        @include m {
            margin-bottom: 32px; } }
    &__title {
        margin-bottom: 12px; }
    &__slider {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -48px -10px 0;
        @include x {
            margin: -32px -16px 0; }
        @include m {
            display: block;
            margin: 0;
            padding-bottom: 80px;
            visibility: hidden;
            &.slick-initialized {
                visibility: visible; } } }
    &__item {
        flex: 0 0 calc(20% - 48px);
        width: calc(20% - 48px);
        margin: 48px 24px 0;
        display: block;
        overflow: hidden;
        padding: 8px 8px 24px;
        border-radius: 16px;
        background: $n8;
        text-align: center;
        color: $n2;
        transition: box-shadow .2s;
        @include x {
            flex: 0 0 calc(20% - 32px);
            width: calc(20% - 32px);
            margin: 32px 16px 0; }
        @include d {
            flex: 0 0 calc(25% - 32px);
            width: calc(25% - 32px); }
        @include t {
            flex: 0 0 calc(33.333% - 32px);
            width: calc(33.333% - 32px); }
        @include m {
            width: 100%;
            margin: 0; }
        &:hover {
            box-shadow: inset 0 0 0 1px $n5; }
        @include dark {
            background: $n2;
            color: $n8;
            &:hover {
                box-shadow: inset 0 0 0 1px $n4; } }
        &:nth-child(n+9) {
            @include d {
                display: none; }
            @include t {
                display: block; } } }
    &__avatar {
        position: relative;
        overflow: hidden;
        width: 80px;
        height: 80px;
        margin: 0 auto 16px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover; } }
    &__check {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $p4;
        .icon {
            width: 12px;
            height: 12px;
            fill: $n8; } }
    &__line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px; }
    &__number {
        min-width: 39px;
        padding: 8px 12px 6px;
        border-radius: 13px;
        text-align: center;
        @include hairline-2;
        color: $n8; }
    &__rating {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        @include caption-bold-2;
        .icon {
            position: relative;
            top: -1px;
            width: 12px;
            height: 12px;
            margin-right: 4px;
            fill: $s3; } }
    &__subtitle {
        margin-bottom: 4px;
        @include body-bold-2; }
    &__location {
        @include caption-2;
        color: $n4; }
    .slick-list {
        overflow: visible;
        @include m {
            margin: 0 -72px 0 0; } }
    .slick-slide {
        margin: 0 8px;
        opacity: 0;
        visibility: hidden;
        transition: all .4s;
        &.slick-active {
            opacity: 1;
            visibility: visible; } }
    .slick-arrow {
        bottom: 0; }
    .slick-prev {
        left: 8px; }
    .slick-next {
        left: 56px; } }
