.brands {
    &__wrap {
        overflow: hidden; }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1px -1px 0;
        @include m {
            display: block; } }
    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 33.333%;
        width: 33.333%;
        height: 150px;
        border-width: 0 1px 1px 0;
        border-style: solid;
        border-color: $n6;
        @include m {
            width: 100%;
            height: 112px; }
        @include dark {
            border-color: $n3; }
        img {
            max-width: 166px;
            max-height: 64px; } } }
