.place {
    &__wrapper {
        border: 1px solid $n6;
        border-radius: 24px;
        @include dark {
            border-color: $n2; } }
    &__preview {
        position: relative;
        padding: 3px;
        img {
            width: 100%;
            border-radius: 20px;
            @include x {
                height: 300px;
                object-fit: cover; } }
        & > .place__button {
            position: absolute;
            top: 11px;
            right: 11px;
            .icon {
                width: 16px;
                height: 16px; } } }
    &__body {
        padding: 40px 32px 24px;
        @include x {
            padding: 32px 24px; } }
    &__title {
        margin-bottom: 12px;
        @include body-bold-1; }
    &__profile {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        span {
            margin-right: 4px;
            color: $n4; } }
    &__avatar {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover; } }
    &__name {
        @include body-bold-2; }
    &__description {
        margin-bottom: 32px;
        padding: 8px;
        border-radius: 20px;
        background: $n7;
        @include dark {
            background: $n2; }
        &_flex {
            display: flex;
            flex-wrap: wrap;
            @include x {
                display: block; }
            .place__item {
                flex: 0 0 50%;
                &:nth-child(2n) {
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 12px;
                        left: 0;
                        bottom: 12px;
                        width: 1px;
                        background: $n6;
                        @include x {
                            top: 0;
                            right: 0;
                            bottom: auto;
                            width: auto;
                            height: 1px; }
                        @include dark {
                            background: $n3; } } } } } }
    &__item {
        display: flex;
        align-items: center;
        padding: 12px; }
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        margin-right: 12px;
        .icon {
            width: 24px;
            height: 24px;
            fill: $n5; } }
    &__category {
        @include caption-2;
        color: $n4; }
    &__subtitle {
        @include body-bold-2; }
    &__parameters {
        display: flex;
        flex-wrap: wrap;
        margin: -10px 0 32px -16px; }
    &__parameter {
        display: flex;
        align-items: center;
        margin: 10px 0 0 16px;
        color: $n4;
        .icon {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            fill: $n4; } }
    &__text {
        margin: 32px 0;
        padding-top: 32px;
        border-top: 1px solid $n6;
        color: $n4;
        @include x {
            display: none; }
        @include dark {
            border-color: $n3; } } }
