.panel {
    position: relative;
    z-index: 5;
    padding: 40px;
    @include d {
        padding: 32px 32px 24px; }
    @include m {
        padding: 32px 20px 24px; }
    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 24px;
        box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1);
        @include m {
            border-radius: 20px;
            box-shadow: 0px 16px 32px rgba(15, 15, 15, 0.1); }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(83.59deg, #FCFCFD 36.52%, rgba(252, 252, 253, 0.83) 98.8%);
            border: 1px solid $n8;
            backdrop-filter: blur(32px);
            border-radius: 24px;
            @include m {
                border-radius: 20px; }
            @include dark {
                background: linear-gradient(92.98deg, #23262F 3.54%, rgba(35, 38, 47, 0.7) 93.38%);
                border-color: $n3; } } }
    &__nav {
        position: relative;
        z-index: 2;
        display: flex;
        margin-bottom: 24px;
        box-shadow: inset 0 -1px 0 0 $n6;
        @include m {
            margin: 0 -20px 16px;
            padding: 0 20px; }
        @include dark {
            box-shadow: inset 0 -1px 0 0 rgba($n4, .3); } }
    &__link {
        padding-bottom: 30px;
        border-bottom: 1px solid transparent;
        @include button-2;
        color: $n4;
        transition: all .2s;
        @include d {
            padding-bottom: 24px; }
        &:hover {
            color: $n2; }
        &.active {
            border-color: $n2;
            color: $n2; }
        @include dark {
            color: $n5;
            &:hover {
                color: $n8; }
            &.active {
                border-color: $n8;
                color: $n8; } }
        &:not(:last-child) {
            margin-right: 48px;
            @include m {
                margin-right: auto; } } }
    &__body {
        position: relative;
        z-index: 3; }
    &__row {
        display: flex;
        @include x {
            margin: 0 -20px; }
        @include t {
            margin: 0; }
        @include m {
            display: block; } }
    &__search {
        position: absolute;
        top: 50%;
        right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        transform: translateY(-50%);
        background: $p1;
        border-radius: 50%;
        transition: background .2s;
        @include x {
            right: 0; }
        @include d {
            width: 56px;
            height: 56px; }
        @include t {
            top: auto;
            bottom: 2px;
            transform: translateY(0); }
        .icon {
            width: 24px;
            height: 24px;
            fill: $n8; }
        span {
            display: none;
            @include button-1;
            color: $n8; }
        &:hover {
            background: darken($p1, 5); } }
    &__controls {
        position: relative;
        z-index: 2;
        display: flex;
        margin-bottom: 16px;
        padding-top: 8px;
        @include m {
            flex-wrap: wrap;
            margin-bottom: 0;
            padding-top: 0; }
        .panel__button {
            @include m {
                margin-bottom: 12px;
                padding: 0 24px; }
            &:not(:last-child) {
                margin-right: 16px;
                @include m {
                    margin-right: 8px; } }
            &.active {
                box-shadow: inset 0 0 0 2px $n2;
                background: $n2;
                color: $n8;
                @include dark {
                    box-shadow: inset 0 0 0 2px $n6;
                    background: $n6;
                    color: $n2; } } } }
    &_stays,
    &_flights,
    &_tasks,
    &_cars {
        margin: -175px 80px 0;
        @include w {
            margin: -175px 40px 0; }
        @include d {
            margin-top: -148px; }
        @include t {
            margin: -110px 16px 0; }
        @include m {
            margin: -110px 8px 0; } }
    &_stays {
        @include m {
            margin-top: -180px; } }
    &_flights {
        @include m {
            margin-top: -210px; } }
    &_flights .date-picker-wrapper,
    &_flights-category .date-picker-wrapper,
    &_cars .date-picker-wrapper,
    &_cars-category .date-picker-wrapper {
        @include t {
            left: 20% !important; }
        @include m {
            left: 50% !important; } }
    &_cars {
        @include m {
            margin-top: -245px; } }
    &_stays-category {
        margin: -90px 80px 0;
        @include w {
            margin: -90px 40px 0; }
        @include d {
            margin-top: -64px;
            padding: 24px 40px; }
        @include t {
            padding: 24px; }
        @include m {
            margin: -100px 8px 0; } }
    &_flights-category,
    &_cars-category {
        margin: -175px 0 0;
        @include d {
            margin-top: -140px; } }
    &_tasks-category {
        margin: -77px 80px 0;
        @include w {
            margin: -77px 40px 0; }
        @include m {
            margin: -100px 8px 0; } }
    &_stays &__row > div,
    &_stays-category &__row > div {
        &:first-child {
            flex: 0 0 300px;
            @include w {
                flex: 0 0 270px; }
            @include d {
                flex: 0 0 250px; }
            @include t {
                flex: 0 0 220px; } }
        &:nth-child(2) {
            flex-grow: 1; }
        &:nth-child(3) {
            flex: 0 0 296px;
            @include d {
                flex: 0 0 240px; }
            @include t {
                flex: 0 0 170px; } } }
    &_flights &__row {
        padding-right: 100px;
        @include x {
            padding-right: 90px; }
        @include t {
            padding-right: 70px; }
        @include m {
            padding-right: 0; } }
    &_flights &__row > div {
        &:first-child,
        &:nth-child(2) {
            flex: 0 0 248px;
            @include x {
                flex: 0 0 225px; }
            @include d {
                flex: 0 0 200px; }
            @include t {
                flex: 0 0 170px; }
            @include m {
                flex: 0 0 50%; } }
        &:nth-child(3) {
            flex-grow: 1; } }
    &_tasks-category &__row {
        @include m {
            margin: -12px 0 -8px; } }
    &_tasks &__row > div,
    &_tasks-category &__row > div {
        &:first-child {
            flex-grow: 1; }
        &:nth-child(2) {
            flex: 0 0 300px;
            @include x {
                flex: 0 0 280px; }
            @include d {
                flex: 0 0 250px; }
            @include t {
                flex: 0 0 180px; } }
        &:nth-child(3) {
            flex: 0 0 296px;
            @include t {
                flex: 0 0 210px; } } }
    &_flights &__controls &__button,
    &_flights-category &__controls &__button {
        @include m {
            padding: 0 12px;
            font-size: 13px; } }
    &_cars-category &__controls {
        padding-top: 0; }
    &_cars &__controls &__button,
    &_cars-category &__controls &__button {
        @include m {
            width: 100%; } }
    &_flights-category &__row {
        padding-right: 80px;
        @include t {
            padding-right: 64px; }
        @include m {
            padding: 0; } }
    &_flights-category &__row > div {
        &:first-child,
        &:nth-child(2) {
            flex: 0 0 280px;
            @include x {
                flex: 0 0 250px; }
            @include d {
                flex: 0 0 230px; }
            @include t {
                flex: 0 0 140px; } }
        &:nth-child(3) {
            flex-grow: 1; } }
    &_cars &__row,
    &_cars-category &__row {
        padding-right: 100px;
        @include t {
            padding-right: 80px; }
        @include m {
            padding: 0; } }
    &_cars &__row > div,
    &_cars-category &__row > div {
        &:first-child {
            flex-grow: 1; }
        &:nth-child(2) {
            flex-shrink: 0;
            flex: 0 0 550px;
            @include x {
                flex: 0 0 500px; }
            @include d {
                flex: 0 0 450px; }
            @include t {
                flex: 0 0 300px; } } }
    &_flights &__search,
    &_flights-category &__search,
    &_cars &__search,
    &_cars-category &__search {
        @include m {
            position: static;
            width: 100%;
            height: 48px;
            margin-top: 12px;
            border-radius: 24px;
            .icon {
                display: none; }
            span {
                display: inline; } } } }
