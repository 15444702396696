.guests {
    position: relative;
    width: 107px;
    height: 40px;
    &.active {
        overflow: visible;
        .guests__head {
            .icon {
                transform: rotate(180deg); } }
        .guests__body {
            visibility: visible;
            opacity: 1; } }
    &__head {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding-left: 4px;
        border-radius: 20px;
        box-shadow: inset 0 0 0 2px $n6;
        @include button-2;
        user-select: none;
        transition: all .2s;
        cursor: pointer;
        @include dark {
            box-shadow: inset 0 0 0 2px $n4; }
        .icon {
            width: 20px;
            height: 20px;
            margin-left: 4px;
            fill: $n4;
            transition: transform .2s; } }
    &__body {
        position: absolute;
        bottom: calc(100% + 4px);
        left: 0;
        right: 0;
        z-index: 5;
        border-radius: 24px;
        box-shadow: inset 0 0 0 2px $n6;
        background: $n8;
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        @include dark {
            background: $n2;
            box-shadow: inset 0 0 0 2px $n4; } }
    .counter {
        width: 100%;
        height: 39px;
        padding: 1px 12px 0; } }
