.range {
    &__label {
        margin-bottom: 20px;
        @include hairline-2;
        color: $n5; }
    &__indicators {
        display: flex;
        justify-content: space-between;
        margin-top: 8px; }
    &__text {
        font-weight: 500; }
    &__info {
        font-weight: 500;
        color: $n4; }
    &__note {
        margin-bottom: 20px;
        @include caption-2;
        color: $n4; }
    &_time &__text {
        margin-top: 12px; } }

.noUi {
    &-horizontal {
        height: 8px; }
    &-target {
        border: none;
        background: $n6;
        box-shadow: none;
        @include dark {
            background: $n3; } }
    &-connect {
        background: $p1; }
    &-handle {
        border: none;
        box-shadow: none;
        background: $p1;
        border-radius: 50%;
        cursor: pointer;
        &:before,
        &:after {
            display: none; } }
    &-horizontal &-handle {
        right: -12px;
        top: -8px;
        width: 24px;
        height: 24px;
        border: 4px solid $n8; }
    &-tooltip {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        padding: 4px 8px;
        background: $n1;
        border-radius: 8px;
        border: none;
        font-size: 14px;
        font-weight: 600;
        color: $n8;
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            @include arr(8,4,$n1,b); }
        @include dark {
            background: $n3;
            &:before {
                @include arr(8,4,$n3,b); } } }
    &-handle:hover &-tooltip {
        visibility: visible;
        opacity: 1; }
    &-horizontal &-tooltip {
        bottom: 24px; } }

.range_time {
    .noUi {
        &-horizontal {
            height: 2px; }
        &-handle {
            top: -11px; } } }

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
    right: -12px; }
