.interest {
    &__head {
        margin-bottom: 64px;
        text-align: center;
        @include m {
            margin-bottom: 32px; } }
    &__category {
        display: inline-block;
        margin-bottom: 12px;
        padding: 8px 8px 6px;
        box-shadow: inset 0 0 0 2px $p4;
        border-radius: 4px;
        @include hairline-2;
        color: $p4;
        @include m {
            display: none; } }
    &__title {
        margin-bottom: 48px;
        @include m {
            margin-bottom: 32px; } }
    .nav {
        justify-content: center;
        @include m {
            display: none; } }
    .select {
        width: 100%;
        text-align: left; }
    &__wrapper {
        margin: 0 -12px;
        @include m {
            margin: 0 -8px; } }
    &__slider {
        padding-bottom: 88px;
        visibility: hidden;
        @include m {
            padding-bottom: 72px; }
        &.slick-initialized {
            visibility: visible; } }
    .slick-list {
        overflow: visible; }
    .slick-track {
        display: flex; }
    .slick-slide {
        height: auto;
        margin: 0 12px;
        opacity: 0;
        visibility: hidden;
        transition: all .4s;
        @include m {
            margin: 0 8px; }
        &.slick-active {
            opacity: 1;
            visibility: visible; } }
    .slick-arrow {
        bottom: 0;
        width: 24px;
        height: 24px;
        &:hover {
            box-shadow: none;
            path {
                fill: $n2; } } }
    .slick-prev {
        left: calc(50% - 44px); }
    .slick-next {
        right: calc(50% - 44px); } }
