.comments {
    &__center {
        display: flex;
        align-items: flex-start;
        @include t {
            flex-direction: column-reverse; } }
    .profile {
        flex-shrink: 0;
        width: 343px;
        @include t {
            width: auto;
            margin-top: 64px; }
        @include m {
            margin: 64px -16px 0; } }
    .comment {
        flex-grow: 1;
        padding: 40px 0 0 48px;
        @include t {
            padding: 0; } } }

