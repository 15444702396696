.destinations {
    &__inner {
        position: relative; }
    &__head {
        margin-bottom: 48px;
        padding-right: 120px;
        @include m {
            padding-right: 0; } }
    &__wrapper {
        margin: 0 -16px; }
    &__item {
        display: block;
        color: $n2;
        @include dark {
            color: $n8; }
        &:hover {
            .destinations__preview {
                img {
                    transform: scale(1.1); } } } }
    &__preview {
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
        border-radius: 24px;
        &:before {
            content: "";
            display: block;
            padding-bottom: 72.7%;
            @include d {
                padding-bottom: 96%; }
            @include m {
                padding-bottom: 82%; } }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 1s; } }
    &__category {
        position: absolute;
        top: 16px;
        left: 16px;
        z-index: 2; }
    &__subtitle {
        margin-bottom: 8px;
        @include body-bold-1; }
    &__content {
        @include caption-2;
        color: $n4; }
    &__button {
        margin-top: 48px;
        .icon {
            width: 16px;
            height: 16px; } }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: -48px -16px 0;
        @include m {
            display: block;
            margin: -32px 0 0; } }
    &__list &__item {
        flex: 0 0 calc(33.333% - 32px);
        width: calc(33.333% - 32px);
        margin: 48px 16px 0;
        @include t {
            flex: 0 0 calc(50% - 32px);
            width: calc(50% - 32px); }
        @include m {
            width: 100%;
            margin: 32px 0 0; }
        &:nth-child(n+5) {
            @include m {
                display: none; } }
        &:nth-child(n+9) {
            @include t {
                display: none; }
            @include m {
                display: block; } } }
    &__slider {
        position: static;
        visibility: hidden;
        @include m {
            position: relative;
            padding-bottom: 88px; }
        &.slick-initialized {
            visibility: visible; } }
    .slick-list {
        overflow: visible;
        @include s {
            margin: 0 -246px 0 0; } }
    .slick-slide {
        padding: 0 16px; }
    .slick-arrow {
        top: 8px;
        @include m {
            top: auto;
            bottom: 0; } }
    .slick-prev {
        right: 48px;
        @include t {
            right: auto;
            left: 16px; } }
    .slick-next {
        right: 0;
        @include m {
            right: auto;
            left: 64px; } } }
