.comment {
    &__form {
        margin-bottom: 48px;
        @include d {
            margin-bottom: 40px; } }
    &__title {
        @include body-bold-1; }
    &__form &__title {
        margin-bottom: 8px; }
    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        @include d {
            display: block; } }
    &__text {
        margin-right: auto;
        color: $n4;
        span {
            font-weight: 500;
            color: $n2;
            @include dark {
                color: $n8; } } }
    .rating {
        position: relative;
        top: -3px;
        flex-shrink: 0;
        @include d {
            margin: 8px 0 0 -4px; } }
    &__field {
        position: relative; }
    &__input {
        width: 100%;
        height: 72px;
        padding: 0 180px 0 24px;
        border-radius: 24px;
        background: none;
        border: 2px solid $n6;
        @include poppins;
        @include body-2;
        color: $n2;
        transition: border-color .2s;
        @include m {
            height: 64px;
            padding-right: 96px; }
        @include dark {
            border-color: $n3;
            color: $n8; }
        &:focus {
            border-color: $n4; }
        @include placeholder {
            color: $n4; } }
    &__form &__button {
        position: absolute;
        top: 50%;
        right: 16px;
        z-index: 2;
        transform: translateY(-50%);
        .icon {
            width: 14px;
            height: 14px; }
        @include m {
            width: 32px;
            height: 32px;
            padding: 0;
            font-size: 0;
            .icon:last-child {
                margin-left: 0 !important; } } }
    .smile {
        position: absolute;
        top: 50%;
        right: 140px;
        z-index: 2;
        transform: translateY(-50%);
        @include m {
            right: 64px; } }
    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        @include m {
            display: none; }
        .select {
            width: 160px; } }
    &__head &__title {
        margin-right: auto; }
    &__item {
        display: flex;
        align-items: flex-start;
        padding-bottom: 24px;
        border-bottom: 1px solid $n6;
        @include dark {
            border-color: $n3; }
        &:not(:last-child) {
            margin-bottom: 24px; } }
    &__avatar {
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        margin-right: 20px;
        @include m {
            margin-right: 14px; }
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover; } }
    &__details {
        flex-grow: 1; }
    &__top {
        display: flex;
        margin-bottom: 4px; }
    &__author {
        margin-right: auto;
        font-weight: 500; }
    .rating {
        position: relative;
        top: -2px;
        margin-left: 8px;
        flex-shrink: 0; }
    &__content {
        margin-bottom: 8px;
        color: $n3;
        @include dark {
            color: $n5; } }
    &__time {
        @include caption-2;
        color: $n4; }
    &__foot {
        display: flex;
        align-items: center; }
    &__actions {
        display: flex; }
    &__action {
        margin-left: 16px;
        @include poppins;
        @include caption-bold-2;
        transition: color .2s;
        @include dark {
            color: $n8; }
        &:hover {
            color: $p1; } }
    &__btns {
        margin-top: 32px;
        text-align: center; }
    &__btns &__button {
        .loader {
            margin: 0 16px 0 5px;
            transform: scale(.8); } } }


