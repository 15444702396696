.nav {
    display: flex;
    &__link {
        display: flex;
        padding: 6px 12px;
        border-radius: 14px;
        background: none;
        @include button-2;
        color: $n4;
        transition: all .2s;
        .icon {
            width: 16px;
            height: 16px;
            margin-right: 8px;
            fill: $n4;
            transition: fill .2s; }
        &:hover {
            color: $n3;
            .icon {
                fill: $n3; }
            @include dark {
                color: $n6;
                .icon {
                    fill: $n6; } } }
        &.active {
            background: $n3;
            color: $n8;
            .icon {
                fill: $n8; }
            @include dark {
                background: $n6;
                color: $n2;
                .icon {
                    fill: $n2; } } }
        &:not(:last-child) {
            margin-right: 8px; } } }
