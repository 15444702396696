.live {
    &__head {
        margin-bottom: 64px;
        text-align: center;
        @include m {
            margin-bottom: 48px; } }
    &__wrapper {
        @include m {
            margin: 0 -4px; } }
    &__slider {
        display: flex;
        flex-wrap: wrap;
        margin: -48px -16px 0;
        @include m {
            display: block;
            visibility: hidden;
            margin: 0;
            padding-bottom: 64px;
            &.slick-initialized {
                visibility: visible; } } }
    &__item {
        flex: 0 0 calc(33.333% - 32px);
        width: calc(33.333% - 32px);
        margin: 48px 16px 0;
        text-align: center;
        color: $n2;
        transition: color .2s;
        @include t {
            flex: 0 0 calc(50% - 32px);
            width: calc(50% - 32px); }
        @include m {
            width: auto;
            margin: 0 4px; }
        &:hover {
            color: $p1;
            .live__preview {
                img {
                    transform: scale(1.05); } } }
        @include dark {
            color: $n8;
            &:hover {
                color: $p1; } }
        &:nth-child(n+4) {
            display: none;
            @include t {
                display: block; } }
        &:nth-child(n+5) {
            @include t {
                display: none; }
            @include m {
                display: block; } } }
    &__preview {
        margin-bottom: 24px;
        overflow: hidden;
        border-radius: 16px;
        img {
            width: 100%;
            transition: transform 1s; } }
    &__subtitle {
        margin-bottom: 8px;
        @include body-bold-2; }
    &__content {
        color: $n4; }
    &__btns {
        margin-top: 64px;
        text-align: center;
        @include t {
            margin-top: 40px; }
        @include m {
            display: none; } }
    &__button {
        .loader {
            margin: 0 16px 0 5px;
            transform: scale(.8); } }
    .slick-list {
        overflow: visible; }
    .slick-slide {
        @include m {
            margin: 0 4px; } }
    .slick-arrow {
       bottom: 0; }
    .slick-prev {
        left: calc(50% - 44px); }
    .slick-next {
        right: calc(50% - 44px); } }
