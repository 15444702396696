@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

img, fieldset, a img {
  border: none; }

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea {
  -webkit-appearance: none; }

input[type="submit"],
button {
  cursor: pointer; }
  input[type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    padding: 0;
    border: 0; }

button {
  background: none; }

textarea {
  overflow: auto; }

input, button {
  margin: 0;
  padding: 0;
  border: 0; }

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none; }

ul, ol {
  list-style-type: none; }

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto; }
  .nice-select:hover {
    border-color: #dbdbdb; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
  .nice-select:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 5px; }
  .nice-select.open:after {
    transform: rotate(-135deg); }
  .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0); }
  .nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #cccccc; }
  .nice-select.wide {
    width: 100%; }
    .nice-select.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
    .nice-select .list:hover .option:not(:hover) {
      background-color: transparent !important; }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    transition: all 0.2s; }
    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
      background-color: #f6f6f6; }
    .nice-select .option.selected {
      font-weight: bold; }
    .nice-select .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }

body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-bg, .fancybox-inner, .fancybox-outer, .fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-caption, .fancybox-infobar, .fancybox-navigation .fancybox-button, .fancybox-toolbar {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease,visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-caption .fancybox-caption, .fancybox-show-infobar .fancybox-infobar, .fancybox-show-nav .fancybox-navigation .fancybox-button, .fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  transition: opacity .25s ease 0s,visibility 0s ease 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform,opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide:before {
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide, .fancybox-slide--current, .fancybox-slide--next, .fancybox-slide--previous {
  display: block; }

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0; }

.fancybox-slide--image:before {
  display: none; }

.fancybox-slide--html {
  padding: 6px; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  transform-origin: top left;
  transition-property: transform,opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in; }

.fancybox-can-pan .fancybox-content, .fancybox-can-swipe .fancybox-content {
  cursor: grab; }

.fancybox-is-grabbing .fancybox-content {
  cursor: grabbing; }

.fancybox-container [data-selectable=true] {
  cursor: text; }

.fancybox-image, .fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--iframe .fancybox-content, .fancybox-slide--map .fancybox-content, .fancybox-slide--pdf .fancybox-content, .fancybox-slide--video .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff; }

.fancybox-iframe, .fancybox-video {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px; }

.fancybox-button, .fancybox-button:link, .fancybox-button:visited {
  color: #ccc; }

.fancybox-button:hover {
  color: #fff; }

.fancybox-button:focus {
  outline: none; }

.fancybox-button.fancybox-focus {
  outline: 1px dotted; }

.fancybox-button[disabled], .fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none; }

.fancybox-button div {
  height: 100%; }

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%; }

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0; }

.fancybox-button--fsenter svg:nth-child(2), .fancybox-button--fsexit svg:first-child, .fancybox-button--pause svg:first-child, .fancybox-button--play svg:nth-child(2) {
  display: none; }

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998; }

.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401; }

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1; }

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0; }

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden; }

.fancybox-is-scaling .fancybox-close-small, .fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none; }

.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px; }

.fancybox-navigation .fancybox-button div {
  padding: 7px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right); }

.fancybox-caption {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, transparent);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996; }

@supports (padding: max(0px)) {
  .fancybox-caption {
    padding: 75px max(44px, env(safe-area-inset-right)) max(25px, env(safe-area-inset-bottom)) max(44px, env(safe-area-inset-left)); } }

.fancybox-caption--separate {
  margin-top: -50px; }

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all; }

.fancybox-caption a, .fancybox-caption a:link, .fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

.fancybox-loading {
  animation: a 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999; }

@keyframes a {
  to {
    transform: rotate(1turn); } }

.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translateZ(0); }

.fancybox-fx-fade.fancybox-slide--next, .fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scaleX(1); }

.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  transform: rotate(-1turn); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  transform: rotate(1turn); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  transform: rotate(0deg); }

.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scaleX(1) translateZ(0); }

.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  transform: translateZ(0) scale(1); }

@media (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px; }
  .fancybox-slide--image {
    padding: 6px 0; }
  .fancybox-close-small {
    right: -6px; }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px; }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px; }
  @supports (padding: max(0px)) {
    .fancybox-caption {
      padding-left: max(12px, env(safe-area-inset-left));
      padding-right: max(12px, env(safe-area-inset-right)); } } }

.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:link, .fancybox-share__button:visited {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs__list a:before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs__list a:focus:before {
  opacity: .5; }

.fancybox-thumbs__list a.fancybox-thumbs-active:before {
  opacity: 1; }

@media (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px); } }

.jq-ry-container {
  position: relative;
  padding: 0 5px;
  line-height: 0;
  display: block;
  cursor: pointer;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  direction: ltr; }

.jq-ry-container[readonly="readonly"] {
  cursor: default; }

.jq-ry-container > .jq-ry-group-wrapper {
  position: relative;
  width: 100%; }

.jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group {
  position: relative;
  line-height: 0;
  z-index: 10;
  white-space: nowrap; }

.jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group > svg {
  display: inline-block; }

.jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group.jq-ry-normal-group {
  width: 100%; }

.jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group.jq-ry-rated-group {
  width: 0;
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden; }

/*# sourceMappingURL=min/jquery.rateyo.min.css.map */
/*! nouislider - 11.0.3 - 2018-01-21 14:04:07 */
.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr; }

.noUi-base, .noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

.noUi-connects {
  overflow: hidden;
  z-index: 0; }

.noUi-connect, .noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }

html:not([dir=rtl]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0; }

.noUi-vertical .noUi-origin {
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  position: absolute; }

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  -webkit-transition: transform .3s;
  transition: transform .3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

.noUi-horizontal {
  height: 18px; }

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px; }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px; }

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto; }

.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0,0 3px 6px -5px #BBB; }

.noUi-connects {
  border-radius: 3px; }

.noUi-connect {
  background: #3FB8AF; }

.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF,inset 0 1px 7px #EBEBEB,0 3px 6px -3px #BBB; }

.noUi-active {
  box-shadow: inset 0 0 1px #FFF,inset 0 1px 7px #DDD,0 3px 6px -3px #BBB; }

.noUi-handle:after, .noUi-handle:before {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px; }

.noUi-handle:after {
  left: 17px; }

.noUi-vertical .noUi-handle:after, .noUi-vertical .noUi-handle:before {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px; }

.noUi-vertical .noUi-handle:after {
  top: 17px; }

[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled] .noUi-handle, [disabled].noUi-handle, [disabled].noUi-target {
  cursor: not-allowed; }

.noUi-pips, .noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #999; }

.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

.noUi-marker {
  position: absolute;
  background: #CCC; }

.noUi-marker-large, .noUi-marker-sub {
  background: #AAA; }

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active, .mfp-close.nice-select.open {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active, .mfp-arrow.nice-select.open {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.date-picker {
  width: 170px;
  height: 25px;
  padding: 0;
  border: 0;
  line-height: 25px;
  padding-left: 10px;
  font-size: 12px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  cursor: pointer;
  color: #303030;
  position: relative;
  z-index: 2; }

.date-picker-wrapper {
  position: absolute;
  z-index: 1;
  border: 1px solid #bfbfbf;
  background-color: #efefef;
  padding: 5px 12px;
  font-size: 12px;
  line-height: 20px;
  color: #aaa;
  font-family: Arial, sans-serif;
  -webkit-box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  -webkit-box-sizing: initial;
  box-sizing: initial; }

.dp-clearfix {
  clear: both;
  height: 0;
  font-size: 0; }

.date-picker-wrapper.inline-wrapper {
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: inline-block; }

.date-picker-wrapper.single-date {
  width: auto; }

.date-picker-wrapper.no-shortcuts {
  padding-bottom: 12px; }

.date-picker-wrapper.no-topbar {
  padding-top: 12px; }

.date-picker-wrapper .footer {
  font-size: 11px;
  padding-top: 3px; }

.date-picker-wrapper b {
  color: #666;
  font-weight: 700; }

.date-picker-wrapper a {
  color: #6bb4d6;
  text-decoration: underline; }

.date-picker-wrapper .month-name {
  text-transform: uppercase; }

.date-picker-wrapper .select-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle; }

.date-picker-wrapper .select-wrapper:hover {
  text-decoration: underline; }

.date-picker-wrapper .month-element {
  display: inline-block;
  vertical-align: middle; }

.date-picker-wrapper .select-wrapper select {
  position: absolute;
  margin: 0;
  padding: 0;
  left: 0;
  top: -1px;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  text-transform: inherit;
  color: inherit;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  outline: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  filter: alpha(opacity=1);
  opacity: 0.01; }

.date-picker-wrapper .month-wrapper {
  border: 1px solid #bfbfbf;
  border-radius: 3px;
  background-color: #fff;
  padding: 5px;
  cursor: default;
  position: relative;
  _overflow: hidden; }

.date-picker-wrapper .month-wrapper table {
  width: 190px;
  float: left; }

.date-picker-wrapper .month-wrapper table.month2 {
  width: 190px;
  float: left; }

.date-picker-wrapper .month-wrapper table th, .date-picker-wrapper .month-wrapper table td {
  vertical-align: middle;
  text-align: center;
  line-height: 14px;
  margin: 0px;
  padding: 0px; }

.date-picker-wrapper .month-wrapper table .day {
  padding: 5px 0;
  line-height: 1;
  font-size: 12px;
  margin-bottom: 1px;
  color: #ccc;
  cursor: default; }

.date-picker-wrapper .month-wrapper table div.day.lastMonth, .date-picker-wrapper .month-wrapper table div.day.nextMonth {
  color: #999;
  cursor: default; }

.date-picker-wrapper .month-wrapper table .day.checked {
  background-color: #9cdbf7; }

.date-picker-wrapper .month-wrapper table .week-name {
  height: 20px;
  line-height: 20px;
  font-weight: 100;
  text-transform: uppercase; }

.date-picker-wrapper .month-wrapper table .day.has-tooltip {
  cursor: help !important; }

.date-picker-wrapper .month-wrapper table .day.has-tooltip .tooltip {
  white-space: nowrap; }

.date-picker-wrapper .time label {
  white-space: nowrap; }

.date-picker-wrapper .month-wrapper table .day.toMonth.valid {
  color: #333;
  cursor: pointer; }

.date-picker-wrapper .month-wrapper table .day.toMonth.hovering {
  background-color: #cdecfa; }

.date-picker-wrapper .month-wrapper table .day.nextMonth, .date-picker-wrapper .month-wrapper table .day.lastMonth {
  display: none; }

.date-picker-wrapper .month-wrapper table .day.real-today {
  background-color: #ffe684; }

.date-picker-wrapper .month-wrapper table .day.real-today.checked, .date-picker-wrapper .month-wrapper table .day.real-today.hovering {
  background-color: #70ccd5; }

.date-picker-wrapper table .caption {
  height: 40px; }

.date-picker-wrapper table .caption > th:first-of-type, .date-picker-wrapper table .caption > th:last-of-type {
  width: 27px; }

.date-picker-wrapper table .caption .next, .date-picker-wrapper table .caption .prev {
  padding: 0 5px;
  cursor: pointer; }

.date-picker-wrapper table .caption .next:hover, .date-picker-wrapper table .caption .prev:hover {
  background-color: #ccc;
  color: white; }

.date-picker-wrapper .gap {
  position: relative;
  z-index: 1;
  width: 15px;
  height: 100%;
  background-color: red;
  font-size: 0;
  line-height: 0;
  float: left;
  top: -5px;
  margin: 0 10px -10px;
  visibility: hidden;
  height: 0; }

.date-picker-wrapper .gap .gap-lines {
  height: 100%;
  overflow: hidden; }

.date-picker-wrapper .gap .gap-line {
  height: 15px;
  width: 15px;
  position: relative; }

.date-picker-wrapper .gap .gap-line .gap-1 {
  z-index: 1;
  height: 0;
  border-left: 8px solid white;
  border-top: 8px solid #eee;
  border-bottom: 8px solid #eee; }

.date-picker-wrapper .gap .gap-line .gap-2 {
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 2;
  height: 0;
  border-left: 8px solid transparent;
  border-top: 8px solid white; }

.date-picker-wrapper .gap .gap-line .gap-3 {
  position: absolute;
  right: 0;
  top: 8px;
  z-index: 2;
  height: 0;
  border-left: 8px solid transparent;
  border-bottom: 8px solid white; }

.date-picker-wrapper .gap .gap-top-mask {
  width: 6px;
  height: 1px;
  position: absolute;
  top: -1px;
  left: 1px;
  background-color: #eee;
  z-index: 3; }

.date-picker-wrapper .gap .gap-bottom-mask {
  width: 6px;
  height: 1px;
  position: absolute;
  bottom: -1px;
  left: 7px;
  background-color: #eee;
  z-index: 3; }

.date-picker-wrapper .selected-days {
  display: none; }

.date-picker-wrapper .drp_top-bar {
  line-height: 1.4;
  position: relative;
  padding: 10px 40px 10px 0; }

.date-picker-wrapper .drp_top-bar .error-top, .date-picker-wrapper .drp_top-bar .normal-top {
  display: none; }

.date-picker-wrapper .drp_top-bar .default-top {
  display: block; }

.date-picker-wrapper .drp_top-bar.error .default-top {
  display: none; }

.date-picker-wrapper .drp_top-bar.error .error-top {
  display: block;
  color: red; }

.date-picker-wrapper .drp_top-bar.normal .default-top {
  display: none; }

.date-picker-wrapper .drp_top-bar.normal .normal-top {
  display: block; }

.date-picker-wrapper .drp_top-bar.normal .normal-top .selection-top {
  color: #333; }

.date-picker-wrapper .drp_top-bar .apply-btn {
  position: absolute;
  right: 0px;
  top: 6px;
  padding: 3px 5px;
  margin: 0;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  color: #d9eef7;
  border: solid 1px #0076a3;
  background: #0095cd;
  background: -moz-linear-gradient(top, #00adee, #0078a5);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adee', endColorstr='#0078a5');
  color: white;
  line-height: initial; }

.date-picker-wrapper .drp_top-bar .apply-btn.disabled {
  cursor: pointer;
  color: #606060;
  border: solid 1px #b7b7b7;
  background: #fff;
  background: -moz-linear-gradient(top, #fff, #ededed);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed'); }

.date-picker-wrapper .time {
  position: relative; }

.date-picker-wrapper.single-month .time {
  display: block; }

.date-picker-wrapper .time input[type=range] {
  vertical-align: middle;
  width: 129px;
  padding: 0;
  margin: 0;
  height: 20px; }

.date-picker-wrapper .time1 {
  width: 180px;
  padding: 0 5px;
  text-align: center; }

/*time styling*/
.time2 {
  width: 180px;
  padding: 0 5px;
  text-align: center; }

.date-picker-wrapper .time1 {
  float: left; }

.date-picker-wrapper .time2 {
  float: right; }

.date-picker-wrapper .hour {
  text-align: right; }

.minute {
  text-align: right; }

.date-picker-wrapper .hide {
  display: none; }

.date-picker-wrapper .first-date-selected, .date-picker-wrapper .last-date-selected {
  background-color: #49e !important;
  color: white !important; }

.date-picker-wrapper .date-range-length-tip {
  position: absolute;
  margin-top: -4px;
  margin-left: -8px;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  display: none;
  background-color: yellow;
  padding: 0 6px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 16px;
  -webkit-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
  -moz-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
  -ms-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
  -o-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3)); }

.date-picker-wrapper .date-range-length-tip:after {
  content: '';
  position: absolute;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid yellow;
  left: 50%;
  margin-left: -4px;
  bottom: -4px; }

.date-picker-wrapper.two-months.no-gap .month1 .next, .date-picker-wrapper.two-months.no-gap .month2 .prev {
  display: none; }

.date-picker-wrapper .week-number {
  padding: 5px 0;
  line-height: 1;
  font-size: 12px;
  margin-bottom: 1px;
  color: #999;
  cursor: pointer; }

.date-picker-wrapper .week-number.week-number-selected {
  color: #49e;
  font-weight: bold; }

body {
  min-width: 375px;
  background: #FCFCFD;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 1.71429;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #23262F; }
  body.dark {
    background: #141416;
    color: #FCFCFD; }

a {
  text-decoration: none; }

svg,
img {
  vertical-align: middle; }

.outer {
  overflow: hidden;
  min-height: 100vh; }

.center {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px; }
  @media only screen and (max-width: 1023px) {
    .center {
      padding: 0 40px; } }
  @media only screen and (max-width: 767px) {
    .center {
      padding: 0 32px; } }

body.dark .some-icon {
  display: none; }

.some-icon-dark {
  display: none; }
  body.dark .some-icon-dark {
    display: inline-block; }

.slick-arrow {
  position: absolute;
  z-index: 2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 0;
  transition: all .2s; }
  .slick-arrow path {
    position: relative;
    z-index: 2;
    fill: #777E90;
    transition: fill .2s; }
  .slick-arrow:hover {
    box-shadow: inset 0 0 0 2px #E6E8EC; }
    body.dark .slick-arrow:hover {
      box-shadow: inset 0 0 0 2px #353945; }
      body.dark .slick-arrow:hover path {
        fill: #E6E8EC; }

.rating {
  z-index: 1; }

.select {
  float: none;
  width: 100%;
  height: 48px;
  padding: 0 48px 0 16px;
  box-shadow: inset 0 0 0 2px #E6E8EC;
  background: #FCFCFD;
  border-radius: 12px;
  border: none;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
  line-height: 48px; }
  .select:after {
    display: none; }
  .select:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    width: 32px;
    height: 32px;
    transform: translateY(-50%);
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px #E6E8EC;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50%/10px auto;
    transition: transform .2s; }
  body.dark .select {
    background: #141416;
    box-shadow: inset 0 0 0 2px #353945;
    -webkit-appearance: none; }
    body.dark .select:before {
      box-shadow: inset 0 0 0 2px #353945;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23FCFCFD'/%3E%3C/svg%3E"); }
  .select.open {
    box-shadow: inset 0 0 0 2px #777E90; }
    body.dark .select.open {
      box-shadow: inset 0 0 0 2px #777E90; }
    .select.open:before {
      transform: translateY(-50%) rotate(180deg); }
  .select .current {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .select .list {
    right: 0;
    margin-top: 2px;
    border-radius: 12px;
    background: #FCFCFD;
    border: 2px solid #E6E8EC;
    box-shadow: 0 4px 12px rgba(35, 38, 47, 0.1); }
    body.dark .select .list {
      background: #141416;
      border-color: #353945;
      box-shadow: 0 4px 12px rgba(20, 20, 22, 0.1); }
  .select .option {
    min-height: auto;
    padding: 10px 14px;
    font-weight: 500;
    line-height: 1.4; }
    .select .option:hover, .select .option.focus, .select .option.selected.focus {
      background: #F4F5F6; }
      body.dark .select .option:hover, body.dark .select .option.focus, body.dark .select .option.selected.focus {
        background: #23262F; }
    .select .option.selected {
      font-weight: 500;
      color: #3B71FE; }

.select-empty {
  float: none;
  width: 100%;
  height: 56px;
  padding: 0 64px 0 24px;
  box-shadow: none;
  background: transparent;
  border-radius: 32px;
  border: none;
  opacity: 1;
  font-family: 'DM Sans', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #3B71FE; }
  @media only screen and (max-width: 767px) {
    .select-empty {
      height: 40px;
      font-size: 32px;
      line-height: 40px; } }
  .select-empty:after {
    display: none; }
  .select-empty:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 27px;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%233B71FE'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto;
    transition: transform .2s; }
  .select-empty.open {
    background: #FCFCFD; }
    body.dark .select-empty.open {
      background: #141416; }
    .select-empty.open:before {
      transform: translateY(-50%) rotate(180deg); }
  .select-empty .current {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .select-empty .list {
    right: 0;
    margin-top: 4px;
    border: none;
    border-radius: 24px;
    background: #FCFCFD;
    box-shadow: 0px 24px 32px 0 rgba(15, 15, 15, 0.15); }
    body.dark .select-empty .list {
      border: 2px solid #141416;
      background: #141416;
      box-shadow: 0 4px 12px rgba(20, 20, 22, 0.1); }
  .select-empty .option {
    min-height: auto;
    padding: 10px 24px;
    border: none;
    font-family: 'DM Sans', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;
    color: #777E90; }
    @media only screen and (max-width: 767px) {
      .select-empty .option {
        font-size: 32px; } }
    body.dark .select-empty .option {
      padding: 10px 22px;
      color: #FCFCFD; }
    .select-empty .option:hover, .select-empty .option.focus, .select-empty .option.selected.focus {
      background: #F4F5F6; }
      body.dark .select-empty .option:hover, body.dark .select-empty .option.focus, body.dark .select-empty .option.selected.focus {
        background: #19191b; }
    .select-empty .option.selected {
      color: #3B71FE; }
      body.dark .select-empty .option.selected {
        color: #3B71FE; }

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1);
  border-radius: 50%;
  background: #FCFCFD; }
  @media only screen and (max-width: 767px) {
    .play {
      width: 48px;
      height: 48px; } }
  .play .icon {
    width: 24px;
    height: 24px;
    fill: #777E90;
    transition: all .2s; }
    @media only screen and (max-width: 767px) {
      .play .icon {
        width: 12px;
        height: 12px; } }
  .play:hover .icon {
    transform: scale(1.2);
    fill: #3B71FE; }
  .play_small {
    width: 48px;
    height: 48px; }
    .play_small .icon {
      width: 12px;
      height: 12px; }

.fancybox-caption__body {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.fancybox-image {
  border-radius: 16px; }

.fancybox-infobar {
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #777E90; }

.fancybox-bg {
  background: #141416; }

.fancybox-is-open .fancybox-bg {
  opacity: 1; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-uppercase {
  text-transform: uppercase; }

.m-auto {
  margin: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.align-baseline {
  align-items: baseline; }

@media only screen and (max-width: 1179px) {
  .desktop-hide {
    display: none !important; } }

.desktop-show {
  display: none !important; }
  @media only screen and (max-width: 1179px) {
    .desktop-show {
      display: block !important; } }

@media only screen and (max-width: 1179px) {
  .desktop-text-right {
    text-align: right !important; } }

@media only screen and (max-width: 1023px) {
  .tablet-hide {
    display: none !important; } }

.tablet-show {
  display: none !important; }
  @media only screen and (max-width: 1023px) {
    .tablet-show {
      display: block !important; } }

@media only screen and (max-width: 1023px) {
  .tablet-text-right {
    text-align: right !important; } }

@media only screen and (max-width: 767px) {
  .mobile-hide {
    display: none !important; } }

.mobile-show {
  display: none !important; }
  @media only screen and (max-width: 767px) {
    .mobile-show {
      display: block !important; } }

@media only screen and (max-width: 767px) {
  .mobile-text-right {
    text-align: right !important; } }

[class^="section"] {
  margin-bottom: 136px; }
  @media only screen and (max-width: 1179px) {
    [class^="section"] {
      margin-bottom: 112px; } }
  @media only screen and (max-width: 767px) {
    [class^="section"] {
      margin-bottom: 64px; } }

.section-mb0 {
  margin-bottom: 0; }
  @media only screen and (max-width: 1179px) {
    .section-mb0 {
      margin-bottom: 0; } }
  @media only screen and (max-width: 767px) {
    .section-mb0 {
      margin-bottom: 0; } }

.section-mb64 {
  margin-bottom: 64px; }
  @media only screen and (max-width: 1179px) {
    .section-mb64 {
      margin-bottom: 64px; } }

.section-mb80 {
  margin-bottom: 80px; }
  @media only screen and (max-width: 1179px) {
    .section-mb80 {
      margin-bottom: 64px; } }

.section-pd {
  padding: 136px 0; }
  @media only screen and (max-width: 1179px) {
    .section-pd {
      padding: 112px 0; } }
  @media only screen and (max-width: 767px) {
    .section-pd {
      padding: 64px 0; } }

.section-bg {
  background: #F4F5F6; }
  body.dark .section-bg {
    background: none;
    box-shadow: inset 0 1px 0 #23262F; }

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px; }
  @media only screen and (max-width: 1023px) {
    .container {
      padding: 0 40px; } }
  @media only screen and (max-width: 767px) {
    .container {
      padding: 0 32px; } }

.hero,
.h1,
.h2,
.h3,
.h4 {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700; }

.hero {
  font-size: 96px;
  line-height: 1;
  letter-spacing: -.02em; }
  @media only screen and (max-width: 1339px) {
    .hero {
      font-size: 80px; } }
  @media only screen and (max-width: 1179px) {
    .hero {
      font-size: 64px; } }

.h1 {
  font-size: 64px;
  line-height: 1;
  letter-spacing: -.02em; }

.h2 {
  font-size: 48px;
  line-height: 1.16667;
  letter-spacing: -.02em; }
  @media only screen and (max-width: 767px) {
    .h2 {
      font-size: 40px;
      line-height: 1.2; } }

.h3 {
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -.01em; }

.h4 {
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -.01em; }

.info {
  margin-top: 12px;
  font-size: 24px;
  line-height: 1.33333;
  letter-spacing: -.01em;
  color: #777E90; }
  @media only screen and (max-width: 767px) {
    .info {
      font-size: 16px; } }

[class^="button"] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  background: #3B71FE;
  border-radius: 24px;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  color: #FCFCFD;
  transition: all .2s; }
  [class^="button"]:hover {
    background: #0142eb; }
  [class^="button"].disabled {
    opacity: .5;
    pointer-events: none; }
  [class^="button"] .icon {
    fill: #FCFCFD;
    transition: all .2s; }
  [class^="button"]:not([class^="button-circle"]) .icon:first-child {
    margin-right: 15px; }
  [class^="button"]:not([class^="button-circle"]) .icon:last-child {
    margin-left: 15px; }

.button-stroke {
  background: none;
  box-shadow: 0 0 0 2px #E6E8EC inset;
  color: #23262F; }
  .button-stroke .icon {
    fill: #23262F; }
  body.dark .button-stroke {
    box-shadow: 0 0 0 2px #777E90 inset;
    color: #FCFCFD; }
    body.dark .button-stroke .icon {
      fill: #FCFCFD; }
  .button-stroke:hover, .button-stroke.active {
    background: #23262F;
    box-shadow: 0 0 0 2px #23262F inset;
    color: #FCFCFD; }
    .button-stroke:hover .icon, .button-stroke.active .icon {
      fill: #FCFCFD; }
    body.dark .button-stroke:hover, body.dark .button-stroke.active {
      background: #353945;
      box-shadow: 0 0 0 2px #353945 inset; }

.button-black {
  background: #141416; }
  .button-black:hover, .button-black.active {
    background: #23262F; }
  body.dark .button-black {
    box-shadow: inset 0 0 0 2px #353945; }
    body.dark .button-black:hover, body.dark .button-black.active {
      background: #353945; }

.button-white {
  background: #FCFCFD;
  color: #23262F; }
  .button-white .icon {
    fill: #23262F; }
  .button-white:hover {
    background: #F4F5F6; }

.button-small {
  height: 40px;
  border-radius: 20px;
  padding: 0 16px;
  font-size: 14px; }

.button-circle-stroke {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #E6E8EC inset;
  background: transparent;
  transition: all .2s; }
  .button-circle-stroke .icon {
    fill: #777E90; }
  .button-circle-stroke:hover {
    background: #23262F;
    box-shadow: 0 0 0 2px #23262F inset; }
    .button-circle-stroke:hover .icon {
      fill: #FCFCFD; }
  body.dark .button-circle-stroke {
    box-shadow: 0 0 0 2px #353945 inset; }
    body.dark .button-circle-stroke:hover {
      background: #353945; }

.button-circle-stroke.button-small {
  flex: 0 0 40px;
  width: 40px;
  height: 40px; }

[class^="status"] {
  display: inline-block;
  padding: 0 12px;
  background: #E6E8EC;
  border-radius: 13px;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: #353945; }
  body.dark [class^="status"] {
    background: #353945;
    color: #E6E8EC; }

.status-black {
  background: #23262F;
  color: #FCFCFD; }
  body.dark .status-black {
    background: #FCFCFD;
    box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1);
    color: #23262F; }

.status-gray {
  background: #F4F5F6;
  color: #777E90; }
  body.dark .status-gray {
    background: #141416;
    color: #777E90; }

[class^="category"] {
  display: inline-block;
  padding: 11px 8px 9px;
  background: #FCFCFD;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #353945; }

.category-blue {
  background: #3B71FE;
  color: #FCFCFD; }

[class^="stage"] {
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #777E90; }

.stage-small {
  font-size: 12px; }

.header {
  position: relative;
  z-index: 10;
  padding: 24px 0; }
  @media only screen and (max-width: 767px) {
    .header {
      padding: 48px 0 24px; } }
  .header__center {
    display: flex;
    align-items: center; }
  .header__logo {
    margin-right: 40px; }
    @media only screen and (max-width: 1179px) {
      .header__logo {
        margin-right: 32px; } }
    @media only screen and (max-width: 767px) {
      .header__logo {
        position: relative;
        z-index: 15;
        margin-right: auto; } }
    .header__logo img {
      width: 106px; }
  .header__wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 40px;
    border-left: 1px solid #E6E8EC; }
    @media only screen and (max-width: 1179px) {
      .header__wrapper {
        padding-left: 32px; } }
    @media only screen and (max-width: 1023px) {
      .header__wrapper {
        padding-left: 0;
        border: none; } }
    @media only screen and (max-width: 767px) {
      .header__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        flex-direction: column;
        align-items: stretch;
        height: calc(var(--vh, 1vh) * 100);
        padding: 132px 32px 48px;
        background: #FCFCFD;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all .3s; }
        body.dark .header__wrapper {
          background: #141416; }
        .header__wrapper.visible {
          visibility: visible;
          opacity: 1;
          pointer-events: all; } }
    body.dark .header__wrapper {
      border-color: #23262F; }
  .header__item {
    position: relative; }
    @media only screen and (max-width: 767px) {
      .header__item {
        position: static; } }
    @media only screen and (max-width: 1023px) {
      .header__item_dropdown {
        margin-right: auto; } }
    @media only screen and (max-width: 767px) {
      .header__item_dropdown {
        margin: 0; } }
    .header__item_language {
      margin-left: 40px; }
      @media only screen and (max-width: 1023px) {
        .header__item_language {
          margin-left: 32px; } }
      @media only screen and (max-width: 767px) {
        .header__item_language {
          display: none; } }
  .header__head {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .header__item_dropdown .header__head,
  .header__item_language .header__head {
    display: flex;
    align-items: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90;
    transition: color .2s; }
    @media only screen and (max-width: 767px) {
      .header__item_dropdown .header__head,
      .header__item_language .header__head {
        display: none; } }
    .header__item_dropdown .header__head .icon,
    .header__item_language .header__head .icon {
      fill: #777E90;
      transition: all .2s; }
    .header__item_dropdown .header__head:hover,
    .header__item_language .header__head:hover {
      color: #23262F; }
      .header__item_dropdown .header__head:hover .icon,
      .header__item_language .header__head:hover .icon {
        fill: #23262F; }
      body.dark .header__item_dropdown .header__head:hover, body.dark
      .header__item_language .header__head:hover {
        color: #E6E8EC; }
        body.dark .header__item_dropdown .header__head:hover .icon, body.dark
        .header__item_language .header__head:hover .icon {
          fill: #E6E8EC; }
  .header__item_dropdown .header__head .icon {
    width: 24px;
    height: 24px;
    margin-left: 8px; }
  .header__item_dropdown.active .header__head {
    color: #23262F; }
    .header__item_dropdown.active .header__head .icon {
      transform: rotate(180deg);
      fill: #23262F; }
    body.dark .header__item_dropdown.active .header__head {
      color: #E6E8EC; }
      body.dark .header__item_dropdown.active .header__head .icon {
        fill: #E6E8EC; }
  .header__item_language .header__head {
    height: 24px; }
    .header__item_language .header__head .icon {
      width: 16px;
      height: 16px;
      margin-right: 12px; }
  .header__item_language.active .header__head {
    color: #23262F; }
    .header__item_language.active .header__head .icon {
      fill: #23262F; }
    body.dark .header__item_language.active .header__head {
      color: #E6E8EC; }
      body.dark .header__item_language.active .header__head .icon {
        fill: #E6E8EC; }
  .header__item_notification .header__head {
    position: relative; }
    .header__item_notification .header__head .icon {
      width: 24px;
      height: 24px;
      fill: #777E90;
      transition: fill .2s; }
    .header__item_notification .header__head:hover .icon {
      fill: #23262F; }
      body.dark .header__item_notification .header__head:hover .icon {
        fill: #E6E8EC; }
    .header__item_notification .header__head.active:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #58C27D; }
  .header__item_notification.active .header__head svg {
    fill: #23262F; }
    body.dark .header__item_notification.active .header__head svg {
      fill: #E6E8EC; }
  .header__item_user .header__head img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%; }
  .header__item_notification .header__head,
  .header__item_user .header__head {
    width: 40px;
    height: 40px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .header__body {
    position: absolute;
    background: #FCFCFD;
    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    transition: all .3s; }
  .header__item.active .header__body {
    visibility: visible;
    opacity: 1;
    transform: translateY(0); }
  .header__item_dropdown .header__body,
  .header__item_language .header__body,
  .header__item_user .header__body {
    position: absolute;
    width: 292px;
    padding: 16px;
    border-radius: 20px;
    box-shadow: 0px 40px 32px -8px rgba(15, 15, 15, 0.12); }
  .header__item_dropdown .header__body,
  .header__item_language .header__body {
    top: calc(100% + 41px);
    left: -40px; }
  @media only screen and (max-width: 767px) {
    .header__item_dropdown .header__body {
      position: static;
      width: auto;
      margin: 0 -32px;
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      background: none;
      visibility: visible;
      transform: translateY(0);
      opacity: 1; } }
  body.dark .header__item_dropdown .header__body {
    background: #141416;
    box-shadow: 0px 10px 32px #23262F; }
    @media only screen and (max-width: 767px) {
      body.dark .header__item_dropdown .header__body {
        box-shadow: none;
        background: none; } }
  body.dark .header__item_language .header__body {
    background: linear-gradient(92.98deg, #23262F 3.54%, rgba(35, 38, 47, 0.7) 93.38%); }
  .header__item_notification .header__body {
    position: absolute;
    top: calc(100% + 32px);
    right: -136px;
    width: 354px;
    padding: 40px 32px 32px;
    border-radius: 24px;
    box-shadow: 0px 16px 64px -24px rgba(31, 47, 70, 0.4); }
    @media only screen and (max-width: 1419px) {
      .header__item_notification .header__body {
        right: -126px; } }
    @media only screen and (max-width: 1339px) {
      .header__item_notification .header__body {
        right: -96px; } }
    @media only screen and (max-width: 767px) {
      .header__item_notification .header__body {
        top: 100%;
        left: 16px;
        right: 16px;
        width: auto;
        padding: 32px 24px; } }
    body.dark .header__item_notification .header__body {
      background: linear-gradient(92.98deg, #23262F 3.54%, rgba(35, 38, 47, 0.7) 93.38%); }
      @media only screen and (max-width: 767px) {
        body.dark .header__item_notification .header__body {
          background: linear-gradient(92.98deg, #23262F 3.54%, rgba(35, 38, 47, 0.9) 93.38%); } }
  .header__item_user .header__body {
    top: calc(100% + 32px);
    right: -72px; }
    @media only screen and (max-width: 1419px) {
      .header__item_user .header__body {
        right: -62px; } }
    @media only screen and (max-width: 1339px) {
      .header__item_user .header__body {
        right: -32px; } }
    @media only screen and (max-width: 767px) {
      .header__item_user .header__body {
        top: 100%;
        left: 16px;
        right: 16px;
        width: auto; } }
    body.dark .header__item_user .header__body {
      background: #141416;
      box-shadow: 0px 10px 32px #23262F; }
  .header__item[href] {
    margin-left: auto;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90;
    transition: color .2s; }
    @media only screen and (max-width: 1023px) {
      .header__item[href] {
        display: none; } }
    @media only screen and (max-width: 767px) {
      .header__item[href] {
        display: flex;
        align-items: center;
        margin: 0 -32px;
        padding: 0 32px;
        height: 64px;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 600; } }
    .header__item[href]:hover, .header__item[href].active {
      color: #23262F; }
      @media only screen and (max-width: 767px) {
        .header__item[href]:hover, .header__item[href].active {
          background: none;
          box-shadow: inset 2px 0 0 #3B71FE; } }
      body.dark .header__item[href]:hover, body.dark .header__item[href].active {
        color: #E6E8EC; }
        @media only screen and (max-width: 767px) {
          body.dark .header__item[href]:hover, body.dark .header__item[href].active {
            background: none;
            color: #E6E8EC; } }
  .header__link {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 20px;
    border-radius: 24px;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90;
    transition: all .2s; }
    .header__link .icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      fill: #777E90;
      transition: fill .2s; }
    .header__link:hover {
      color: #23262F; }
      .header__link:hover .icon {
        fill: #23262F; }
      body.dark .header__link:hover {
        color: #E6E8EC; }
        body.dark .header__link:hover .icon {
          fill: #E6E8EC; }
    .header__link.active {
      background: #23262F;
      color: #E6E8EC; }
      body.dark .header__link.active {
        background: #FCFCFD;
        color: #353945; }
      .header__link.active .icon {
        fill: #E6E8EC; }
        body.dark .header__link.active .icon {
          fill: #353945; }
  @media only screen and (max-width: 767px) {
    .header__item_dropdown .header__link {
      height: 64px;
      padding: 0 32px;
      border-radius: 0;
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-weight: 600; } }
  @media only screen and (max-width: 767px) {
    .header__item_dropdown .header__link .icon {
      display: none; } }
  @media only screen and (max-width: 767px) {
    .header__item_dropdown .header__link.active {
      background: none;
      box-shadow: inset 2px 0 0 #3B71FE;
      color: #23262F; } }
  @media only screen and (max-width: 767px) {
    body.dark .header__item_dropdown .header__link.active {
      background: none;
      color: #E6E8EC; } }
  .header__list {
    display: flex;
    flex-wrap: wrap;
    margin: -4px -2px 0; }
  .header__box {
    flex: 0 0 calc(50% - 4px);
    width: calc(50% - 4px);
    margin: 4px 2px 0;
    padding: 12px 20px;
    border-radius: 8px;
    transition: background .2s; }
    .header__box:hover, .header__box.active {
      background: #F4F5F6; }
      body.dark .header__box:hover, body.dark .header__box.active {
        background: #141416; }
    .header__box.active .header__category {
      color: #23262F; }
      body.dark .header__box.active .header__category {
        color: #E6E8EC; }
  .header__category {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90;
    transition: color .2s; }
    body.dark .header__category {
      color: #E6E8EC; }
  .header__country {
    font-size: 12px;
    line-height: 1.66667;
    color: #B1B5C3; }
  .header__title {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600;
    letter-spacing: -.01em; }
  .header__notifications {
    margin: 0 -16px; }
  .header__notification {
    position: relative;
    display: flex;
    padding: 16px 40px 16px 16px;
    border-radius: 16px;
    transition: background .2s; }
    .header__notification:hover {
      background: #F4F5F6; }
      body.dark .header__notification:hover {
        background: #23262F; }
  .header__avatar {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 24px; }
    @media only screen and (max-width: 767px) {
      .header__avatar {
        margin-right: 16px; } }
    .header__avatar img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%; }
  .header__details {
    flex-grow: 1; }
  .header__subtitle {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    color: #141416; }
    body.dark .header__subtitle {
      color: #FCFCFD; }
  .header__content {
    color: #353945; }
    body.dark .header__content {
      color: #E6E8EC; }
  .header__date {
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
  .header__status {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    border-radius: 50%; }
  .header__group {
    margin-bottom: 16px; }
  .header__menu:not(:last-child) {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #E6E8EC; }
    body.dark .header__menu:not(:last-child) {
      border-color: #353945; }
  .header__btns {
    display: flex;
    margin: 0 -4px; }
  .header__btns .header__button {
    flex: 0 0 calc(50% - 8px);
    width: calc(50% - 8px);
    margin: 0 4px; }
  .header__wrapper > .header__button {
    margin-left: 40px;
    flex-shrink: 0; }
    @media only screen and (max-width: 1023px) {
      .header__wrapper > .header__button {
        margin-left: 32px; } }
    @media only screen and (max-width: 767px) {
      .header__wrapper > .header__button {
        width: 100%;
        height: 48px;
        margin: auto 0 0; } }
    .header__wrapper > .header__button.active {
      background: #23262F;
      box-shadow: inset 0 0 0 2px #23262F;
      color: #FCFCFD; }
  .header__item_notification, .header__item_user, .header__login {
    margin-left: 24px; }
    @media only screen and (max-width: 767px) {
      .header__item_notification, .header__item_user, .header__login {
        margin-left: 20px; } }
  .header__login {
    display: none;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #58C27D;
    transition: background .2s; }
    .header__login .icon {
      width: 24px;
      height: 24px;
      fill: #FCFCFD; }
    .header__login:hover {
      background: #41b168; }
  .header__burger {
    display: none; }
    @media only screen and (max-width: 767px) {
      .header__burger {
        display: block;
        position: relative;
        z-index: 15;
        margin-left: 20px;
        width: 32px;
        height: 32px;
        background: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
        .header__burger.active:before {
          transform: translateY(0) rotate(-45deg); }
        .header__burger.active:after {
          transform: translateY(0) rotate(45deg); }
        .header__burger:before, .header__burger:after {
          content: '';
          position: absolute;
          top: 16px;
          left: 6px;
          width: 20px;
          height: 2px;
          background: #777E90;
          border-radius: 2px;
          transition: transform .2s; }
        .header__burger:before {
          transform: translateY(-4px); }
        .header__burger:after {
          transform: translateY(3px); } }
  .header_border {
    box-shadow: inset 0 -1px 0 0 #E6E8EC; }
    body.dark .header_border {
      box-shadow: inset 0 -1px 0 0 #23262F; }
  .header_wide .header__center {
    max-width: 100%; }
  .header.authorization .header__item_user {
    display: none; }
  .header.authorization .header__login {
    display: flex; }

.panel {
  position: relative;
  z-index: 5;
  padding: 40px; }
  @media only screen and (max-width: 1179px) {
    .panel {
      padding: 32px 32px 24px; } }
  @media only screen and (max-width: 767px) {
    .panel {
      padding: 32px 20px 24px; } }
  .panel__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 24px;
    box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1); }
    @media only screen and (max-width: 767px) {
      .panel__background {
        border-radius: 20px;
        box-shadow: 0px 16px 32px rgba(15, 15, 15, 0.1); } }
    .panel__background:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(83.59deg, #FCFCFD 36.52%, rgba(252, 252, 253, 0.83) 98.8%);
      border: 1px solid #FCFCFD;
      backdrop-filter: blur(32px);
      border-radius: 24px; }
      @media only screen and (max-width: 767px) {
        .panel__background:before {
          border-radius: 20px; } }
      body.dark .panel__background:before {
        background: linear-gradient(92.98deg, #23262F 3.54%, rgba(35, 38, 47, 0.7) 93.38%);
        border-color: #353945; }
  .panel__nav {
    position: relative;
    z-index: 2;
    display: flex;
    margin-bottom: 24px;
    box-shadow: inset 0 -1px 0 0 #E6E8EC; }
    @media only screen and (max-width: 767px) {
      .panel__nav {
        margin: 0 -20px 16px;
        padding: 0 20px; } }
    body.dark .panel__nav {
      box-shadow: inset 0 -1px 0 0 rgba(119, 126, 144, 0.3); }
  .panel__link {
    padding-bottom: 30px;
    border-bottom: 1px solid transparent;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90;
    transition: all .2s; }
    @media only screen and (max-width: 1179px) {
      .panel__link {
        padding-bottom: 24px; } }
    .panel__link:hover {
      color: #23262F; }
    .panel__link.active {
      border-color: #23262F;
      color: #23262F; }
    body.dark .panel__link {
      color: #B1B5C3; }
      body.dark .panel__link:hover {
        color: #FCFCFD; }
      body.dark .panel__link.active {
        border-color: #FCFCFD;
        color: #FCFCFD; }
    .panel__link:not(:last-child) {
      margin-right: 48px; }
      @media only screen and (max-width: 767px) {
        .panel__link:not(:last-child) {
          margin-right: auto; } }
  .panel__body {
    position: relative;
    z-index: 3; }
  .panel__row {
    display: flex; }
    @media only screen and (max-width: 1339px) {
      .panel__row {
        margin: 0 -20px; } }
    @media only screen and (max-width: 1023px) {
      .panel__row {
        margin: 0; } }
    @media only screen and (max-width: 767px) {
      .panel__row {
        display: block; } }
  .panel__search {
    position: absolute;
    top: 50%;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    transform: translateY(-50%);
    background: #3B71FE;
    border-radius: 50%;
    transition: background .2s; }
    @media only screen and (max-width: 1339px) {
      .panel__search {
        right: 0; } }
    @media only screen and (max-width: 1179px) {
      .panel__search {
        width: 56px;
        height: 56px; } }
    @media only screen and (max-width: 1023px) {
      .panel__search {
        top: auto;
        bottom: 2px;
        transform: translateY(0); } }
    .panel__search .icon {
      width: 24px;
      height: 24px;
      fill: #FCFCFD; }
    .panel__search span {
      display: none;
      font-family: 'DM Sans', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      color: #FCFCFD; }
    .panel__search:hover {
      background: #225ffe; }
  .panel__controls {
    position: relative;
    z-index: 2;
    display: flex;
    margin-bottom: 16px;
    padding-top: 8px; }
    @media only screen and (max-width: 767px) {
      .panel__controls {
        flex-wrap: wrap;
        margin-bottom: 0;
        padding-top: 0; } }
    @media only screen and (max-width: 767px) {
      .panel__controls .panel__button {
        margin-bottom: 12px;
        padding: 0 24px; } }
    .panel__controls .panel__button:not(:last-child) {
      margin-right: 16px; }
      @media only screen and (max-width: 767px) {
        .panel__controls .panel__button:not(:last-child) {
          margin-right: 8px; } }
    .panel__controls .panel__button.active {
      box-shadow: inset 0 0 0 2px #23262F;
      background: #23262F;
      color: #FCFCFD; }
      body.dark .panel__controls .panel__button.active {
        box-shadow: inset 0 0 0 2px #E6E8EC;
        background: #E6E8EC;
        color: #23262F; }
  .panel_stays, .panel_flights, .panel_tasks, .panel_cars {
    margin: -175px 80px 0; }
    @media only screen and (max-width: 1419px) {
      .panel_stays, .panel_flights, .panel_tasks, .panel_cars {
        margin: -175px 40px 0; } }
    @media only screen and (max-width: 1179px) {
      .panel_stays, .panel_flights, .panel_tasks, .panel_cars {
        margin-top: -148px; } }
    @media only screen and (max-width: 1023px) {
      .panel_stays, .panel_flights, .panel_tasks, .panel_cars {
        margin: -110px 16px 0; } }
    @media only screen and (max-width: 767px) {
      .panel_stays, .panel_flights, .panel_tasks, .panel_cars {
        margin: -110px 8px 0; } }
  @media only screen and (max-width: 767px) {
    .panel_stays {
      margin-top: -180px; } }
  @media only screen and (max-width: 767px) {
    .panel_flights {
      margin-top: -210px; } }
  @media only screen and (max-width: 1023px) {
    .panel_flights .date-picker-wrapper,
    .panel_flights-category .date-picker-wrapper,
    .panel_cars .date-picker-wrapper,
    .panel_cars-category .date-picker-wrapper {
      left: 20% !important; } }
  @media only screen and (max-width: 767px) {
    .panel_flights .date-picker-wrapper,
    .panel_flights-category .date-picker-wrapper,
    .panel_cars .date-picker-wrapper,
    .panel_cars-category .date-picker-wrapper {
      left: 50% !important; } }
  @media only screen and (max-width: 767px) {
    .panel_cars {
      margin-top: -245px; } }
  .panel_stays-category {
    margin: -90px 80px 0; }
    @media only screen and (max-width: 1419px) {
      .panel_stays-category {
        margin: -90px 40px 0; } }
    @media only screen and (max-width: 1179px) {
      .panel_stays-category {
        margin-top: -64px;
        padding: 24px 40px; } }
    @media only screen and (max-width: 1023px) {
      .panel_stays-category {
        padding: 24px; } }
    @media only screen and (max-width: 767px) {
      .panel_stays-category {
        margin: -100px 8px 0; } }
  .panel_flights-category, .panel_cars-category {
    margin: -175px 0 0; }
    @media only screen and (max-width: 1179px) {
      .panel_flights-category, .panel_cars-category {
        margin-top: -140px; } }
  .panel_tasks-category {
    margin: -77px 80px 0; }
    @media only screen and (max-width: 1419px) {
      .panel_tasks-category {
        margin: -77px 40px 0; } }
    @media only screen and (max-width: 767px) {
      .panel_tasks-category {
        margin: -100px 8px 0; } }
  .panel_stays .panel__row > div:first-child,
  .panel_stays-category .panel__row > div:first-child {
    flex: 0 0 300px; }
    @media only screen and (max-width: 1419px) {
      .panel_stays .panel__row > div:first-child,
      .panel_stays-category .panel__row > div:first-child {
        flex: 0 0 270px; } }
    @media only screen and (max-width: 1179px) {
      .panel_stays .panel__row > div:first-child,
      .panel_stays-category .panel__row > div:first-child {
        flex: 0 0 250px; } }
    @media only screen and (max-width: 1023px) {
      .panel_stays .panel__row > div:first-child,
      .panel_stays-category .panel__row > div:first-child {
        flex: 0 0 220px; } }
  .panel_stays .panel__row > div:nth-child(2),
  .panel_stays-category .panel__row > div:nth-child(2) {
    flex-grow: 1; }
  .panel_stays .panel__row > div:nth-child(3),
  .panel_stays-category .panel__row > div:nth-child(3) {
    flex: 0 0 296px; }
    @media only screen and (max-width: 1179px) {
      .panel_stays .panel__row > div:nth-child(3),
      .panel_stays-category .panel__row > div:nth-child(3) {
        flex: 0 0 240px; } }
    @media only screen and (max-width: 1023px) {
      .panel_stays .panel__row > div:nth-child(3),
      .panel_stays-category .panel__row > div:nth-child(3) {
        flex: 0 0 170px; } }
  .panel_flights .panel__row {
    padding-right: 100px; }
    @media only screen and (max-width: 1339px) {
      .panel_flights .panel__row {
        padding-right: 90px; } }
    @media only screen and (max-width: 1023px) {
      .panel_flights .panel__row {
        padding-right: 70px; } }
    @media only screen and (max-width: 767px) {
      .panel_flights .panel__row {
        padding-right: 0; } }
  .panel_flights .panel__row > div:first-child, .panel_flights .panel__row > div:nth-child(2) {
    flex: 0 0 248px; }
    @media only screen and (max-width: 1339px) {
      .panel_flights .panel__row > div:first-child, .panel_flights .panel__row > div:nth-child(2) {
        flex: 0 0 225px; } }
    @media only screen and (max-width: 1179px) {
      .panel_flights .panel__row > div:first-child, .panel_flights .panel__row > div:nth-child(2) {
        flex: 0 0 200px; } }
    @media only screen and (max-width: 1023px) {
      .panel_flights .panel__row > div:first-child, .panel_flights .panel__row > div:nth-child(2) {
        flex: 0 0 170px; } }
    @media only screen and (max-width: 767px) {
      .panel_flights .panel__row > div:first-child, .panel_flights .panel__row > div:nth-child(2) {
        flex: 0 0 50%; } }
  .panel_flights .panel__row > div:nth-child(3) {
    flex-grow: 1; }
  @media only screen and (max-width: 767px) {
    .panel_tasks-category .panel__row {
      margin: -12px 0 -8px; } }
  .panel_tasks .panel__row > div:first-child,
  .panel_tasks-category .panel__row > div:first-child {
    flex-grow: 1; }
  .panel_tasks .panel__row > div:nth-child(2),
  .panel_tasks-category .panel__row > div:nth-child(2) {
    flex: 0 0 300px; }
    @media only screen and (max-width: 1339px) {
      .panel_tasks .panel__row > div:nth-child(2),
      .panel_tasks-category .panel__row > div:nth-child(2) {
        flex: 0 0 280px; } }
    @media only screen and (max-width: 1179px) {
      .panel_tasks .panel__row > div:nth-child(2),
      .panel_tasks-category .panel__row > div:nth-child(2) {
        flex: 0 0 250px; } }
    @media only screen and (max-width: 1023px) {
      .panel_tasks .panel__row > div:nth-child(2),
      .panel_tasks-category .panel__row > div:nth-child(2) {
        flex: 0 0 180px; } }
  .panel_tasks .panel__row > div:nth-child(3),
  .panel_tasks-category .panel__row > div:nth-child(3) {
    flex: 0 0 296px; }
    @media only screen and (max-width: 1023px) {
      .panel_tasks .panel__row > div:nth-child(3),
      .panel_tasks-category .panel__row > div:nth-child(3) {
        flex: 0 0 210px; } }
  @media only screen and (max-width: 767px) {
    .panel_flights .panel__controls .panel__button,
    .panel_flights-category .panel__controls .panel__button {
      padding: 0 12px;
      font-size: 13px; } }
  .panel_cars-category .panel__controls {
    padding-top: 0; }
  @media only screen and (max-width: 767px) {
    .panel_cars .panel__controls .panel__button,
    .panel_cars-category .panel__controls .panel__button {
      width: 100%; } }
  .panel_flights-category .panel__row {
    padding-right: 80px; }
    @media only screen and (max-width: 1023px) {
      .panel_flights-category .panel__row {
        padding-right: 64px; } }
    @media only screen and (max-width: 767px) {
      .panel_flights-category .panel__row {
        padding: 0; } }
  .panel_flights-category .panel__row > div:first-child, .panel_flights-category .panel__row > div:nth-child(2) {
    flex: 0 0 280px; }
    @media only screen and (max-width: 1339px) {
      .panel_flights-category .panel__row > div:first-child, .panel_flights-category .panel__row > div:nth-child(2) {
        flex: 0 0 250px; } }
    @media only screen and (max-width: 1179px) {
      .panel_flights-category .panel__row > div:first-child, .panel_flights-category .panel__row > div:nth-child(2) {
        flex: 0 0 230px; } }
    @media only screen and (max-width: 1023px) {
      .panel_flights-category .panel__row > div:first-child, .panel_flights-category .panel__row > div:nth-child(2) {
        flex: 0 0 140px; } }
  .panel_flights-category .panel__row > div:nth-child(3) {
    flex-grow: 1; }
  .panel_cars .panel__row,
  .panel_cars-category .panel__row {
    padding-right: 100px; }
    @media only screen and (max-width: 1023px) {
      .panel_cars .panel__row,
      .panel_cars-category .panel__row {
        padding-right: 80px; } }
    @media only screen and (max-width: 767px) {
      .panel_cars .panel__row,
      .panel_cars-category .panel__row {
        padding: 0; } }
  .panel_cars .panel__row > div:first-child,
  .panel_cars-category .panel__row > div:first-child {
    flex-grow: 1; }
  .panel_cars .panel__row > div:nth-child(2),
  .panel_cars-category .panel__row > div:nth-child(2) {
    flex-shrink: 0;
    flex: 0 0 550px; }
    @media only screen and (max-width: 1339px) {
      .panel_cars .panel__row > div:nth-child(2),
      .panel_cars-category .panel__row > div:nth-child(2) {
        flex: 0 0 500px; } }
    @media only screen and (max-width: 1179px) {
      .panel_cars .panel__row > div:nth-child(2),
      .panel_cars-category .panel__row > div:nth-child(2) {
        flex: 0 0 450px; } }
    @media only screen and (max-width: 1023px) {
      .panel_cars .panel__row > div:nth-child(2),
      .panel_cars-category .panel__row > div:nth-child(2) {
        flex: 0 0 300px; } }
  @media only screen and (max-width: 767px) {
    .panel_flights .panel__search,
    .panel_flights-category .panel__search,
    .panel_cars .panel__search,
    .panel_cars-category .panel__search {
      position: static;
      width: 100%;
      height: 48px;
      margin-top: 12px;
      border-radius: 24px; }
      .panel_flights .panel__search .icon,
      .panel_flights-category .panel__search .icon,
      .panel_cars .panel__search .icon,
      .panel_cars-category .panel__search .icon {
        display: none; }
      .panel_flights .panel__search span,
      .panel_flights-category .panel__search span,
      .panel_cars .panel__search span,
      .panel_cars-category .panel__search span {
        display: inline; } }

@media only screen and (max-width: 767px) {
  .main {
    margin-bottom: 64px; } }

.main__center {
  max-width: 1440px; }
  @media only screen and (max-width: 1419px) {
    .main__center {
      padding: 0 40px; } }
  @media only screen and (max-width: 1023px) {
    .main__center {
      padding: 0; } }
  @media only screen and (max-width: 767px) {
    .main__center {
      padding: 0 8px; } }

.main__preview {
  position: relative; }
  .main__preview:before {
    content: "";
    display: block; }
  .main__preview img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    object-fit: cover; }
    @media only screen and (max-width: 1023px) {
      .main__preview img {
        border-radius: 0; } }
    @media only screen and (max-width: 767px) {
      .main__preview img {
        position: static;
        border-radius: 16px 16px 0 0;
        object-position: 50% 100%; } }

.main__bg {
  height: 254px; }
  @media only screen and (max-width: 767px) {
    .main__bg {
      height: 200px; } }
  .main__bg img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.main__wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 145px 80px; }
  @media only screen and (max-width: 1179px) {
    .main__wrap {
      padding: 98px 80px; } }
  @media only screen and (max-width: 1023px) {
    .main__wrap {
      padding: 80px 40px; } }
  @media only screen and (max-width: 767px) {
    .main__wrap {
      padding: 80px 16px;
      text-align: center; } }

.main__title {
  margin-bottom: 16px; }
  body.dark .main__title {
    color: #23262F; }

.main__info {
  margin-top: 0;
  margin-bottom: 24px;
  color: #23262F; }
  @media only screen and (max-width: 1179px) {
    .main__info {
      font-size: 16px; } }

@media only screen and (max-width: 1179px) {
  .main_stays, .main_flights, .main_tasks {
    margin-bottom: 96px; } }

@media only screen and (max-width: 767px) {
  .main_stays, .main_flights, .main_tasks {
    margin-bottom: 64px; } }

.main_stays .main__preview:before,
.main_flights .main__preview:before,
.main_tasks .main__preview:before,
.main_cars .main__preview:before {
  padding-bottom: 60.63%; }
  @media only screen and (max-width: 1179px) {
    .main_stays .main__preview:before,
    .main_flights .main__preview:before,
    .main_tasks .main__preview:before,
    .main_cars .main__preview:before {
      padding-bottom: 64%; } }
  @media only screen and (max-width: 767px) {
    .main_stays .main__preview:before,
    .main_flights .main__preview:before,
    .main_tasks .main__preview:before,
    .main_cars .main__preview:before {
      display: none; } }

@media only screen and (max-width: 767px) {
  .main_stays .main__preview img {
    height: 780px; } }

@media only screen and (max-width: 767px) {
  .main_flights .main__preview img,
  .main_cars .main__preview img {
    height: 700px; } }

@media only screen and (max-width: 767px) {
  .main_tasks .main__preview img {
    height: 550px;
    object-position: 50% 35%; } }

.main_tasks-category .main__preview:before {
  padding-bottom: 46%; }
  @media only screen and (max-width: 767px) {
    .main_tasks-category .main__preview:before {
      display: none; } }

@media only screen and (max-width: 767px) {
  .main_tasks-category .main__preview img {
    height: 450px;
    object-position: 50% 100%; } }

.main_stays .main__title,
.main_flights .main__title {
  max-width: 450px; }

@media only screen and (max-width: 767px) {
  .main_tasks .main__title,
  .main_tasks-category .main__title,
  .main_cars .main__title {
    font-size: 48px; } }

.main_stays-category .main__preview:before {
  padding-bottom: 46%; }
  @media only screen and (max-width: 767px) {
    .main_stays-category .main__preview:before {
      display: none; } }

@media only screen and (max-width: 767px) {
  .main_stays-category .main__preview img {
    height: 500px; } }

.main_stays-category .main__wrap,
.main_tasks .main__wrap,
.main_cars .main__wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 80px 184px;
  text-align: center; }
  @media only screen and (max-width: 1179px) {
    .main_stays-category .main__wrap,
    .main_tasks .main__wrap,
    .main_cars .main__wrap {
      padding: 80px 80px 160px; } }
  @media only screen and (max-width: 1023px) {
    .main_stays-category .main__wrap,
    .main_tasks .main__wrap,
    .main_cars .main__wrap {
      padding: 50px 64px 100px; } }
  @media only screen and (max-width: 767px) {
    .main_stays-category .main__wrap,
    .main_tasks .main__wrap,
    .main_cars .main__wrap {
      padding: 24px 16px 72px; } }

@media only screen and (max-width: 767px) {
  .main_cars .main__wrap {
    display: block;
    padding-top: 108px; } }

.main_tasks-category .main__wrap {
  text-align: center; }

@media only screen and (max-width: 767px) {
  .main_flights .main__info {
    font-size: 24px; } }

.main_flights-category .main__center {
  max-width: 1440px; }
  @media only screen and (max-width: 1419px) {
    .main_flights-category .main__center {
      padding: 0 40px; } }
  @media only screen and (max-width: 767px) {
    .main_flights-category .main__center {
      padding: 0 8px; } }

.adventure__head {
  margin-bottom: 64px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .adventure__head {
      margin-bottom: 32px; } }

.adventure__wrapper {
  margin: 0 -16px; }

.adventure__slider {
  padding-bottom: 106px;
  visibility: hidden; }
  @media only screen and (max-width: 1179px) {
    .adventure__slider {
      padding-bottom: 80px; } }
  .adventure__slider.slick-initialized {
    visibility: visible; }

.adventure__item {
  display: flex !important;
  align-items: center; }

.adventure__preview {
  flex-shrink: 0;
  width: 160px; }
  @media only screen and (max-width: 1179px) {
    .adventure__preview {
      width: 112px; } }
  .adventure__preview img {
    width: 100%; }

.adventure__details {
  flex-grow: 1;
  padding: 0 24px 0 16px; }
  @media only screen and (max-width: 1339px) {
    .adventure__details {
      padding-right: 0; } }
  @media only screen and (max-width: 1179px) {
    .adventure__details {
      padding: 0 0 0 16px; } }

.adventure__subtitle {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.adventure .slick-list {
  overflow: visible; }
  @media only screen and (max-width: 474px) {
    .adventure .slick-list {
      margin: 0 -265px 0 0; } }

.adventure .slick-slide {
  margin: 0 16px;
  opacity: 0;
  visibility: hidden;
  transition: all .4s; }
  .adventure .slick-slide.slick-active {
    opacity: 1;
    visibility: visible; }

.adventure .slick-arrow {
  bottom: 0; }

.adventure .slick-prev {
  left: calc(50% - 44px); }

.adventure .slick-next {
  right: calc(50% - 44px); }

.travel__head {
  max-width: 640px;
  margin: 0 auto 127px;
  text-align: center; }
  @media only screen and (max-width: 1339px) {
    .travel__head {
      margin-bottom: 80px; } }
  @media only screen and (max-width: 767px) {
    .travel__head {
      margin-bottom: 48px;
      text-align: left; } }

.travel__row {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 1023px) {
    .travel__row {
      display: block; } }

.travel__col:first-child {
  flex-grow: 1;
  padding-right: 128px; }
  @media only screen and (max-width: 1023px) {
    .travel__col:first-child {
      margin-bottom: 64px;
      padding: 0; } }

.travel__col:nth-child(2) {
  flex-shrink: 0;
  width: 454px; }
  @media only screen and (max-width: 1023px) {
    .travel__col:nth-child(2) {
      width: 100%; } }

.travel__list {
  max-width: 352px;
  margin-bottom: 40px; }
  @media only screen and (max-width: 1023px) {
    .travel__list {
      max-width: 100%; } }

.travel__item:not(:last-child) {
  margin-bottom: 40px; }

.travel__number {
  display: inline-block;
  min-width: 38px;
  margin-bottom: 24px;
  padding: 0 8px;
  border-radius: 12px;
  text-align: center;
  font-weight: 500;
  line-height: 24px;
  color: #FCFCFD; }

.travel__subtitle {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em; }

.travel__text {
  color: #777E90; }

.travel__slider {
  visibility: hidden; }
  .travel__slider.slick-initialized {
    visibility: visible; }

.travel__gallery {
  position: relative; }
  .travel__gallery img {
    width: 100%; }

.travel__bg {
  overflow: hidden;
  border-radius: 32px; }
  .travel__bg img {
    transition: all 1s cubic-bezier(0.7, 0, 0.3, 1); }

.travel__preview {
  position: absolute;
  width: 70.5%;
  filter: drop-shadow(0px 40px 64px rgba(15, 15, 15, 0.1));
  transform: scale(0.8);
  opacity: 0;
  transition: all 1s cubic-bezier(0.7, 0, 0.3, 1); }
  @media only screen and (max-width: 1023px) {
    .travel__preview {
      width: 50%; } }
  @media only screen and (max-width: 767px) {
    .travel__preview {
      width: 60%; } }
  .travel__preview:first-child {
    top: 40%;
    left: -24%;
    transition-delay: .3s; }
    @media only screen and (max-width: 1179px) {
      .travel__preview:first-child {
        left: -8%; } }
  .travel__preview:nth-child(2) {
    top: 48%;
    right: -25%;
    transition-delay: .6s; }
    @media only screen and (max-width: 1023px) {
      .travel__preview:nth-child(2) {
        right: -15%; } }
  .travel__preview:nth-child(3) {
    left: -40%;
    bottom: 17%;
    transition-delay: .9s; }
    @media only screen and (max-width: 1179px) {
      .travel__preview:nth-child(3) {
        left: -22%; } }
    @media only screen and (max-width: 1023px) {
      .travel__preview:nth-child(3) {
        left: -10%; } }
    @media only screen and (max-width: 767px) {
      .travel__preview:nth-child(3) {
        left: 5%; } }

.travel .slick-list {
  overflow: visible; }

.travel .slick-slide {
  opacity: 0;
  visibility: hidden;
  transition: all .4s; }
  .travel .slick-slide.slick-active {
    opacity: 1;
    visibility: visible; }
    .travel .slick-slide.slick-active .travel__preview {
      opacity: 1; }

.travel .slick-arrow {
  top: 24px; }
  .travel .slick-arrow path {
    fill: #FCFCFD; }
  body.dark .travel .slick-arrow:hover {
    box-shadow: inset 0 0 0 2px #E6E8EC; }

.travel .slick-prev {
  right: 72px; }

.travel .slick-next {
  right: 24px; }

.work__head {
  margin-bottom: 64px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .work__head {
      margin-bottom: 32px; } }

.work__body {
  position: relative;
  max-width: 1024px;
  margin: 0 auto; }

.work__line {
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translateX(-50%); }
  @media only screen and (max-width: 1023px) {
    .work__line {
      top: 24px; } }
  @media only screen and (max-width: 767px) {
    .work__line {
      display: none; } }
  body.dark .work__line path {
    stroke: #353945 !important; }

.work__list {
  position: relative;
  z-index: 3;
  display: flex; }
  @media only screen and (max-width: 767px) {
    .work__list {
      display: block;
      max-width: 256px;
      margin: 0 auto; } }

.work__item {
  flex: 0 0 256px;
  text-align: center; }
  @media only screen and (max-width: 1023px) {
    .work__item {
      flex: 0 0 200px; } }
  .work__item:not(:last-child) {
    margin-right: auto; }
    @media only screen and (max-width: 767px) {
      .work__item:not(:last-child) {
        margin: 0 0 64px; } }

.work__preview img {
  width: 100%; }

.work__subtitle {
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em; }

.work__content {
  color: #777E90; }

.live__head {
  margin-bottom: 64px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .live__head {
      margin-bottom: 48px; } }

@media only screen and (max-width: 767px) {
  .live__wrapper {
    margin: 0 -4px; } }

.live__slider {
  display: flex;
  flex-wrap: wrap;
  margin: -48px -16px 0; }
  @media only screen and (max-width: 767px) {
    .live__slider {
      display: block;
      visibility: hidden;
      margin: 0;
      padding-bottom: 64px; }
      .live__slider.slick-initialized {
        visibility: visible; } }

.live__item {
  flex: 0 0 calc(33.333% - 32px);
  width: calc(33.333% - 32px);
  margin: 48px 16px 0;
  text-align: center;
  color: #23262F;
  transition: color .2s; }
  @media only screen and (max-width: 1023px) {
    .live__item {
      flex: 0 0 calc(50% - 32px);
      width: calc(50% - 32px); } }
  @media only screen and (max-width: 767px) {
    .live__item {
      width: auto;
      margin: 0 4px; } }
  .live__item:hover {
    color: #3B71FE; }
    .live__item:hover .live__preview img {
      transform: scale(1.05); }
  body.dark .live__item {
    color: #FCFCFD; }
    body.dark .live__item:hover {
      color: #3B71FE; }
  .live__item:nth-child(n+4) {
    display: none; }
    @media only screen and (max-width: 1023px) {
      .live__item:nth-child(n+4) {
        display: block; } }
  @media only screen and (max-width: 1023px) {
    .live__item:nth-child(n+5) {
      display: none; } }
  @media only screen and (max-width: 767px) {
    .live__item:nth-child(n+5) {
      display: block; } }

.live__preview {
  margin-bottom: 24px;
  overflow: hidden;
  border-radius: 16px; }
  .live__preview img {
    width: 100%;
    transition: transform 1s; }

.live__subtitle {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.live__content {
  color: #777E90; }

.live__btns {
  margin-top: 64px;
  text-align: center; }
  @media only screen and (max-width: 1023px) {
    .live__btns {
      margin-top: 40px; } }
  @media only screen and (max-width: 767px) {
    .live__btns {
      display: none; } }

.live__button .loader {
  margin: 0 16px 0 5px;
  transform: scale(0.8); }

.live .slick-list {
  overflow: visible; }

@media only screen and (max-width: 767px) {
  .live .slick-slide {
    margin: 0 4px; } }

.live .slick-arrow {
  bottom: 0; }

.live .slick-prev {
  left: calc(50% - 44px); }

.live .slick-next {
  right: calc(50% - 44px); }

.loader {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  font-size: 4px;
  animation: loader 1.1s infinite ease;
  text-indent: -9999em;
  transform: translateZ(0); }
  body.dark .loader {
    animation-name: loader-white; }
  .loader_white {
    animation-name: loader-white; }

@keyframes loader {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #777e90, 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2), 2.5em 0em 0 0em rgba(119, 126, 144, 0.2), 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2), 0em 2.5em 0 0em rgba(119, 126, 144, 0.2), -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.5), -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.7), 1.8em -1.8em 0 0em #777e90, 2.5em 0em 0 0em rgba(119, 126, 144, 0.2), 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2), 0em 2.5em 0 0em rgba(119, 126, 144, 0.2), -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.2), -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.5), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.7), 2.5em 0em 0 0em #777e90, 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2), 0em 2.5em 0 0em rgba(119, 126, 144, 0.2), -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.2), -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.5), 2.5em 0em 0 0em rgba(119, 126, 144, 0.7), 1.75em 1.75em 0 0em #777e90, 0em 2.5em 0 0em rgba(119, 126, 144, 0.2), -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.2), -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2), 2.5em 0em 0 0em rgba(119, 126, 144, 0.5), 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.7), 0em 2.5em 0 0em #777e90, -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.2), -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2), 2.5em 0em 0 0em rgba(119, 126, 144, 0.2), 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.5), 0em 2.5em 0 0em rgba(119, 126, 144, 0.7), -1.8em 1.8em 0 0em #777e90, -2.6em 0em 0 0em rgba(119, 126, 144, 0.2), -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2), 2.5em 0em 0 0em rgba(119, 126, 144, 0.2), 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2), 0em 2.5em 0 0em rgba(119, 126, 144, 0.5), -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.7), -2.6em 0em 0 0em #777e90, -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2), 2.5em 0em 0 0em rgba(119, 126, 144, 0.2), 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2), 0em 2.5em 0 0em rgba(119, 126, 144, 0.2), -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.5), -2.6em 0em 0 0em rgba(119, 126, 144, 0.7), -1.8em -1.8em 0 0em #777e90; } }

@keyframes loader-white {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff; } }

.nav {
  display: flex; }
  .nav__link {
    display: flex;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90;
    transition: all .2s; }
    .nav__link .icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      fill: #777E90;
      transition: fill .2s; }
    .nav__link:hover {
      color: #353945; }
      .nav__link:hover .icon {
        fill: #353945; }
      body.dark .nav__link:hover {
        color: #E6E8EC; }
        body.dark .nav__link:hover .icon {
          fill: #E6E8EC; }
    .nav__link.active {
      background: #353945;
      color: #FCFCFD; }
      .nav__link.active .icon {
        fill: #FCFCFD; }
      body.dark .nav__link.active {
        background: #E6E8EC;
        color: #23262F; }
        body.dark .nav__link.active .icon {
          fill: #23262F; }
    .nav__link:not(:last-child) {
      margin-right: 8px; }

.travels__center {
  max-width: 1440px; }
  @media only screen and (max-width: 1419px) {
    .travels__center {
      padding: 0 40px; } }
  @media only screen and (max-width: 1023px) {
    .travels__center {
      padding: 0; } }

.travels__wrapper {
  padding: 80px;
  border-radius: 24px;
  background: #F4F5F6; }
  @media only screen and (max-width: 1419px) {
    .travels__wrapper {
      padding: 64px 40px; } }
  @media only screen and (max-width: 1023px) {
    .travels__wrapper {
      border-radius: 0; } }
  @media only screen and (max-width: 767px) {
    .travels__wrapper {
      padding: 64px 32px; } }
  body.dark .travels__wrapper {
    background: #141416;
    box-shadow: inset 0 0 0 2px #23262F; }

.travels__title {
  margin-bottom: 12px; }

.travels__info {
  margin-bottom: 64px; }
  @media only screen and (max-width: 1179px) {
    .travels__info {
      margin-bottom: 48px; } }

.travels__sorting {
  display: flex;
  align-items: center;
  margin-bottom: 48px; }
  @media only screen and (max-width: 1023px) {
    .travels__sorting {
      justify-content: space-between; } }
  @media only screen and (max-width: 767px) {
    .travels__sorting {
      display: block; } }

.travels .nav {
  margin-right: auto; }
  @media only screen and (max-width: 1023px) {
    .travels .nav {
      display: none; } }

.travels .nav__link:not(:last-child) {
  margin-right: 16px; }

.travels__select {
  flex-shrink: 0;
  width: 256px; }
  @media only screen and (max-width: 767px) {
    .travels__select {
      width: 100%; }
      .travels__select:not(:last-child) {
        margin-bottom: 16px; } }

.travels__list {
  display: flex;
  flex-wrap: wrap;
  margin: -32px -16px 0; }
  @media only screen and (max-width: 1339px) {
    .travels__list {
      margin: -32px -10px 0; } }
  @media only screen and (max-width: 1179px) {
    .travels__list {
      margin: -32px -16px 0; } }
  @media only screen and (max-width: 767px) {
    .travels__list {
      display: block;
      margin: 0; } }

.travels__card {
  flex: 0 0 calc(25% - 32px);
  width: calc(25% - 32px);
  margin: 32px 16px 0;
  border-radius: 16px;
  overflow: hidden;
  background: #FCFCFD;
  color: #23262F; }
  @media only screen and (max-width: 1339px) {
    .travels__card {
      flex: 0 0 calc(25% - 20px);
      width: calc(25% - 20px);
      margin: 32px 10px 0; } }
  @media only screen and (max-width: 1179px) {
    .travels__card {
      flex: 0 0 calc(33.333% - 32px);
      width: calc(33.333% - 32px);
      margin: 32px 16px 0; } }
  @media only screen and (max-width: 1023px) {
    .travels__card {
      flex: 0 0 calc(50% - 32px);
      width: calc(50% - 32px); } }
  @media only screen and (max-width: 767px) {
    .travels__card {
      display: block;
      width: 100%;
      margin: 32px 0 0; } }
  body.dark .travels__card {
    background: #23262F;
    color: #FCFCFD; }
  .travels__card:hover .travels__subtitle {
    color: #3B71FE; }
    body.dark .travels__card:hover .travels__subtitle {
      color: #3B71FE; }
  .travels__card:hover .travels__preview img {
    transform: scale(1.1); }
  @media only screen and (max-width: 1179px) {
    .travels__card:nth-child(n+7) {
      display: none; } }

.travels__preview {
  position: relative;
  overflow: hidden; }
  .travels__preview:before {
    content: "";
    display: block;
    padding-bottom: 89%; }
  .travels__preview img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s; }

.travels__body {
  padding: 20px; }

.travels__line {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  .travels__line:not(:last-child) {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #E6E8EC; }
    body.dark .travels__line:not(:last-child) {
      border-color: #353945; }

.travels__subtitle {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  transition: color .2s; }

.travels__location {
  font-size: 12px;
  line-height: 1.66667;
  color: #353945; }
  body.dark .travels__location {
    color: #E6E8EC; }

.travels__price {
  margin-left: 16px;
  padding: 6px 8px;
  box-shadow: inset 0 0 0 2px #58C27D;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #58C27D; }

.travels__old {
  margin-bottom: 6px;
  text-decoration: line-through;
  color: #B1B5C3; }

.travels__date {
  margin-right: 20px;
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.travels__rating {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600; }
  .travels__rating .icon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    fill: #FFD166; }

.travels__btns {
  margin-top: 64px;
  text-align: center; }
  @media only screen and (max-width: 1023px) {
    .travels__btns {
      margin-top: 48px; } }

.planning {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 750px;
  padding: 50px 0 150px; }
  @media only screen and (max-width: 1179px) {
    .planning {
      min-height: 700px; } }
  @media only screen and (max-width: 1023px) {
    .planning {
      min-height: 600px;
      padding: 50px 0 100px; } }
  @media only screen and (max-width: 767px) {
    .planning {
      min-height: auto;
      padding: 0; } }
  .planning__wrap {
    max-width: 440px; }
    @media only screen and (max-width: 767px) {
      .planning__wrap {
        max-width: 100%;
        margin-bottom: 48px; } }
  .planning__title {
    margin-bottom: 32px; }
  .planning__info {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1.5;
    color: #777E90; }
  .planning .subscription {
    max-width: 300px; }
  .planning__gallery {
    position: absolute;
    top: 0;
    right: calc(50% - 600px);
    width: 643px;
    z-index: -1; }
    @media only screen and (max-width: 1179px) {
      .planning__gallery {
        width: 600px; } }
    @media only screen and (max-width: 1023px) {
      .planning__gallery {
        width: 500px; } }
    @media only screen and (max-width: 767px) {
      .planning__gallery {
        position: relative;
        top: auto;
        right: auto;
        width: 100%; } }
  .planning__preview:nth-child(2) {
    left: 1.5%;
    bottom: 16.5%;
    width: 39.7%;
    filter: drop-shadow(0px 40px 64px rgba(15, 15, 15, 0.1)); }
  .planning__preview:nth-child(3) {
    right: 10%;
    bottom: 27.5%;
    width: 30.8%;
    filter: drop-shadow(0px 40px 32px rgba(15, 15, 15, 0.12)); }
  .planning__preview:not(:first-child) {
    position: absolute; }
  .planning__preview img {
    width: 100%; }

.subscription {
  position: relative; }
  .subscription__input {
    width: 100%;
    height: 48px;
    padding: 0 48px 0 14px;
    border-radius: 24px;
    background: none;
    border: 2px solid #E6E8EC;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 1.71429;
    color: #23262F;
    transition: border-color .2s; }
    body.dark .subscription__input {
      border-color: #353945;
      color: #FCFCFD; }
    .subscription__input:focus {
      border-color: #777E90; }
      body.dark .subscription__input:focus {
        border-color: #777E90; }
    .subscription__input::placeholder {
      color: #777E90; }
  .subscription__btn {
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #3B71FE;
    transition: background .2s; }
    .subscription__btn .icon {
      width: 14px;
      height: 14px;
      fill: #FCFCFD; }
    .subscription__btn:hover {
      background: #084cfe; }
  .subscription_big .subscription__input {
    height: 72px;
    padding: 0 72px 0 22px;
    border-radius: 36px;
    border: 2px solid #F4F5F6;
    font-size: 16px; }
    .subscription_big .subscription__input::placeholder {
      color: #B1B5C3; }
    .subscription_big .subscription__input:focus {
      border-color: #B1B5C3; }
    body.dark .subscription_big .subscription__input {
      border-color: #353945; }
      body.dark .subscription_big .subscription__input:focus {
        border-color: #B1B5C3; }
  .subscription_big .subscription__btn {
    top: 16px;
    right: 16px;
    bottom: 16px;
    width: 40px;
    height: 40px; }
    .subscription_big .subscription__btn .icon {
      width: 20px;
      height: 20px; }

.browse__inner {
  position: relative; }

.browse__head {
  margin-bottom: 80px;
  padding-right: 120px; }
  @media only screen and (max-width: 1179px) {
    .browse__head {
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .browse__head {
      margin-bottom: 40px;
      padding: 0; } }
  .browse__head_small {
    margin-bottom: 48px; }
    @media only screen and (max-width: 767px) {
      .browse__head_small {
        margin-bottom: 40px; } }

.browse__info {
  margin-top: 12px; }

.browse__wrapper {
  margin: 0 -16px; }
  @media only screen and (max-width: 767px) {
    .browse__wrapper {
      margin: 0 -8px; } }

.browse__card {
  display: block;
  border-radius: 24px;
  color: #23262F;
  transition: color .2s; }
  .browse__card:hover {
    color: #3B71FE; }
    .browse__card:hover .browse__preview img {
      transform: scale(1.1); }
  body.dark .browse__card {
    color: #FCFCFD; }
    body.dark .browse__card:hover {
      color: #3B71FE; }

.browse__preview {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 24px; }
  .browse__preview:before {
    content: "";
    display: block;
    padding-bottom: 100%; }
  .browse__preview img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 1s; }

.browse__category {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 2; }

.browse__subtitle {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.browse__content {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777E90; }
  .browse__content .icon {
    width: 16px;
    height: 16px;
    margin: -2px 6px 0 0;
    fill: #777E90; }

.browse__slider {
  position: static;
  visibility: hidden; }
  @media only screen and (max-width: 767px) {
    .browse__slider {
      padding-bottom: 80px; } }
  .browse__slider.slick-initialized {
    visibility: visible; }

.browse .slick-list {
  overflow: visible; }
  @media only screen and (max-width: 1179px) {
    .browse .slick-list {
      margin: 0 -180px 0 0; } }
  @media only screen and (max-width: 767px) {
    .browse .slick-list {
      margin-right: -260px; } }

.browse .slick-slide {
  margin: 0 16px;
  opacity: 0;
  visibility: hidden;
  transition: all .4s; }
  @media only screen and (max-width: 767px) {
    .browse .slick-slide {
      margin: 0 8px; } }
  .browse .slick-slide.slick-active {
    opacity: 1;
    visibility: visible; }

.browse .slick-arrow {
  top: 4px; }
  @media only screen and (max-width: 767px) {
    .browse .slick-arrow {
      top: auto;
      bottom: 0; } }

.browse .slick-prev {
  right: 48px; }
  @media only screen and (max-width: 767px) {
    .browse .slick-prev {
      right: auto;
      left: 0; } }

.browse .slick-next {
  right: 0; }
  @media only screen and (max-width: 767px) {
    .browse .slick-next {
      right: auto;
      left: 48px; } }

.places__center {
  max-width: 1440px; }
  @media only screen and (max-width: 1419px) {
    .places__center {
      padding: 0 40px; } }
  @media only screen and (max-width: 1023px) {
    .places__center {
      padding: 0; } }

.places__inner {
  padding: 112px 80px;
  background: #F4F5F6;
  border-radius: 24px; }
  @media only screen and (max-width: 1419px) {
    .places__inner {
      padding: 80px 64px; } }
  @media only screen and (max-width: 1339px) {
    .places__inner {
      padding: 64px 40px; } }
  @media only screen and (max-width: 1023px) {
    .places__inner {
      border-radius: 0; } }
  @media only screen and (max-width: 767px) {
    .places__inner {
      padding: 64px 24px; } }
  body.dark .places__inner {
    background: #18191D;
    box-shadow: inset 0 0 0 1px #353945; }

.places__head {
  margin-bottom: 80px;
  text-align: center; }
  @media only screen and (max-width: 1179px) {
    .places__head {
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .places__head {
      margin-bottom: 40px; } }

.places__title {
  margin-bottom: 12px; }

.places__slider {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -64px -10px 0; }
  @media only screen and (max-width: 1339px) {
    .places__slider {
      margin: -48px -16px 0; } }
  @media only screen and (max-width: 767px) {
    .places__slider {
      display: block;
      margin: 0;
      padding-bottom: 80px;
      visibility: hidden; }
      .places__slider.slick-initialized {
        visibility: visible; } }

.places__item {
  flex: 0 0 calc(20% - 48px);
  width: calc(20% - 48px);
  margin: 64px 24px 0;
  padding: 8px 8px 24px;
  border-radius: 16px;
  background: #FCFCFD;
  color: #23262F;
  transition: all .2s; }
  @media only screen and (max-width: 1339px) {
    .places__item {
      flex: 0 0 calc(20% - 32px);
      width: calc(20% - 32px);
      margin: 48px 16px 0; } }
  @media only screen and (max-width: 1179px) {
    .places__item {
      flex: 0 0 calc(25% - 32px);
      width: calc(25% - 32px); } }
  @media only screen and (max-width: 1023px) {
    .places__item {
      flex: 0 0 calc(33.333% - 32px);
      width: calc(33.333% - 32px); } }
  @media only screen and (max-width: 767px) {
    .places__item {
      display: block;
      width: 100%;
      margin: 0; } }
  .places__item:hover {
    box-shadow: inset 0 0 0 1px #B1B5C3; }
  body.dark .places__item {
    background: #23262F; }
    body.dark .places__item:hover {
      box-shadow: inset 0 0 0 1px #353945; }
  @media only screen and (max-width: 1179px) {
    .places__item:nth-child(n+8) {
      display: none; } }
  @media only screen and (max-width: 1023px) {
    .places__item:nth-child(n+8) {
      display: block; } }

.places__preview {
  position: relative;
  width: 80px;
  margin: 0 auto 16px;
  border-radius: 50%;
  overflow: hidden; }
  .places__preview:before {
    content: '';
    display: block;
    padding-bottom: 100%; }
  .places__preview img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.places__number {
  margin-bottom: 16px; }

.places__subtitle {
  margin-bottom: 4px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }
  body.dark .places__subtitle {
    color: #FCFCFD; }

.places__time {
  text-align: center;
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.places .slick-list {
  overflow: visible; }
  @media only screen and (max-width: 767px) {
    .places .slick-list {
      margin: 0 -144px 0 0; } }

.places .slick-slide {
  margin: 0 8px;
  opacity: 0;
  visibility: hidden;
  transition: all .4s; }
  .places .slick-slide.slick-active {
    opacity: 1;
    visibility: visible; }

.places .slick-arrow {
  bottom: 0; }

.places .slick-prev {
  left: calc(50% - 44px); }

.places .slick-next {
  right: calc(50% - 44px); }

.hosts__inner {
  position: relative; }

.hosts__head {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  padding-right: 120px; }
  @media only screen and (max-width: 1023px) {
    .hosts__head {
      flex-direction: column;
      align-items: flex-start; } }
  @media only screen and (max-width: 767px) {
    .hosts__head {
      margin-bottom: 32px;
      padding: 0; } }

.hosts__title {
  margin-right: 12px; }
  @media only screen and (max-width: 767px) {
    .hosts__title {
      font-size: 32px; } }

.hosts .select-empty {
  width: auto; }
  @media only screen and (max-width: 1023px) {
    .hosts .select-empty {
      margin-left: -24px; } }

.hosts__wrapper {
  margin: 0 -16px; }
  @media only screen and (max-width: 474px) {
    .hosts__wrapper {
      margin: 0 -8px; } }

.hosts__slider {
  position: static;
  visibility: hidden; }
  @media only screen and (max-width: 767px) {
    .hosts__slider {
      position: relative;
      padding-bottom: 80px; } }
  .hosts__slider.slick-initialized {
    visibility: visible; }

.hosts__item {
  display: block;
  overflow: hidden;
  border-radius: 24px;
  border: 1px solid #E6E8EC;
  text-align: center;
  color: #23262F;
  transition: border-color .2s; }
  body.dark .hosts__item {
    background: #23262F;
    border-color: #353945;
    color: #FCFCFD; }
  .hosts__item:hover {
    border-color: #B1B5C3; }
    body.dark .hosts__item:hover {
      border-color: #777E90; }
    .hosts__item:hover .hosts__preview img {
      transform: scale(1.1); }

.hosts__preview {
  position: relative;
  overflow: hidden; }
  .hosts__preview:before {
    content: "";
    display: block;
    padding-bottom: 77%; }
  .hosts__preview img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 1s; }

.hosts__rating {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  height: 24px;
  background: #FCFCFD;
  border-radius: 12px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600; }
  body.dark .hosts__rating {
    background: #23262F;
    color: #FCFCFD; }
  .hosts__rating .icon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    fill: #FFD166; }

.hosts__body {
  padding: 0 16px 24px; }

.hosts__avatar {
  position: relative;
  z-index: 3;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 0 2px #FCFCFD;
  margin: -40px auto 16px; }
  body.dark .hosts__avatar {
    box-shadow: 0 0 0 2px #23262F; }
  .hosts__avatar img {
    width: 100%;
    height: 100%;
    transform: scale(1.02);
    border-radius: 50%; }

.hosts__subtitle {
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.hosts__location {
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.hosts .slick-list {
  overflow: visible; }
  @media only screen and (max-width: 1023px) {
    .hosts .slick-list {
      margin: 0 -160px 0 0; } }
  @media only screen and (max-width: 767px) {
    .hosts .slick-list {
      margin-right: -230px; } }

.hosts .slick-slide {
  margin: 0 16px;
  opacity: 0;
  visibility: hidden;
  transition: all .4s; }
  @media only screen and (max-width: 767px) {
    .hosts .slick-slide {
      margin: 0 8px; } }
  .hosts .slick-slide.slick-active {
    opacity: 1;
    visibility: visible; }

.hosts .slick-arrow {
  top: 12px; }
  @media only screen and (max-width: 767px) {
    .hosts .slick-arrow {
      top: auto;
      bottom: 0; } }

.hosts .slick-prev {
  right: 48px; }
  @media only screen and (max-width: 767px) {
    .hosts .slick-prev {
      right: auto;
      left: 8px; } }

.hosts .slick-next {
  right: 0; }
  @media only screen and (max-width: 767px) {
    .hosts .slick-next {
      right: auto;
      left: 56px; } }

.view__head {
  display: flex;
  align-items: flex-end;
  margin-bottom: 80px; }
  @media only screen and (max-width: 1179px) {
    .view__head {
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .view__head {
      display: block;
      margin-bottom: 32px; } }

.view__details {
  max-width: 540px;
  margin-right: auto; }

@media only screen and (max-width: 767px) {
  .view__button {
    width: 100%;
    margin-top: 32px; } }

.view__preview {
  display: block;
  position: relative;
  margin: 0 -80px;
  border-radius: 16px;
  overflow: hidden; }
  @media only screen and (max-width: 1339px) {
    .view__preview {
      margin: 0 -40px; } }
  @media only screen and (max-width: 1023px) {
    .view__preview {
      border-radius: 0; } }
  @media only screen and (max-width: 767px) {
    .view__preview {
      margin: 0 -32px; }
      .view__preview:before {
        content: "";
        display: block;
        padding-bottom: 65%; } }
  .view__preview img {
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .view__preview img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        object-fit: cover; } }

.categories__head {
  margin-bottom: 64px;
  text-align: center; }
  @media only screen and (max-width: 1179px) {
    .categories__head {
      margin-bottom: 48px; } }

.categories__title {
  margin-bottom: 12px; }

.categories__wrapper {
  margin: 0 -16px; }
  @media only screen and (max-width: 767px) {
    .categories__wrapper {
      margin: 0 -8px; } }

.categories__slider {
  visibility: hidden;
  padding-bottom: 106px; }
  @media only screen and (max-width: 1179px) {
    .categories__slider {
      padding-bottom: 88px; } }
  @media only screen and (max-width: 767px) {
    .categories__slider {
      padding-bottom: 80px; } }
  .categories__slider.slick-initialized {
    visibility: visible; }

.categories__item {
  display: block;
  padding: 32px;
  border-radius: 24px;
  border: 1px solid #F4F5F6;
  color: #23262F;
  transition: all .2s; }
  .categories__item:hover {
    border-color: #E6E8EC; }
  body.dark .categories__item {
    background: #23262F;
    border-color: #23262F;
    color: #FCFCFD; }
    body.dark .categories__item:hover {
      background: #141416; }

.categories__counter {
  margin-bottom: 74px; }

.categories__icon {
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 24px; }
  .categories__icon .icon {
    width: 32px;
    height: 32px;
    fill: #777E90; }
    body.dark .categories__icon .icon {
      fill: #FCFCFD; }

.categories__subtitle {
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.categories__content {
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.categories .slick-list {
  overflow: visible; }
  @media only screen and (max-width: 1023px) {
    .categories .slick-list {
      margin: 0 -150px 0 0; } }
  @media only screen and (max-width: 767px) {
    .categories .slick-list {
      margin-right: -220px; } }

.categories .slick-slide {
  margin: 0 16px; }
  @media only screen and (max-width: 767px) {
    .categories .slick-slide {
      margin: 0 8px; } }

.categories .slick-arrow {
  bottom: 0; }

.categories .slick-prev {
  left: calc(50% - 44px); }

.categories .slick-next {
  right: calc(50% - 44px); }

.theme {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0; }
  .theme__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
    .theme__input:checked + .theme__inner .theme__box {
      background: #3B71FE; }
      .theme__input:checked + .theme__inner .theme__box:before {
        transform: translate(24px, -50%);
        background: #FCFCFD; }
  .theme__inner {
    position: relative;
    display: inline-block;
    transition: all .2s; }
  .theme__box {
    position: relative;
    display: block;
    width: 48px;
    height: 24px;
    background: #E6E8EC;
    border-radius: 12px;
    transition: all .2s; }
    body.dark .theme__box {
      background: #353945; }
    .theme__box:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #3B71FE;
      transition: all .2s; }

.footer {
  border-top: 1px solid #E6E8EC; }
  body.dark .footer {
    border-color: #23262F; }
  .footer__row {
    display: flex;
    padding: 80px 0 48px; }
    @media only screen and (max-width: 767px) {
      .footer__row {
        display: block;
        padding-top: 64px; } }
  .footer__col:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 256px; }
    @media only screen and (max-width: 767px) {
      .footer__col:first-child {
        flex-direction: row;
        width: 100%;
        margin-bottom: 48px; } }
  .footer__col:nth-child(2) {
    flex-grow: 1;
    padding: 0 16px; }
    @media only screen and (max-width: 767px) {
      .footer__col:nth-child(2) {
        margin-bottom: 48px;
        padding: 0; } }
  .footer__col:nth-child(3) {
    width: 256px; }
    @media only screen and (max-width: 767px) {
      .footer__col:nth-child(3) {
        width: 100%; } }
  .footer__logo {
    margin: -8px 0 16px; }
    @media only screen and (max-width: 767px) {
      .footer__logo {
        margin-right: auto; } }
  .footer__theme {
    display: flex;
    align-items: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90; }
    .footer__theme .icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      fill: #777E90; }
    .footer__theme .theme {
      margin-left: 8px; }
  .footer__menu {
    display: flex;
    flex-wrap: wrap;
    margin-top: -32px; }
    @media only screen and (max-width: 767px) {
      .footer__menu {
        margin: -32px -16px 0; } }
  .footer__link {
    flex: 0 0 calc(33.333% - 32px);
    width: calc(33.333% - 32px);
    margin: 32px 16px 0;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90;
    transition: color .2s; }
    @media only screen and (max-width: 1023px) {
      .footer__link {
        flex: 0 0 calc(50% - 32px);
        width: calc(50% - 32px); } }
    .footer__link:hover {
      color: #23262F; }
      body.dark .footer__link:hover {
        color: #E6E8EC; }
    @media only screen and (max-width: 1023px) {
      .footer__link:nth-child(3) {
        order: 5; } }
  .footer__info {
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase; }
  .footer__bottom {
    padding: 24px 0;
    border-top: 1px solid #F4F5F6; }
    body.dark .footer__bottom {
      border-color: #23262F; }
  .footer__copyright {
    font-size: 12px;
    line-height: 1.66667; }
    body.dark .footer__copyright {
      color: #777E90; }

.counter {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 104px;
  height: 24px; }
  .counter__value {
    flex-grow: 1;
    padding: 0 12px;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    user-select: none; }
  .counter__button {
    position: relative;
    top: -1px;
    flex-shrink: 0;
    width: 24px; }
    .counter__button .icon {
      width: 24px;
      height: 24px;
      fill: #B1B5C3;
      transition: fill .2s; }
    .counter__button:hover .icon {
      fill: #3B71FE; }
    .counter__button.disabled {
      pointer-events: none; }
      .counter__button.disabled .icon {
        fill: #E6E8EC; }
        body.dark .counter__button.disabled .icon {
          fill: #353945; }

.location {
  position: relative;
  z-index: 3;
  height: 96px;
  overflow: hidden; }
  @media only screen and (max-width: 1023px) {
    .location {
      height: 64px; } }
  .location.active {
    overflow: visible;
    z-index: 5; }
    .location.active .location__head {
      width: 510px;
      box-shadow: 0px 32px 32px 0 rgba(15, 15, 15, 0.08);
      background: #FCFCFD; }
      @media only screen and (max-width: 1179px) {
        .location.active .location__head {
          width: 420px; } }
      @media only screen and (max-width: 1023px) {
        .location.active .location__head {
          width: 100%;
          box-shadow: none;
          background: none; } }
      body.dark .location.active .location__head {
        background: #141416; }
        @media only screen and (max-width: 1023px) {
          body.dark .location.active .location__head {
            background: none; } }
    .location.active .location__input {
      padding-right: 64px; }
      @media only screen and (max-width: 1023px) {
        .location.active .location__input {
          padding-right: 48px; } }
      @media only screen and (max-width: 767px) {
        .location.active .location__input {
          padding-right: 40px; } }
    .location.active .location__clear {
      visibility: visible;
      opacity: 1; }
    .location.active .location__body {
      visibility: visible;
      opacity: 1; }
  .location__head {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    user-select: none;
    transition: box-shadow .2s, background .2s; }
  .location__input {
    width: 100%;
    height: 100%;
    padding: 0 16px 24px 56px;
    border-radius: 16px;
    background: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600;
    letter-spacing: -.01em;
    color: #23262F;
    cursor: pointer; }
    @media only screen and (max-width: 1179px) {
      .location__input {
        padding-left: 48px;
        font-size: 18px; } }
    @media only screen and (max-width: 1023px) {
      .location__input {
        padding: 0 24px 16px 0; } }
    @media only screen and (max-width: 767px) {
      .location__input {
        padding: 0 0 16px 32px; } }
    .location__input::placeholder {
      color: #23262F; }
    body.dark .location__input {
      color: #FCFCFD; }
      body.dark .location__input::placeholder {
        color: #FCFCFD; }
  .location__icon {
    position: absolute;
    top: 20px;
    left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    pointer-events: none; }
    @media only screen and (max-width: 1179px) {
      .location__icon {
        left: 10px; } }
    @media only screen and (max-width: 1023px) {
      .location__icon {
        display: none; } }
    @media only screen and (max-width: 767px) {
      .location__icon {
        top: 8px;
        left: 0;
        display: flex;
        width: 24px; } }
    .location__icon .icon {
      width: 24px;
      height: 24px;
      fill: #B1B5C3; }
      @media only screen and (max-width: 1179px) {
        .location__icon .icon {
          width: 20px;
          height: 20px; } }
  .location__description {
    position: absolute;
    left: 56px;
    bottom: 20px;
    pointer-events: none;
    font-size: 16px;
    line-height: 1.5;
    color: #777E90; }
    @media only screen and (max-width: 1179px) {
      .location__description {
        left: 48px;
        font-size: 14px; } }
    @media only screen and (max-width: 1023px) {
      .location__description {
        bottom: 4px;
        left: 0; } }
    @media only screen and (max-width: 767px) {
      .location__description {
        left: 32px; } }
  .location__clear {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    opacity: 0;
    visibility: hidden; }
    @media only screen and (max-width: 1023px) {
      .location__clear {
        right: 8px;
        top: 8px; } }
    @media only screen and (max-width: 767px) {
      .location__clear {
        right: 0; } }
    .location__clear .icon {
      width: 24px;
      height: 24px;
      fill: #B1B5C3;
      transition: fill .2s; }
    .location__clear:hover .icon {
      fill: #3B71FE; }
  .location__body {
    position: absolute;
    left: 0;
    bottom: calc(100% + 16px);
    width: 510px;
    padding: 8px;
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
    border-radius: 24px;
    background: #FCFCFD;
    visibility: hidden;
    opacity: 0;
    transition: all .2s; }
    @media only screen and (max-width: 1179px) {
      .location__body {
        width: 420px; } }
    @media only screen and (max-width: 1023px) {
      .location__body {
        left: -16px;
        box-shadow: 0px 16px 32px rgba(15, 15, 15, 0.12); } }
    @media only screen and (max-width: 767px) {
      .location__body {
        bottom: 100%;
        right: -16px;
        width: auto;
        padding: 8px; } }
    body.dark .location__body {
      background: #141416; }
  .location__list {
    max-height: 280px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch; }
    .location__list::-webkit-scrollbar {
      display: none; }
  .location__item {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 56px;
    padding: 0 20px 0 48px;
    cursor: pointer;
    border-radius: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    color: #777E90;
    transition: all .2s; }
    @media only screen and (max-width: 1179px) {
      .location__item {
        min-height: 48px;
        font-size: 14px; } }
    @media only screen and (max-width: 767px) {
      .location__item {
        display: block;
        min-height: auto;
        padding: 8px; } }
    .location__item:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 6px;
      width: 32px;
      height: 32px;
      transform: translateY(-50%);
      border: 1px solid #E6E8EC;
      border-radius: 50%;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath d='M12.666 13.333c.368 0 .667-.298.667-.667S13.034 12 12.666 12s-.667.298-.667.667.299.667.667.667zm0 1.333a2 2 0 1 0 0-4 2 2 0 1 0 0 4zM10.333 2.667a1.67 1.67 0 0 0-1.667 1.667v7.333a3 3 0 1 1-6 0v-5c0-.368.298-.667.667-.667S4 6.298 4 6.667v5a1.67 1.67 0 0 0 1.667 1.667c.921 0 1.667-.746 1.667-1.667V4.333a3 3 0 1 1 6 0v4.333a.67.67 0 0 1-.667.667c-.368 0-.667-.298-.667-.667V4.333a1.67 1.67 0 0 0-1.667-1.667z' fill-rule='evenodd' fill='%23777e91'/%3E%3Cpath d='M2.759 1.654a.67.67 0 0 1 1.152 0l1.173 2.01a.67.67 0 0 1-.576 1.003H2.162a.67.67 0 0 1-.576-1.003l1.173-2.01z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50%/16px auto;
      transition: all .2s; }
      @media only screen and (max-width: 767px) {
        .location__item:before {
          display: none; } }
      body.dark .location__item:before {
        border-color: #353945;
        background-color: #23262F;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath d='M12.666 13.333c.368 0 .667-.298.667-.667S13.034 12 12.666 12s-.667.298-.667.667.299.667.667.667zm0 1.333a2 2 0 1 0 0-4 2 2 0 1 0 0 4zM10.333 2.667a1.67 1.67 0 0 0-1.667 1.667v7.333a3 3 0 1 1-6 0v-5c0-.368.298-.667.667-.667S4 6.298 4 6.667v5a1.67 1.67 0 0 0 1.667 1.667c.921 0 1.667-.746 1.667-1.667V4.333a3 3 0 1 1 6 0v4.333a.67.67 0 0 1-.667.667c-.368 0-.667-.298-.667-.667V4.333a1.67 1.67 0 0 0-1.667-1.667z' fill-rule='evenodd' fill='%23777e91'/%3E%3Cpath d='M2.759 1.654a.67.67 0 0 1 1.152 0l1.173 2.01a.67.67 0 0 1-.576 1.003H2.162a.67.67 0 0 1-.576-1.003l1.173-2.01z' fill='%23777E90'/%3E%3C/svg%3E"); }
    .location__item:hover {
      background: #F4F5F6;
      color: #23262F; }
      body.dark .location__item:hover {
        background: #23262F;
        color: #F4F5F6; }
        body.dark .location__item:hover:before {
          background-color: #353945; }
  .location_small {
    height: 72px; }
    @media only screen and (max-width: 1023px) {
      .location_small {
        height: 64px; } }
    .location_small .location__input {
      padding: 0 24px 0 64px; }
      @media only screen and (max-width: 1179px) {
        .location_small .location__input {
          padding: 0 24px 0 48px; } }
      @media only screen and (max-width: 1023px) {
        .location_small .location__input {
          padding: 0 24px 0 0; } }
      @media only screen and (max-width: 767px) {
        .location_small .location__input {
          padding: 0 0 0 32px; } }
    .location_small .location__clear {
      top: 50%;
      transform: translateY(-50%); }
    @media only screen and (max-width: 767px) {
      .location_small .location__icon {
        top: 50%;
        transform: translateY(-50%); } }
    .location_small.active .location__input {
      padding: 0 64px; }
      @media only screen and (max-width: 1179px) {
        .location_small.active .location__input {
          padding: 0 64px 0 48px; } }
      @media only screen and (max-width: 1023px) {
        .location_small.active .location__input {
          padding: 0 48px 0 0; } }
      @media only screen and (max-width: 767px) {
        .location_small.active .location__input {
          padding: 0 40px 0 32px; } }
  .location_down .location__body {
    top: calc(100% + 16px);
    bottom: auto; }
    @media only screen and (max-width: 767px) {
      .location_down .location__body {
        top: 100%;
        bottom: auto; } }

.travelers {
  position: relative; }
  .travelers.active {
    overflow: visible; }
    .travelers.active .travelers__head {
      box-shadow: 0px 32px 32px 0 rgba(15, 15, 15, 0.08); }
      @media only screen and (max-width: 1023px) {
        .travelers.active .travelers__head {
          box-shadow: none; } }
      body.dark .travelers.active .travelers__head {
        background: #141416; }
        @media only screen and (max-width: 1023px) {
          body.dark .travelers.active .travelers__head {
            background: none; } }
    .travelers.active .travelers__body {
      visibility: visible;
      opacity: 1; }
    .travelers.active .travelers__edit .icon {
      fill: #3B71FE; }
  .travelers__head {
    position: relative;
    border-radius: 16px;
    user-select: none;
    transition: all .2s; }
  .travelers__top {
    padding: 8px 56px 8px 16px;
    background: #F4F5F6;
    border-radius: 12px; }
    body.dark .travelers__top {
      background: #23262F; }
  .travelers__label {
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
  .travelers__value {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500; }
  .travelers__edit {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%); }
    .travelers__edit .icon {
      width: 24px;
      height: 24px;
      fill: #B1B5C3;
      transition: fill .2s; }
    .travelers__edit:hover .icon {
      fill: #777E90; }
  .travelers__content {
    display: flex;
    width: 100%;
    height: 96px;
    padding: 20px 116px 0 56px;
    border-radius: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600;
    letter-spacing: -.01em;
    color: #23262F;
    cursor: pointer; }
    @media only screen and (max-width: 1339px) {
      .travelers__content {
        padding-right: 96px; } }
    @media only screen and (max-width: 1179px) {
      .travelers__content {
        padding: 25px 96px 0 48px;
        font-size: 18px; } }
    @media only screen and (max-width: 1023px) {
      .travelers__content {
        height: 64px;
        padding: 12px 64px 0 0; } }
    @media only screen and (max-width: 767px) {
      .travelers__content {
        padding-left: 32px; } }
    body.dark .travelers__content {
      color: #FCFCFD; }
  .travelers__icon {
    position: absolute;
    top: 20px;
    left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    pointer-events: none; }
    @media only screen and (max-width: 1179px) {
      .travelers__icon {
        left: 10px; } }
    @media only screen and (max-width: 1023px) {
      .travelers__icon {
        display: none; } }
    @media only screen and (max-width: 767px) {
      .travelers__icon {
        top: 8px;
        left: 0;
        display: flex;
        width: 24px; } }
    .travelers__icon .icon {
      width: 24px;
      height: 24px;
      fill: #B1B5C3; }
      @media only screen and (max-width: 1179px) {
        .travelers__icon .icon {
          width: 20px;
          height: 20px; } }
  .travelers__description {
    position: absolute;
    left: 56px;
    bottom: 20px;
    pointer-events: none;
    font-size: 16px;
    line-height: 1.5;
    color: #777E90; }
    @media only screen and (max-width: 1179px) {
      .travelers__description {
        left: 48px;
        font-size: 14px; } }
    @media only screen and (max-width: 1023px) {
      .travelers__description {
        left: 0;
        bottom: 4px; } }
    @media only screen and (max-width: 767px) {
      .travelers__description {
        left: 32px; } }
  .travelers__body {
    position: absolute;
    right: 0;
    bottom: calc(100% + 16px);
    z-index: 10;
    width: 426px;
    padding: 16px 40px;
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
    border-radius: 24px;
    background: #FCFCFD;
    visibility: hidden;
    opacity: 0;
    transition: all .2s; }
    @media only screen and (max-width: 1179px) {
      .travelers__body {
        width: 350px;
        padding: 8px 24px; } }
    @media only screen and (max-width: 1023px) {
      .travelers__body {
        box-shadow: 0px 16px 32px rgba(15, 15, 15, 0.12); } }
    @media only screen and (max-width: 767px) {
      .travelers__body {
        bottom: 100%;
        left: -24px;
        right: -24px;
        width: auto; } }
    body.dark .travelers__body {
      background: #141416; }
  .travelers__item {
    display: flex;
    align-items: flex-start;
    padding: 24px 0 16px; }
    @media only screen and (max-width: 767px) {
      .travelers__item {
        padding: 16px 0 12px; } }
    .travelers__item:not(:last-child) {
      border-bottom: 1px solid #E6E8EC; }
      body.dark .travelers__item:not(:last-child) {
        border-color: #353945; }
  .travelers__details {
    flex-grow: 1;
    padding-right: 24px; }
  .travelers__category {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500; }
  .travelers__text {
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
  .travelers_small .travelers__content {
    height: 72px;
    padding: 0 116px 0 64px;
    line-height: 72px; }
    @media only screen and (max-width: 1179px) {
      .travelers_small .travelers__content {
        padding: 0 92px 0 48px; } }
    @media only screen and (max-width: 1023px) {
      .travelers_small .travelers__content {
        height: 64px;
        padding: 0 72px 0 0;
        line-height: 66px; } }
    @media only screen and (max-width: 767px) {
      .travelers_small .travelers__content {
        padding-left: 32px; } }
  @media only screen and (max-width: 767px) {
    .travelers_small .travelers__icon {
      top: 50%;
      transform: translateY(-50%); } }
  .travelers_down .travelers__body {
    top: calc(100% + 16px);
    bottom: auto; }
    @media only screen and (max-width: 767px) {
      .travelers_down .travelers__body {
        top: 100%;
        bottom: auto; } }

.control {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 16px 0; }
  .control__button {
    margin-right: auto; }
    .control__button .icon {
      width: 10px;
      height: 10px; }
    body.dark .control__button {
      box-shadow: inset 0 0 0 2px #353945; }
      body.dark .control__button:hover {
        background: #353945; }
  .control .breadcrumbs {
    margin-left: 16px; }

.breadcrumbs {
  display: flex;
  flex-wrap: wrap; }
  .breadcrumbs__item {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #B1B5C3; }
    .breadcrumbs__item:not(:last-child) {
      margin-right: 16px; }
      @media only screen and (max-width: 1023px) {
        .breadcrumbs__item:not(:last-child) {
          display: none; } }
      .breadcrumbs__item:not(:last-child):after {
        content: "";
        display: inline-block;
        width: 6px;
        height: 9px;
        margin-left: 16px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777E90'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto; }
  .breadcrumbs__link {
    color: #777E90;
    transition: color .2s; }
    .breadcrumbs__link:hover {
      color: #3B71FE; }

.sorting {
  margin-bottom: 80px; }
  @media only screen and (max-width: 1179px) {
    .sorting {
      margin-bottom: 48px; } }
  @media only screen and (max-width: 767px) {
    .sorting {
      margin-bottom: 32px; } }
  .sorting .control {
    margin-bottom: 48px;
    padding: 0; }
    @media only screen and (max-width: 767px) {
      .sorting .control {
        display: none; } }
  .sorting__body {
    display: flex;
    align-items: flex-start;
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #E6E8EC; }
    body.dark .sorting__body {
      border-color: #353945; }
  .sorting__box {
    margin-right: auto; }
  .sorting__line {
    display: flex;
    align-items: center; }
  @media only screen and (max-width: 1023px) {
    .sorting .nav {
      display: none; } }
  .sorting__location {
    position: relative;
    margin-left: 40px;
    flex-shrink: 0; }
    @media only screen and (max-width: 767px) {
      .sorting__location {
        display: none; } }
    .sorting__location .sorting__button .icon {
      width: 12px;
      height: 12px; }
    .sorting__location .sorting__button.active {
      background: #3B71FE;
      box-shadow: inset 0 0 0 2px #3B71FE;
      color: #FCFCFD; }
      .sorting__location .sorting__button.active .icon {
        transform: rotate(180deg);
        fill: #FCFCFD; }
    body.dark .sorting__location .sorting__button {
      box-shadow: inset 0 0 0 2px #353945; }
      body.dark .sorting__location .sorting__button:hover {
        background: #353945; }
      body.dark .sorting__location .sorting__button.active {
        background: #3B71FE;
        box-shadow: inset 0 0 0 2px #3B71FE; }
  .sorting__map {
    position: absolute;
    top: calc(100% + 16px);
    right: 0;
    z-index: 5;
    width: 640px;
    height: 480px;
    padding: 4px;
    background: #FCFCFD;
    border-radius: 24px 0 24px 24px;
    border: 1px solid #E6E8EC;
    box-shadow: 0px 40px 64px -16px rgba(15, 15, 15, 0.1);
    visibility: hidden;
    opacity: 0;
    transition: all .4s; }
    .sorting__map.show {
      visibility: visible;
      opacity: 1; }
    .sorting__map iframe {
      width: 100%;
      height: 100%;
      border-radius: 21px 0 21px 21px; }
  .sorting__title {
    margin-bottom: 12px; }
    @media only screen and (max-width: 767px) {
      .sorting__title {
        font-size: 32px; } }
  .sorting__sale {
    margin-right: 16px;
    padding: 8px 8px 6px;
    box-shadow: inset 0 0 0 2px #58C27D;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    color: #58C27D; }
  .sorting__details {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500; }
  .sorting__foot {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .sorting__foot {
        display: block; } }
  .sorting__select {
    flex-shrink: 0;
    width: 256px; }
    @media only screen and (max-width: 767px) {
      .sorting__select {
        width: 100%; } }
    @media only screen and (max-width: 767px) {
      .sorting__select:nth-child(2) {
        margin-bottom: 16px; } }
  .sorting_flights {
    margin-bottom: 64px; }
    @media only screen and (max-width: 767px) {
      .sorting_flights {
        margin-bottom: 32px; } }
  .sorting_flights .control {
    margin-bottom: 32px; }
  .sorting_flights .sorting__foot {
    padding-top: 32px;
    border-top: 1px solid #E6E8EC; }
    @media only screen and (max-width: 767px) {
      .sorting_flights .sorting__foot {
        display: block;
        padding-top: 0;
        border: none; } }
    body.dark .sorting_flights .sorting__foot {
      border-color: #353945; }

.card {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  overflow: hidden;
  background: #FCFCFD;
  border: 1px solid #E6E8EC;
  color: #141416;
  transition: border-color .2s; }
  .card:hover {
    border-color: #B1B5C3; }
    .card:hover .card__preview img {
      transform: scale(1.1); }
  body.dark .card {
    background: #141416;
    border-color: #353945;
    color: #FCFCFD; }
  .card__preview {
    position: relative;
    overflow: hidden; }
    .card__preview:before {
      content: "";
      display: block;
      padding-bottom: 68.6%; }
    .card__preview img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 1s; }
      @media only screen and (max-width: 767px) {
        .card__preview img {
          border-radius: 24px 24px 0 0; } }
  .card__category {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 2; }
  .card__body {
    flex-grow: 1;
    padding: 24px 22px; }
  .card__line {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px; }
  .card__title {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500; }
  .card__price {
    margin-left: 16px;
    padding: 6px 8px;
    box-shadow: inset 0 0 0 2px #58C27D;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    color: #58C27D; }
  .card__old {
    margin-bottom: 6px;
    text-decoration: line-through;
    color: #B1B5C3; }
  .card__date {
    margin-right: 20px;
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
  .card__options {
    display: flex;
    flex-wrap: wrap;
    margin: -8px 0 16px -12px; }
  .card__option {
    display: flex;
    margin: 8px 0 0 12px;
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
    .card__option .icon {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      margin: 1px 6px 0 0;
      fill: #777E90; }
  .card__foot {
    padding-top: 16px;
    border-top: 1px solid #E6E8EC; }
    body.dark .card__foot {
      border-color: #23262F; }
  .card__comment {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
  .card__avatar {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 12px; }
    .card__avatar img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover; }
  .card__flex {
    display: flex;
    justify-content: space-between; }
  .card__cost {
    font-size: 12px;
    line-height: 1.66667;
    font-weight: 600; }
  .card__rating {
    display: flex;
    align-items: center; }
    .card__rating .icon {
      width: 12px;
      height: 12px;
      margin-right: 4px;
      fill: #FFD166; }
  .card__number {
    margin-right: 4px;
    font-size: 12px;
    line-height: 1.66667;
    font-weight: 600; }
  .card__reviews {
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
  .card_row {
    flex-direction: row;
    border-radius: 16px; }
    @media only screen and (max-width: 1339px) {
      .card_row {
        display: block; } }
    .card_row .card__preview {
      flex-shrink: 0;
      width: 256px; }
      @media only screen and (max-width: 1339px) {
        .card_row .card__preview {
          width: 100%; } }
      .card_row .card__preview:before {
        display: none; }
        @media only screen and (max-width: 1339px) {
          .card_row .card__preview:before {
            display: block; } }
      @media only screen and (max-width: 767px) {
        .card_row .card__preview img {
          border-radius: 16px 16px 0 0; } }
    .card_row .card__body {
      display: flex;
      flex-direction: column;
      flex-grow: 1; }
    .card_row .card__line {
      margin-bottom: 16px; }
    .card_row .card__price {
      display: flex;
      flex-direction: column-reverse; }
    .card_row .card__old {
      margin: 6px 0 0;
      text-decoration: none; }
    .card_row .card__options {
      flex-wrap: nowrap; }
      @media only screen and (max-width: 1339px) {
        .card_row .card__options {
          flex-wrap: wrap; } }
    .card_row .card__option:nth-child(2) {
      flex-shrink: 0; }
    .card_row .card__foot {
      margin-top: auto; }
  .card_car .card__line {
    margin-bottom: 0; }
    @media only screen and (max-width: 1179px) {
      .card_car .card__line {
        margin-bottom: 8px; } }
  .card_car .card__price {
    display: flex;
    flex-direction: column-reverse; }
  .card_car .card__old {
    margin: 6px 0 0;
    text-decoration: none; }
  .card_car .card__option:nth-child(2) {
    flex-shrink: 0; }

.catalog__title {
  margin-bottom: 48px; }
  @media only screen and (max-width: 767px) {
    .catalog__title {
      margin-bottom: 32px; } }

.catalog__list {
  display: flex;
  flex-wrap: wrap;
  margin: -32px -16px 0; }
  @media only screen and (max-width: 767px) {
    .catalog__list {
      display: block;
      margin: 0; } }

.catalog__list .card {
  flex: 0 0 calc(33.333% - 32px);
  width: calc(33.333% - 32px);
  margin: 32px 16px 0; }
  @media only screen and (max-width: 1023px) {
    .catalog__list .card {
      flex: 0 0 calc(50% - 32px);
      width: calc(50% - 32px); } }
  @media only screen and (max-width: 767px) {
    .catalog__list .card {
      width: 100%;
      margin: 32px 0 0; } }

.catalog__cards {
  display: flex;
  flex-wrap: wrap;
  margin: -32px -16px 0; }
  @media only screen and (max-width: 767px) {
    .catalog__cards {
      display: block;
      margin: 0; } }

.catalog__cards .card {
  flex: 0 0 calc(50% - 32px);
  width: calc(50% - 32px);
  margin: 32px 16px 0; }
  @media only screen and (max-width: 1339px) {
    .catalog__cards .card {
      flex: 0 0 calc(33.333% - 32px);
      width: calc(33.333% - 32px); } }
  @media only screen and (max-width: 1023px) {
    .catalog__cards .card {
      flex: 0 0 calc(50% - 32px);
      width: calc(50% - 32px); } }
  @media only screen and (max-width: 767px) {
    .catalog__cards .card {
      width: 100%;
      margin: 32px 0 0; } }
  @media only screen and (max-width: 1339px) {
    .catalog__cards .card:nth-child(n+10) {
      display: none; } }
  @media only screen and (max-width: 1023px) {
    .catalog__cards .card:nth-child(n+10) {
      display: block; } }

.catalog__btns {
  margin-top: 48px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .catalog__btns {
      margin-top: 32px; } }

.catalog__btns .catalog__button .loader {
  margin: 0 16px 0 2px;
  transform: scale(0.8); }

.newsletter__center {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .newsletter__center {
      display: block; } }

.newsletter__wrap {
  max-width: 352px;
  margin-right: auto; }
  @media only screen and (max-width: 767px) {
    .newsletter__wrap {
      max-width: 100%;
      margin: 0 0 40px; } }

.newsletter__title {
  margin-bottom: 16px; }

.newsletter__info {
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 1.5;
  color: #777E90; }

.newsletter__list {
  margin-bottom: 40px; }

.newsletter__item {
  display: flex; }
  .newsletter__item:not(:last-child) {
    margin-bottom: 16px; }

.newsletter__number {
  flex-shrink: 0;
  width: 44px;
  margin-right: 16px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  font-weight: 500;
  color: #FCFCFD; }

.newsletter__text {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.newsletter__gallery {
  position: relative;
  width: 544px; }
  @media only screen and (max-width: 1179px) {
    .newsletter__gallery {
      width: 450px;
      margin-right: -50px; } }
  @media only screen and (max-width: 1023px) {
    .newsletter__gallery {
      width: 400px;
      margin-right: -150px; } }
  @media only screen and (max-width: 767px) {
    .newsletter__gallery {
      width: 100%;
      margin: 0; } }

.newsletter__preview:nth-child(2) {
  top: 25%;
  left: -17.5%;
  width: 53%; }

.newsletter__preview:nth-child(3) {
  right: 17%;
  bottom: 21.5%;
  width: 47%; }

.newsletter__preview:nth-child(4) {
  top: 12%;
  right: 19.5%;
  width: 14.7%; }

.newsletter__preview:not(:first-child) {
  position: absolute;
  z-index: 2;
  filter: drop-shadow(0px 20px 32px rgba(15, 15, 15, 0.1)); }

.newsletter__preview img {
  width: 100%; }

.best__center {
  max-width: 1440px; }
  @media only screen and (max-width: 1419px) {
    .best__center {
      padding: 0 40px; } }
  @media only screen and (max-width: 767px) {
    .best__center {
      padding: 0; } }

.best__inner {
  padding: 80px 80px 100px;
  background: #F4F5F6;
  border-radius: 24px; }
  @media only screen and (max-width: 1419px) {
    .best__inner {
      padding: 80px 40px; } }
  @media only screen and (max-width: 767px) {
    .best__inner {
      padding: 64px 24px;
      border-radius: 0; } }
  body.dark .best__inner {
    background: #18191D; }

.best__head {
  margin-bottom: 80px;
  text-align: center; }
  @media only screen and (max-width: 1179px) {
    .best__head {
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .best__head {
      margin-bottom: 32px; } }

.best__title {
  margin-bottom: 12px; }

.best__slider {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -48px -10px 0; }
  @media only screen and (max-width: 1339px) {
    .best__slider {
      margin: -32px -16px 0; } }
  @media only screen and (max-width: 767px) {
    .best__slider {
      display: block;
      margin: 0;
      padding-bottom: 80px;
      visibility: hidden; }
      .best__slider.slick-initialized {
        visibility: visible; } }

.best__item {
  flex: 0 0 calc(20% - 48px);
  width: calc(20% - 48px);
  margin: 48px 24px 0;
  display: block;
  overflow: hidden;
  padding: 8px 8px 24px;
  border-radius: 16px;
  background: #FCFCFD;
  text-align: center;
  color: #23262F;
  transition: box-shadow .2s; }
  @media only screen and (max-width: 1339px) {
    .best__item {
      flex: 0 0 calc(20% - 32px);
      width: calc(20% - 32px);
      margin: 32px 16px 0; } }
  @media only screen and (max-width: 1179px) {
    .best__item {
      flex: 0 0 calc(25% - 32px);
      width: calc(25% - 32px); } }
  @media only screen and (max-width: 1023px) {
    .best__item {
      flex: 0 0 calc(33.333% - 32px);
      width: calc(33.333% - 32px); } }
  @media only screen and (max-width: 767px) {
    .best__item {
      width: 100%;
      margin: 0; } }
  .best__item:hover {
    box-shadow: inset 0 0 0 1px #B1B5C3; }
  body.dark .best__item {
    background: #23262F;
    color: #FCFCFD; }
    body.dark .best__item:hover {
      box-shadow: inset 0 0 0 1px #777E90; }
  @media only screen and (max-width: 1179px) {
    .best__item:nth-child(n+9) {
      display: none; } }
  @media only screen and (max-width: 1023px) {
    .best__item:nth-child(n+9) {
      display: block; } }

.best__avatar {
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
  margin: 0 auto 16px; }
  .best__avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; }

.best__check {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #58C27D; }
  .best__check .icon {
    width: 12px;
    height: 12px;
    fill: #FCFCFD; }

.best__line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px; }

.best__number {
  min-width: 39px;
  padding: 8px 12px 6px;
  border-radius: 13px;
  text-align: center;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #FCFCFD; }

.best__rating {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600; }
  .best__rating .icon {
    position: relative;
    top: -1px;
    width: 12px;
    height: 12px;
    margin-right: 4px;
    fill: #FFD166; }

.best__subtitle {
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.best__location {
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.best .slick-list {
  overflow: visible; }
  @media only screen and (max-width: 767px) {
    .best .slick-list {
      margin: 0 -72px 0 0; } }

.best .slick-slide {
  margin: 0 8px;
  opacity: 0;
  visibility: hidden;
  transition: all .4s; }
  .best .slick-slide.slick-active {
    opacity: 1;
    visibility: visible; }

.best .slick-arrow {
  bottom: 0; }

.best .slick-prev {
  left: 8px; }

.best .slick-next {
  left: 56px; }

.testimonials__nav {
  display: flex;
  justify-content: center;
  margin: 80px -16px 0; }
  @media only screen and (max-width: 1179px) {
    .testimonials__nav {
      margin: 80px -8px 0; } }
  @media only screen and (max-width: 1023px) {
    .testimonials__nav {
      justify-content: flex-start;
      margin: 80px -40px 0;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch; }
      .testimonials__nav::-webkit-scrollbar {
        display: none; }
      .testimonials__nav:before, .testimonials__nav:after {
        content: "";
        flex-shrink: 0;
        width: 40px;
        height: 1px; } }
  @media only screen and (max-width: 767px) {
    .testimonials__nav {
      margin-top: 48px; } }

.testimonials__link {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 calc(25% - 32px);
  width: calc(25% - 32px);
  margin: 0 16px;
  padding-bottom: 26px;
  cursor: pointer;
  color: #23262F;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  @media only screen and (max-width: 1179px) {
    .testimonials__link {
      flex: 0 0 calc(25% - 16px);
      width: calc(25% - 16px);
      margin: 0 8px; } }
  @media only screen and (max-width: 1023px) {
    .testimonials__link {
      flex: 0 0 256px;
      width: 256px;
      margin: 0; } }
  body.dark .testimonials__link {
    color: #FCFCFD; }
  .testimonials__link:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    border-radius: 2px;
    background: #23262F;
    opacity: 0;
    transition: opacity .2s; }
    body.dark .testimonials__link:after {
      background: #FCFCFD; }
  .testimonials__link:hover:after, .testimonials__link.active:after {
    opacity: 1; }
  @media only screen and (max-width: 1023px) {
    .testimonials__link:not(:last-child) {
      margin-right: 32px; } }

.testimonials__avatar {
  flex-shrink: 48px;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  background: #E6E8EC;
  border-radius: 50%;
  overflow: hidden; }
  .testimonials__avatar img {
    width: 100%;
    height: 100%; }

.testimonials__man {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.71429;
  font-weight: 500; }

.testimonials__position {
  font-size: 14px;
  line-height: 1.71429;
  color: #777E90; }

.testimonials__item {
  display: none; }
  .testimonials__item:first-child {
    display: block; }

.testimonials__row {
  display: flex;
  margin: 0 -16px; }
  @media only screen and (max-width: 767px) {
    .testimonials__row {
      display: block;
      margin: 0; } }

.testimonials__col {
  flex: 0 0 calc(50% - 32px);
  width: calc(50% - 32px);
  margin: 0 16px; }
  @media only screen and (max-width: 767px) {
    .testimonials__col {
      width: 100%;
      margin: 0; }
      .testimonials__col:not(:last-child) {
        margin-bottom: 48px; } }

.testimonials__logo {
  margin-bottom: 48px; }
  @media only screen and (max-width: 1023px) {
    .testimonials__logo {
      margin-bottom: 24px; } }
  .testimonials__logo img {
    max-width: 166px; }

.testimonials__comment {
  max-width: 450px;
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.33333;
  letter-spacing: -.01em; }
  @media only screen and (max-width: 1023px) {
    .testimonials__comment {
      font-size: 20px; } }
  @media only screen and (max-width: 767px) {
    .testimonials__comment {
      font-size: 24px; } }

.testimonials__details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 1.71429; }

.testimonials__icon {
  margin-right: 12px; }

.testimonials__author {
  margin-right: 24px;
  font-weight: 500; }

.testimonials__description {
  color: #777E90; }
  @media only screen and (max-width: 1023px) {
    .testimonials__description {
      margin-left: 36px; } }

.testimonials__preview {
  position: relative; }
  .testimonials__preview img {
    width: 100%;
    border-radius: 16px; }
    @media only screen and (max-width: 767px) {
      .testimonials__preview img {
        border-radius: 12px; } }

.actions {
  position: relative; }
  .actions__list {
    display: flex; }
  .actions__item_share {
    position: relative; }
  .actions__item:not(:last-child) {
    margin-right: 16px; }
  .actions__button .icon {
    width: 24px;
    height: 24px; }
  .actions__item.active .actions__button {
    background: #23262F;
    box-shadow: 0 0 0 2px #23262F inset;
    color: #FCFCFD; }
    .actions__item.active .actions__button .icon {
      fill: #FCFCFD; }
    body.dark .actions__item.active .actions__button {
      background: #353945;
      box-shadow: 0 0 0 2px #353945 inset; }
  .actions__body {
    position: absolute;
    z-index: 5;
    border: 1px solid #E6E8EC;
    background: #FCFCFD;
    visibility: hidden;
    opacity: 0;
    transition: all .3s; }
  .actions__item.active .actions__body {
    visibility: visible;
    opacity: 1; }
  .actions__item_map .actions__body {
    top: calc(100% + 28px);
    right: 0;
    width: 640px;
    height: 480px;
    padding: 4px;
    border-radius: 24px; }
    @media only screen and (max-width: 767px) {
      .actions__item_map .actions__body {
        left: -20px;
        right: -20px;
        width: auto; } }
    .actions__item_map .actions__body iframe {
      width: 100%;
      height: 100%;
      border-radius: 21px; }
  .actions__item_share .actions__body {
    left: 50%;
    top: calc(100% + 8px);
    width: 220px;
    transform: translateX(-50%);
    padding: 32px 16px;
    box-shadow: 0px 32px 32px -8px rgba(31, 47, 70, 0.12);
    border-radius: 16px;
    text-align: center; }
    body.dark .actions__item_share .actions__body {
      background: #23262F;
      border-color: #353945; }
  .actions__item_share .actions__body_up {
    top: auto;
    bottom: calc(100% + 8px); }
  .actions__title {
    margin-bottom: 24px;
    font-weight: 500; }
  .actions__list {
    display: flex;
    justify-content: center; }
  .actions__link span {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #E6E8EC;
    cursor: pointer;
    transition: all .2s; }
    .actions__link span .icon {
      width: 20px;
      height: 20px;
      transition: fill .2s; }
    .actions__link span:hover {
      background: #3B71FE; }
      .actions__link span:hover .icon {
        fill: #FCFCFD; }
    body.dark .actions__link span {
      background: #353945; }
      body.dark .actions__link span .icon {
        fill: #FCFCFD; }
      body.dark .actions__link span:hover {
        background: #3B71FE; }
        body.dark .actions__link span:hover .icon {
          fill: #FCFCFD; }
  .actions__link:not(:last-child) {
    margin-right: 24px; }
  .actions__favorite {
    position: relative; }
    .actions__favorite .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 24px;
      transform: translate(-50%, -50%);
      transition: all .2s; }
      .actions__favorite .icon:nth-child(2) {
        z-index: 2;
        opacity: 0; }
    .actions__favorite:hover {
      box-shadow: inset 0 0 0 2px #23262F;
      background: #23262F; }
      body.dark .actions__favorite:hover {
        box-shadow: 0 0 0 2px #353945 inset; }
      .actions__favorite:hover .icon {
        fill: #FCFCFD; }
    .actions__favorite.active {
      box-shadow: inset 0 0 0 2px #23262F;
      background: #23262F;
      color: #23262F; }
      .actions__favorite.active .icon {
        fill: #FCFCFD; }
        .actions__favorite.active .icon:first-child {
          opacity: 0; }
        .actions__favorite.active .icon:nth-child(2) {
          opacity: 1; }
      body.dark .actions__favorite.active {
        background: #353945;
        box-shadow: 0 0 0 2px #353945 inset; }

@media only screen and (max-width: 767px) {
  .product {
    padding-top: 48px; } }

@media only screen and (max-width: 767px) {
  .product .control {
    display: none; } }

.product__head {
  display: flex;
  align-items: flex-start;
  margin-bottom: 64px; }
  @media only screen and (max-width: 767px) {
    .product__head {
      display: block;
      margin-bottom: 48px; } }

.product__box {
  flex-grow: 1;
  padding-right: 40px; }
  @media only screen and (max-width: 767px) {
    .product__box {
      margin-bottom: 32px;
      padding-right: 0; } }

.product__title {
  max-width: 640px;
  margin-bottom: 16px; }
  @media only screen and (max-width: 767px) {
    .product__title {
      font-size: 32px; } }

.product__line {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.product__avatar {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 20px; }
  .product__avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; }

.product__rating {
  display: flex;
  align-items: center;
  margin-right: 20px; }
  .product__rating .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: #FFD166; }

.product__number {
  font-weight: 500; }

.product__reviews {
  margin-left: 8px;
  color: #777E90; }

.product__options {
  display: flex;
  flex-wrap: wrap;
  margin: -4px 0 6px -20px; }
  @media only screen and (max-width: 1023px) {
    .product__options {
      flex: 0 0 100%;
      margin-top: 6px; } }

.product__option {
  display: flex;
  align-items: center;
  margin: 10px 0 0 20px;
  color: #777E90; }
  .product__option .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: #777E90; }

.product .actions {
  flex-shrink: 0; }

.product__grid {
  margin: -32px -16px 0; }
  @media only screen and (max-width: 767px) {
    .product__grid {
      margin: 0; } }
  .product__grid:after {
    content: " ";
    display: table;
    clear: both; }

.product__photo {
  float: left;
  width: calc(50% - 32px);
  margin: 32px 16px 0; }
  @media only screen and (max-width: 767px) {
    .product__photo {
      float: none;
      width: 100%;
      margin: 0; } }
  .product__photo img {
    max-width: 100%;
    border-radius: 16px; }
  .product__photo:first-child {
    float: left;
    width: calc(100% - 32px); }
    @media only screen and (max-width: 767px) {
      .product__photo:first-child {
        float: none;
        width: 100%; } }
  .product__photo:nth-child(2n+1) {
    float: left; }
    @media only screen and (max-width: 767px) {
      .product__photo:nth-child(2n+1) {
        float: none; } }
  .product__photo:nth-child(2n) {
    float: right; }
    @media only screen and (max-width: 767px) {
      .product__photo:nth-child(2n) {
        float: none; } }
  @media only screen and (max-width: 767px) {
    .product__photo:not(:last-child) {
      margin-bottom: 24px; } }

.product__foot {
  margin-top: 32px;
  text-align: center; }

.product__foot .product__button .icon {
  width: 24px;
  height: 24px; }

.product_photo {
  padding-top: 80px; }
  @media only screen and (max-width: 1023px) {
    .product_photo {
      padding-top: 48px; } }
  @media only screen and (max-width: 767px) {
    .product_photo {
      padding-top: 32px; } }

@media only screen and (max-width: 767px) {
  .gallery {
    margin: 0 -24px 0 -16px; } }

.gallery__list {
  display: grid;
  margin-right: 8px;
  grid-gap: 8px; }
  .gallery__list_stays {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr); }
    @media only screen and (max-width: 767px) {
      .gallery__list_stays {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr); } }
    .gallery__list_stays .gallery__preview:first-child {
      grid-column: 1 / 4;
      grid-row: 1 / 4; }
      @media only screen and (max-width: 767px) {
        .gallery__list_stays .gallery__preview:first-child {
          grid-column: 1 / 4;
          grid-row: 1 / 4; } }
    .gallery__list_stays .gallery__preview:nth-child(2) {
      grid-column: 4 / 5;
      grid-row: 1 / 2; }
      @media only screen and (max-width: 767px) {
        .gallery__list_stays .gallery__preview:nth-child(2) {
          grid-column: 1 / 2;
          grid-row: 4 / 5; } }
    .gallery__list_stays .gallery__preview:nth-child(3) {
      grid-column: 4 / 5;
      grid-row: 2 / 3; }
      @media only screen and (max-width: 767px) {
        .gallery__list_stays .gallery__preview:nth-child(3) {
          grid-column: 2 / 3;
          grid-row: 4 / 5; } }
    .gallery__list_stays .gallery__preview:nth-child(4) {
      grid-column: 4 / 5;
      grid-row: 3 / 4; }
      @media only screen and (max-width: 767px) {
        .gallery__list_stays .gallery__preview:nth-child(4) {
          grid-column: 3 / 4;
          grid-row: 4 / 5; } }
  .gallery__list_cars {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr); }
    @media only screen and (max-width: 767px) {
      .gallery__list_cars {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr); } }
    .gallery__list_cars .gallery__preview:first-child {
      grid-column: 1 / 3;
      grid-row: 1 / 3; }
      @media only screen and (max-width: 767px) {
        .gallery__list_cars .gallery__preview:first-child {
          grid-column: 1 / 3;
          grid-row: 1 / 2; } }
    .gallery__list_cars .gallery__preview:nth-child(2) {
      grid-column: 3 / 4;
      grid-row: 1 / 3; }
      @media only screen and (max-width: 767px) {
        .gallery__list_cars .gallery__preview:nth-child(2) {
          grid-column: 1 / 2;
          grid-row: 2 / 3; } }
    .gallery__list_cars .gallery__preview:nth-child(3) {
      grid-column: 1 / 2;
      grid-row: 3 / 4; }
      @media only screen and (max-width: 767px) {
        .gallery__list_cars .gallery__preview:nth-child(3) {
          grid-column: 2 / 3;
          grid-row: 2 / 3; } }
    .gallery__list_cars .gallery__preview:nth-child(4) {
      grid-column: 2 / 3;
      grid-row: 3 / 4; }
      @media only screen and (max-width: 767px) {
        .gallery__list_cars .gallery__preview:nth-child(4) {
          grid-column: 1 / 2;
          grid-row: 3 / 4; } }
    .gallery__list_cars .gallery__preview:nth-child(5) {
      grid-column: 3 / 4;
      grid-row: 3 / 4; }
      @media only screen and (max-width: 767px) {
        .gallery__list_cars .gallery__preview:nth-child(5) {
          grid-column: 2 / 3;
          grid-row: 3 / 4; } }
  .gallery__list_tour {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr); }
    @media only screen and (max-width: 767px) {
      .gallery__list_tour {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr); } }
    .gallery__list_tour .gallery__preview:first-child {
      grid-column: 1 / 3;
      grid-row: 1 / 3; }
      @media only screen and (max-width: 767px) {
        .gallery__list_tour .gallery__preview:first-child {
          grid-column: 1 / 3;
          grid-row: 1 / 3; } }
    .gallery__list_tour .gallery__preview:nth-child(2) {
      grid-column: 3 / 4;
      grid-row: 1 / 2; }
      @media only screen and (max-width: 767px) {
        .gallery__list_tour .gallery__preview:nth-child(2) {
          grid-column: 1 / 2;
          grid-row: 3 / 4; } }
    .gallery__list_tour .gallery__preview:nth-child(3) {
      grid-column: 4 / 5;
      grid-row: 1 / 2; }
      @media only screen and (max-width: 767px) {
        .gallery__list_tour .gallery__preview:nth-child(3) {
          grid-column: 2 / 3;
          grid-row: 3 / 4; } }
    .gallery__list_tour .gallery__preview:nth-child(4) {
      grid-column: 3 / 4;
      grid-row: 2 / 3; }
      @media only screen and (max-width: 767px) {
        .gallery__list_tour .gallery__preview:nth-child(4) {
          grid-column: 1 / 2;
          grid-row: 4 / 5; } }
    .gallery__list_tour .gallery__preview:nth-child(5) {
      grid-column: 4 / 5;
      grid-row: 2 / 3; }
      @media only screen and (max-width: 767px) {
        .gallery__list_tour .gallery__preview:nth-child(5) {
          grid-column: 2 / 3;
          grid-row: 4 / 5; } }

.gallery__preview {
  position: relative;
  cursor: pointer; }
  .gallery__preview img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover; }
  .gallery__preview:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: 48px;
    height: 48px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1);
    background: #FCFCFD url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cpath fill='%23777e91' fill-rule='evenodd' d='M5.666.334C8.612.334 11 2.722 11 5.667a5.31 5.31 0 0 1-1.12 3.27l3.592 3.592c.26.26.26.682 0 .943s-.682.26-.943 0L8.937 9.881a5.31 5.31 0 0 1-3.27 1.12c-2.946 0-5.333-2.388-5.333-5.333S2.721.334 5.666.334zm0 1.333a4 4 0 0 0 0 8 4 4 0 0 0 0-8zm0 1.333c.368 0 .667.298.667.667h0v1.333h1.333c.368 0 .667.298.667.667s-.298.667-.667.667h0-1.333v1.333c0 .368-.298.667-.667.667S5 8.035 5 7.667h0V6.334H3.667c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h0H5V3.667c0-.368.298-.667.667-.667z'/%3E%3C/svg%3E") no-repeat 50% 50%/14px auto;
    opacity: 0;
    transition: opacity .2s;
    pointer-events: none; }
  .gallery__preview:hover:after {
    opacity: 1; }

.gallery__view {
  display: block;
  width: 100%;
  height: 100%; }

.gallery__button {
  position: absolute;
  left: 24px;
  bottom: 24px; }
  @media only screen and (max-width: 767px) {
    .gallery__button {
      left: 16px;
      bottom: 16px; } }
  .gallery__button .icon {
    width: 16px;
    height: 16px; }

.description {
  padding-bottom: 136px;
  border-bottom: 1px solid #E6E8EC; }
  @media only screen and (max-width: 1179px) {
    .description {
      margin-bottom: 80px;
      padding-bottom: 80px; } }
  @media only screen and (max-width: 767px) {
    .description {
      margin-bottom: 64px;
      padding-bottom: 64px; } }
  body.dark .description {
    border-color: #23262F; }
  .description__center {
    display: flex;
    align-items: flex-start; }
    @media only screen and (max-width: 1023px) {
      .description__center {
        display: block; } }
  .description__wrapper {
    flex: 0 0 calc(100% - 448px);
    max-width: calc(100% - 448px);
    padding-right: 48px;
    overflow: hidden; }
    @media only screen and (max-width: 1179px) {
      .description__wrapper {
        flex: 0 0 calc(100% - 400px);
        max-width: calc(100% - 400px);
        padding-right: 32px; } }
    @media only screen and (max-width: 1023px) {
      .description__wrapper {
        max-width: 100%;
        margin-bottom: 48px;
        padding-right: 0;
        overflow: visible; } }
  .description__title {
    margin-bottom: 8px; }
  .description__profile {
    display: flex;
    align-items: center; }
    .description__profile span {
      color: #777E90; }
  .description__avatar {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin: 0 8px; }
    .description__avatar img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover; }
  .description__name {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500; }
  .description__parameters {
    display: flex;
    flex-wrap: wrap;
    margin: 24px 0 40px 0;
    padding-top: 14px;
    border-top: 1px solid #E6E8EC; }
    body.dark .description__parameters {
      border-color: #23262F; }
  .description__parameter {
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #777E90; }
    .description__parameter .icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      fill: #777E90; }
    .description__parameter:not(:first-child) {
      margin-left: 16px; }
  .description__content {
    margin-bottom: 48px;
    font-size: 16px;
    line-height: 1.5; }
    .description__content p {
      color: #777E90; }
      .description__content p:not(:last-child) {
        margin-bottom: 24px; }
    .description__content h4 {
      margin-bottom: 32px;
      font-family: 'DM Sans', sans-serif;
      font-size: 32px;
      line-height: 40px; }
    .description__content_hide {
      display: none; }
  .description__full .description__content {
    margin: 0; }
  .description__info {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600;
    letter-spacing: -.01em; }
  .description__options {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: -24px 0 48px; }
    @media only screen and (max-width: 767px) {
      .description__options {
        display: block;
        margin-top: 0; } }
    .description__options:before {
      content: "";
      position: absolute;
      top: 24px;
      left: 44%;
      bottom: 0;
      width: 1px;
      background: #E6E8EC; }
      body.dark .description__options:before {
        background: #23262F; }
      @media only screen and (max-width: 767px) {
        .description__options:before {
          display: none; } }
  .description__option {
    display: flex;
    margin-top: 24px;
    color: #777E90; }
    .description__option .icon {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      fill: #777E90; }
    .description__option:nth-child(2n+1) {
      flex: 0 0 44%;
      padding-right: 40px; }
      @media only screen and (max-width: 1339px) {
        .description__option:nth-child(2n+1) {
          padding-right: 24px; } }
      @media only screen and (max-width: 767px) {
        .description__option:nth-child(2n+1) {
          padding: 0; } }
    .description__option:nth-child(2n) {
      flex: 0 0 56%;
      padding-left: 80px; }
      @media only screen and (max-width: 1339px) {
        .description__option:nth-child(2n) {
          padding-left: 24px; } }
      @media only screen and (max-width: 767px) {
        .description__option:nth-child(2n) {
          padding: 0; } }
  body.dark .description__button {
    box-shadow: inset 0 0 0 2px #353945; }
    body.dark .description__button:hover {
      background: #353945; }
  .description__list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -20px; }
    @media only screen and (max-width: 767px) {
      .description__list {
        display: block;
        margin: 24px 0 0; } }
  .description__item {
    display: flex;
    align-items: center;
    flex: 0 0 calc(50% - 40px);
    width: calc(50% - 40px);
    margin: 32px 20px 0;
    font-size: 16px;
    line-height: 1.5;
    color: #777E90; }
    @media only screen and (max-width: 1179px) {
      .description__item {
        flex: 0 0 calc(100% - 40px);
        width: calc(100% - 40px); } }
    @media only screen and (max-width: 1023px) {
      .description__item {
        flex: 0 0 calc(50% - 40px);
        width: calc(50% - 40px); } }
    @media only screen and (max-width: 767px) {
      .description__item {
        width: 100%;
        margin: 0; }
        .description__item:not(:last-child) {
          margin-bottom: 16px; } }
  .description__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 20px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid; }
    .description__icon .icon {
      width: 24px;
      height: 24px;
      fill: #23262F; }
      body.dark .description__icon .icon {
        fill: #FCFCFD; }
  .description__section {
    margin-top: 64px;
    padding-top: 64px;
    border-top: 1px solid #E6E8EC; }
    @media only screen and (max-width: 1179px) {
      .description__section {
        margin-top: 32px;
        padding-top: 32px; } }
    body.dark .description__section {
      border-color: #353945; }
  .description__section .description__content {
    margin-bottom: 24px; }
  .description__section .description__title {
    margin-bottom: 32px; }
  .description__inner {
    margin: 0 -16px; }
    @media only screen and (max-width: 767px) {
      .description__inner {
        margin: 0 -8px; } }
  .description__slider {
    padding-bottom: 72px;
    visibility: hidden; }
    .description__slider.slick-initialized {
      visibility: visible; }
  .description__slide {
    padding: 32px;
    border-radius: 24px; }
    @media only screen and (max-width: 1179px) {
      .description__slide {
        padding: 24px; } }
    body.dark .description__slide {
      color: #23262F; }
  .description__preview {
    margin-bottom: 64px; }
    @media only screen and (max-width: 1023px) {
      .description__preview {
        margin-bottom: 32px; } }
    .description__preview .icon {
      width: 32px;
      height: 32px; }
  .description__subtitle {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600;
    letter-spacing: -.01em; }
  .description__slide .description__text {
    font-size: 12px;
    line-height: 1.66667; }
  .description .slick-list {
    overflow: visible; }
    @media only screen and (max-width: 1179px) {
      .description .slick-list {
        margin-right: -80px; } }
    @media only screen and (max-width: 1023px) {
      .description .slick-list {
        margin-right: 0; } }
    @media only screen and (max-width: 767px) {
      .description .slick-list {
        margin-right: -160px; } }
  .description .slick-track {
    display: flex; }
  .description .slick-slide {
    height: auto;
    margin: 0 16px;
    opacity: 0;
    visibility: hidden;
    transition: all .4s; }
    @media only screen and (max-width: 767px) {
      .description .slick-slide {
        margin: 0 8px; } }
    .description .slick-slide.slick-active {
      opacity: 1;
      visibility: visible; }
  .description .slick-arrow {
    bottom: 0; }
  .description .slick-prev {
    left: 16px; }
  .description .slick-next {
    left: 64px; }

.receipt {
  flex-shrink: 0;
  width: 448px;
  padding: 32px;
  border: 1px solid #E6E8EC;
  background: #FCFCFD;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08);
  border-radius: 24px; }
  @media only screen and (max-width: 1179px) {
    .receipt {
      width: 400px; } }
  @media only screen and (max-width: 1023px) {
    .receipt {
      width: auto; } }
  @media only screen and (max-width: 767px) {
    .receipt {
      margin: 0 -16px;
      padding: 24px 16px; } }
  body.dark .receipt {
    border: 1px solid #353945;
    background: #23262F; }
  .receipt__head {
    display: flex;
    align-items: center;
    margin-bottom: 32px; }
  .receipt__head .receipt__cost {
    display: flex;
    margin-bottom: 8px; }
  .receipt__old, .receipt__actual {
    font-family: 'DM Sans', sans-serif;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.01em;
    font-weight: 700; }
  .receipt__old {
    margin-right: 12px;
    text-decoration: line-through;
    color: #B1B5C3; }
  .receipt__note {
    position: relative;
    top: -2px;
    align-self: flex-end;
    margin-left: 9px;
    font-size: 16px;
    line-height: 1.5;
    color: #777E90; }
  .receipt__avatar {
    position: relative;
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    margin-left: auto; }
    .receipt__avatar img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover; }
  .receipt__check {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #58C27D; }
    .receipt__check .icon {
      width: 12px;
      height: 12px;
      fill: #FCFCFD; }
  .receipt__rating {
    display: flex;
    align-items: center; }
    .receipt__rating .icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      fill: #FFD166; }
  .receipt__number {
    font-weight: 500; }
  .receipt__reviews {
    margin-left: 8px;
    color: #777E90; }
  .receipt__description {
    margin-bottom: 32px;
    padding: 8px;
    border-radius: 20px;
    background: #F4F5F6; }
    @media only screen and (max-width: 767px) {
      .receipt__description {
        padding: 0; } }
    body.dark .receipt__description {
      background: #353945; }
    .receipt__description_flex {
      display: flex;
      flex-wrap: wrap; }
      .receipt__description_flex .receipt__item {
        flex: 0 0 50%; }
        .receipt__description_flex .receipt__item:nth-child(2n) {
          position: relative; }
          .receipt__description_flex .receipt__item:nth-child(2n):before {
            content: "";
            position: absolute;
            top: 12px;
            left: 0;
            bottom: 12px;
            width: 1px;
            background: #E6E8EC; }
            body.dark .receipt__description_flex .receipt__item:nth-child(2n):before {
              background: #777E90; }
  .receipt__item {
    display: flex;
    align-items: center;
    padding: 12px; }
  .receipt__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 12px; }
    .receipt__icon .icon {
      width: 24px;
      height: 24px;
      fill: #B1B5C3; }
  .receipt__category {
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
  .receipt__subtitle {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500; }
  .receipt__btns {
    display: flex;
    margin-bottom: 32px; }
    .receipt__btns .receipt__button .icon {
      width: 16px;
      height: 16px;
      margin-left: 8px; }
      .receipt__btns .receipt__button .icon:nth-child(2) {
        display: none;
        width: 12px;
        height: 12px;
        margin-left: 12px; }
      .receipt__btns .receipt__button .icon:not(:last-child) {
        margin-right: 0; }
    .receipt__btns .receipt__button.active .icon:first-child {
      display: none; }
    .receipt__btns .receipt__button.active .icon:nth-child(2) {
      display: inline; }
    .receipt__btns .receipt__button:first-child {
      flex-shrink: 0;
      margin-right: 8px; }
    .receipt__btns .receipt__button:nth-child(2) {
      flex-grow: 1; }
  .receipt__stage {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600;
    letter-spacing: -.01em; }
  .receipt__table {
    margin-bottom: 32px; }
  .receipt__line {
    display: flex;
    padding: 8px 12px; }
    .receipt__line:last-child {
      background: #F4F5F6;
      border-radius: 8px; }
      body.dark .receipt__line:last-child {
        background: #353945; }
      .receipt__line:last-child .receipt__cell:first-child {
        color: #23262F; }
        body.dark .receipt__line:last-child .receipt__cell:first-child {
          color: #FCFCFD; }
  .receipt__cell:first-child {
    margin-right: auto;
    color: #777E90; }
  .receipt__cell:nth-child(2) {
    flex-shrink: 0;
    margin-left: 20px;
    text-align: right;
    font-weight: 500; }
  .receipt__offers {
    margin-bottom: 48px; }
  .receipt__offer {
    display: flex;
    align-items: center; }
    .receipt__offer:not(:last-child) {
      margin-bottom: 24px; }
    .receipt__offer .receipt__button {
      width: 192px;
      color: #777E90; }
      @media only screen and (max-width: 767px) {
        .receipt__offer .receipt__button {
          width: 180px;
          padding: 0 16px; } }
      .receipt__offer .receipt__button .icon {
        width: 16px;
        height: 16px;
        fill: #777E90; }
      .receipt__offer .receipt__button:hover {
        background: #3B71FE;
        box-shadow: inset 0 0 0 2px #3B71FE;
        color: #FCFCFD; }
        .receipt__offer .receipt__button:hover .icon {
          fill: #FCFCFD; }
        .receipt__offer .receipt__button:hover .receipt__money {
          color: #FCFCFD; }
  .receipt__wrap {
    flex-grow: 1; }
  .receipt__date {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500; }
  .receipt__time {
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
  .receipt__money {
    color: #58C27D;
    transition: color .2s; }
  .receipt__settings {
    margin-bottom: 32px; }
  .receipt__setting {
    display: flex;
    align-items: center; }
    .receipt__setting:not(:last-child) {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #E6E8EC; }
      body.dark .receipt__setting:not(:last-child) {
        border-color: #353945; }
  .receipt__setting .receipt__subtitle {
    margin-bottom: 8px; }
  .receipt__content {
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
  .receipt .counter {
    flex-shrink: 0;
    width: 128px;
    height: 48px;
    margin-left: 24px;
    padding: 0 12px;
    border-radius: 24px;
    box-shadow: inset 0 0 0 1px #E6E8EC; }
    body.dark .receipt .counter {
      box-shadow: inset 0 0 0 1px #353945; }
  .receipt__foot {
    text-align: center; }
  .receipt__foot .receipt__button .icon {
    width: 16px;
    height: 16px; }
  .receipt__report {
    display: inline-flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90;
    transition: color .2s; }
    .receipt__report .icon {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      fill: #777E90;
      transition: fill .2s; }
    .receipt__report:hover {
      color: #3B71FE; }
      .receipt__report:hover .icon {
        fill: #3B71FE; }
  .receipt__free {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
    .receipt__free .icon {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      fill: #777E90; }
  .receipt__more {
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    color: #23262F;
    transition: color .2s; }
    .receipt__more:hover {
      color: #3B71FE; }
    body.dark .receipt__more {
      color: #FCFCFD; }
      body.dark .receipt__more:hover {
        color: #3B71FE; }

.comments__center {
  display: flex;
  align-items: flex-start; }
  @media only screen and (max-width: 1023px) {
    .comments__center {
      flex-direction: column-reverse; } }

.comments .profile {
  flex-shrink: 0;
  width: 343px; }
  @media only screen and (max-width: 1023px) {
    .comments .profile {
      width: auto;
      margin-top: 64px; } }
  @media only screen and (max-width: 767px) {
    .comments .profile {
      margin: 64px -16px 0; } }

.comments .comment {
  flex-grow: 1;
  padding: 40px 0 0 48px; }
  @media only screen and (max-width: 1023px) {
    .comments .comment {
      padding: 0; } }

.profile {
  padding: 32px 20px;
  border: 1px solid #E6E8EC;
  border-radius: 24px;
  background: #FCFCFD; }
  @media only screen and (max-width: 767px) {
    .profile {
      padding: 24px 16px; } }
  body.dark .profile {
    border-color: #353945;
    background: #141416; }
  .profile__head, .profile__main {
    margin-bottom: 32px; }
  .profile__main {
    text-align: center; }
  .profile__line {
    display: flex;
    justify-content: center; }
  .profile__top {
    text-align: center;
    margin-bottom: 32px; }
    @media only screen and (max-width: 767px) {
      .profile__top {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left; } }
  .profile__avatar {
    position: relative;
    flex-shrink: 0; }
    .profile__avatar img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover; }
  .profile__line .profile__avatar {
    width: 64px;
    height: 64px;
    margin-right: 24px; }
  .profile__top .profile__avatar,
  .profile__main .profile__avatar {
    width: 160px;
    height: 160px; }
    @media only screen and (max-width: 767px) {
      .profile__top .profile__avatar,
      .profile__main .profile__avatar {
        width: 64px;
        height: 64px; } }
  .profile__top .profile__avatar {
    margin: 0 auto 24px; }
    @media only screen and (max-width: 767px) {
      .profile__top .profile__avatar {
        margin: 0 20px 0 0; } }
  .profile__main .profile__avatar {
    margin: 0 auto 12px; }
  .profile__check {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #58C27D; }
    .profile__check .icon {
      fill: #FCFCFD; }
  .profile__line .profile__check {
    width: 24px;
    height: 24px; }
    .profile__line .profile__check .icon {
      width: 12px;
      height: 12px; }
  .profile__top .profile__check {
    width: 40px;
    height: 40px; }
    @media only screen and (max-width: 767px) {
      .profile__top .profile__check {
        width: 24px;
        height: 24px; } }
    .profile__top .profile__check .icon {
      width: 16px;
      height: 16px; }
      @media only screen and (max-width: 767px) {
        .profile__top .profile__check .icon {
          width: 12px;
          height: 12px; } }
  .profile__update {
    display: inline-flex;
    align-items: center;
    margin-bottom: 24px;
    font-size: 12px;
    line-height: 1.66667;
    font-weight: 600;
    color: #777E90;
    transition: color .2s; }
    .profile__update .icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      fill: #777E90;
      transition: fill .2s; }
    .profile__update:hover {
      color: #3B71FE; }
      .profile__update:hover .icon {
        fill: #3B71FE; }
  .profile__man {
    font-family: 'DM Sans', sans-serif;
    font-size: 32px;
    line-height: 1.25;
    letter-spacing: -.01em; }
    .profile__man:not(:last-child) {
      margin-bottom: 8px; }
  @media only screen and (max-width: 767px) {
    .profile__top .profile__man {
      text-align: left; } }
  .profile__rating {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 1.66667; }
    .profile__rating .icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      fill: #FFD166; }
  .profile__number {
    font-weight: 500; }
  .profile__reviews {
    margin-left: 8px;
    color: #777E90; }
  .profile__body {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .profile__parameters {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    padding: 8px 16px;
    border-radius: 20px;
    background: #F4F5F6; }
    body.dark .profile__parameters {
      background: #23262F; }
  .profile__parameter {
    display: flex;
    align-items: center;
    color: #777E90; }
    .profile__parameter .icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
      fill: #777E90; }
    .profile__parameter:not(:last-child) {
      margin-right: 12px; }
  .profile__info {
    margin-bottom: 32px;
    text-align: center;
    color: #777E90; }
  .profile__site {
    display: inline-flex;
    align-items: center;
    margin-bottom: 32px;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #23262F; }
    body.dark .profile__site {
      color: #FCFCFD; }
    .profile__site .icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      fill: #777E90; }
  .profile__control {
    display: inline-flex;
    margin-bottom: 32px; }
  .profile__control .profile__button {
    margin-right: 8px; }
    body.dark .profile__control .profile__button {
      box-shadow: inset 0 0 0 2px #353945; }
      body.dark .profile__control .profile__button:hover {
        background: #353945; }
  .profile__socials {
    display: flex;
    justify-content: center; }
  .profile__social .icon {
    width: 20px;
    height: 20px;
    fill: #777E90;
    transition: fill .2s; }
  .profile__social:hover .icon {
    fill: #3B71FE; }
  .profile__social:not(:last-child) {
    margin-right: 24px; }
  .profile__note {
    position: relative;
    margin-top: 32px;
    padding-top: 32px;
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
    .profile__note:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 200px;
      height: 1px;
      transform: translateX(-50%);
      background: #E6E8EC; }
      body.dark .profile__note:before {
        background: #353945; }
    body.dark .profile__note {
      border-color: #353945; }
  .profile__report {
    display: inline-flex;
    align-items: center;
    margin-top: 32px;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90;
    transition: color .2s; }
    .profile__report .icon {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      fill: #777E90;
      transition: fill .2s; }
    .profile__report:hover {
      color: #3B71FE; }
      .profile__report:hover .icon {
        fill: #3B71FE; }

.comment__form {
  margin-bottom: 48px; }
  @media only screen and (max-width: 1179px) {
    .comment__form {
      margin-bottom: 40px; } }

.comment__title {
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em; }

.comment__form .comment__title {
  margin-bottom: 8px; }

.comment__head {
  display: flex;
  align-items: center;
  margin-bottom: 40px; }
  @media only screen and (max-width: 1179px) {
    .comment__head {
      display: block; } }

.comment__text {
  margin-right: auto;
  color: #777E90; }
  .comment__text span {
    font-weight: 500;
    color: #23262F; }
    body.dark .comment__text span {
      color: #FCFCFD; }

.comment .rating {
  position: relative;
  top: -3px;
  flex-shrink: 0; }
  @media only screen and (max-width: 1179px) {
    .comment .rating {
      margin: 8px 0 0 -4px; } }

.comment__field {
  position: relative; }

.comment__input {
  width: 100%;
  height: 72px;
  padding: 0 180px 0 24px;
  border-radius: 24px;
  background: none;
  border: 2px solid #E6E8EC;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #23262F;
  transition: border-color .2s; }
  @media only screen and (max-width: 767px) {
    .comment__input {
      height: 64px;
      padding-right: 96px; } }
  body.dark .comment__input {
    border-color: #353945;
    color: #FCFCFD; }
  .comment__input:focus {
    border-color: #777E90; }
  .comment__input::placeholder {
    color: #777E90; }

.comment__form .comment__button {
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 2;
  transform: translateY(-50%); }
  .comment__form .comment__button .icon {
    width: 14px;
    height: 14px; }
  @media only screen and (max-width: 767px) {
    .comment__form .comment__button {
      width: 32px;
      height: 32px;
      padding: 0;
      font-size: 0; }
      .comment__form .comment__button .icon:last-child {
        margin-left: 0 !important; } }

.comment .smile {
  position: absolute;
  top: 50%;
  right: 140px;
  z-index: 2;
  transform: translateY(-50%); }
  @media only screen and (max-width: 767px) {
    .comment .smile {
      right: 64px; } }

.comment__head {
  display: flex;
  align-items: center;
  margin-bottom: 32px; }
  @media only screen and (max-width: 767px) {
    .comment__head {
      display: none; } }
  .comment__head .select {
    width: 160px; }

.comment__head .comment__title {
  margin-right: auto; }

.comment__item {
  display: flex;
  align-items: flex-start;
  padding-bottom: 24px;
  border-bottom: 1px solid #E6E8EC; }
  body.dark .comment__item {
    border-color: #353945; }
  .comment__item:not(:last-child) {
    margin-bottom: 24px; }

.comment__avatar {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 20px; }
  @media only screen and (max-width: 767px) {
    .comment__avatar {
      margin-right: 14px; } }
  .comment__avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; }

.comment__details {
  flex-grow: 1; }

.comment__top {
  display: flex;
  margin-bottom: 4px; }

.comment__author {
  margin-right: auto;
  font-weight: 500; }

.comment .rating {
  position: relative;
  top: -2px;
  margin-left: 8px;
  flex-shrink: 0; }

.comment__content {
  margin-bottom: 8px;
  color: #353945; }
  body.dark .comment__content {
    color: #B1B5C3; }

.comment__time {
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.comment__foot {
  display: flex;
  align-items: center; }

.comment__actions {
  display: flex; }

.comment__action {
  margin-left: 16px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  transition: color .2s; }
  body.dark .comment__action {
    color: #FCFCFD; }
  .comment__action:hover {
    color: #3B71FE; }

.comment__btns {
  margin-top: 32px;
  text-align: center; }

.comment__btns .comment__button .loader {
  margin: 0 16px 0 5px;
  transform: scale(0.8); }

.checkbox {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .checkbox__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
    .checkbox__input:checked + .checkbox__inner .checkbox__tick {
      background: #3B71FE;
      border-color: #3B71FE; }
      .checkbox__input:checked + .checkbox__inner .checkbox__tick:before {
        opacity: 1; }
  .checkbox__inner {
    display: flex; }
    .checkbox__inner:hover .checkbox__tick {
      border-color: #3B71FE; }
  .checkbox__tick {
    position: relative;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border-radius: 4px;
    border: 2px solid #E6E8EC;
    transition: all .2s; }
    body.dark .checkbox__tick {
      background: none;
      border-color: #353945; }
    .checkbox__tick:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 14px;
      height: 10px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' fill='none' viewBox='0 0 14 10'%3E%3Cpath fill-rule='evenodd' d='M13.707.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 7.586 12.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfd'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto;
      opacity: 0;
      transition: opacity .2s; }
  .checkbox__text {
    line-height: 1.71429;
    font-weight: 500;
    color: #23262F; }
    body.dark .checkbox__text {
      color: #FCFCFD; }
  .checkbox__flex {
    display: flex;
    justify-content: space-between;
    flex-grow: 1; }
  .checkbox__counter {
    margin-left: 20px;
    font-weight: 500;
    color: #777E90; }

.radio {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .radio__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
    .radio__input:checked + .radio__inner .radio__tick:before {
      transform: translate(-50%, -50%) scale(1); }
  .radio__inner {
    display: flex; }
    .radio__inner:hover .radio__tick {
      border-color: #3B71FE; }
  .radio__tick {
    position: relative;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border-radius: 50%;
    border: 2px solid #E6E8EC;
    transition: all .2s; }
    body.dark .radio__tick {
      background: #141416;
      border-color: #353945; }
    .radio__tick:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #3B71FE;
      transition: transform .2s; }
  .radio__text {
    line-height: 1.71429;
    font-weight: 500;
    color: #23262F; }
    body.dark .radio__text {
      color: #FCFCFD; }

.field__label {
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #B1B5C3; }

.field__wrap {
  position: relative; }

.field__input, .field__textarea {
  width: 100%;
  border-radius: 12px;
  border: 2px solid #E6E8EC;
  box-shadow: none;
  background: none;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 1.71429;
  font-weight: 500;
  color: #23262F;
  transition: border-color .2s; }
  body.dark .field__input, body.dark .field__textarea {
    border-color: #353945;
    color: #FCFCFD; }
  .field__input::placeholder, .field__textarea::placeholder {
    color: #777E90; }
  .field__input:focus, .field__textarea:focus {
    border-color: #777E90; }
    body.dark .field__input:focus, body.dark .field__textarea:focus {
      border-color: #777E90; }

.field__input {
  height: 48px;
  padding: 0 14px; }

.field__textarea {
  height: 140px;
  padding: 10px 14px;
  resize: none; }

.field__button {
  position: absolute;
  right: 12px;
  bottom: 8px;
  height: 32px;
  padding: 0 12px;
  font-size: 14px; }
  body.dark .field__button {
    box-shadow: inset 0 0 0 2px #353945; }

.field__map {
  position: absolute;
  right: 16px;
  bottom: 16px;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  transition: color .2s; }
  @media only screen and (max-width: 767px) {
    .field__map {
      bottom: calc(100% + 10px);
      right: 0; } }
  body.dark .field__map {
    color: #FCFCFD; }
  .field__map:hover {
    color: #3B71FE; }
    body.dark .field__map:hover {
      color: #3B71FE; }

.field__view {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 48px; }
  .field__view .icon {
    width: 24px;
    height: 24px;
    fill: #B1B5C3;
    transition: fill .2s; }
  .field__view:hover .icon {
    fill: #777E90; }
  .field__view.active .icon {
    fill: #141416; }
  body.dark .field__view .icon {
    fill: #777E90; }
  body.dark .field__view:hover .icon {
    fill: #B1B5C3; }
  body.dark .field__view.active .icon {
    fill: #FCFCFD; }

.field_map .field__input {
  padding-right: 115px; }

.field_button .field__input {
  padding-right: 150px; }

.field_textarea {
  font-size: 0; }

.field_empty .field__input {
  border: none;
  height: 26px; }

.field_empty .select {
  height: 26px;
  padding: 0 36px 0 14px;
  box-shadow: none;
  background: none;
  line-height: 26px; }
  body.dark .field_empty .select {
    box-shadow: none;
    background: none; }
  .field_empty .select:before {
    right: 12px;
    width: 24px;
    height: 24px;
    box-shadow: none; }
    body.dark .field_empty .select:before {
      box-shadow: none; }

.field_empty .option {
  padding: 8px 14px; }

.field_view .field__input {
  padding-right: 48px; }

.checkout .control {
  margin-bottom: 80px; }
  @media only screen and (max-width: 1023px) {
    .checkout .control {
      margin-bottom: 48px; } }

.checkout__wrapper {
  display: flex;
  align-items: flex-start; }
  @media only screen and (max-width: 1023px) {
    .checkout__wrapper {
      flex-direction: column-reverse; } }

.checkout__inner {
  flex-grow: 1;
  padding-right: 80px; }
  @media only screen and (max-width: 1339px) {
    .checkout__inner {
      padding-right: 48px; } }
  @media only screen and (max-width: 1179px) {
    .checkout__inner {
      padding-right: 32px; } }
  @media only screen and (max-width: 1023px) {
    .checkout__inner {
      width: 100%;
      padding: 0; } }

.checkout__title {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #E6E8EC; }
  body.dark .checkout__title {
    border-color: #23262F; }

.checkout__data {
  margin-top: 24px; }
  .checkout__data_flex {
    display: flex;
    margin-left: -8px;
    margin-right: -8px; }
    @media only screen and (max-width: 767px) {
      .checkout__data_flex {
        display: block;
        margin-left: 0;
        margin-right: 0; } }

.checkout__data_flex .checkout__el {
  flex: 0 0 calc(50% - 16px);
  width: calc(50% - 16px);
  margin: 0 8px; }
  @media only screen and (max-width: 767px) {
    .checkout__data_flex .checkout__el {
      width: 100%;
      margin: 0; }
      .checkout__data_flex .checkout__el:not(:last-child) {
        margin-bottom: 12px; } }

.checkout__datepicker {
  position: relative;
  padding: 8px 56px 8px 16px;
  background: #F4F5F6;
  border-radius: 12px; }
  body.dark .checkout__datepicker {
    background: #23262F; }

.checkout__label {
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.checkout__input {
  width: 100%;
  height: 24px;
  background: none;
  box-shadow: none;
  border-radius: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #23262F;
  pointer-events: none; }
  body.dark .checkout__input {
    color: #FCFCFD; }

.checkout__controls {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%); }

.checkout__edit .icon {
  width: 24px;
  height: 24px;
  fill: #B1B5C3;
  transition: fill .2s; }

.checkout__edit:hover .icon {
  fill: #777E90; }

.checkout__edit:nth-child(2) {
  display: none; }
  .checkout__edit:nth-child(2) .icon {
    fill: #3B71FE; }

.checkout__section:not(:first-child) {
  margin-top: 48px;
  padding-top: 48px;
  border-top: 1px solid #E6E8EC; }
  body.dark .checkout__section:not(:first-child) {
    border-color: #23262F; }

.checkout__category {
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em; }

.checkout__box > .category {
  margin-bottom: 24px; }

.checkout__box:not(:last-child) {
  margin-bottom: 32px; }

.checkout__top {
  display: flex;
  align-items: center;
  margin-bottom: 24px; }
  .checkout__top > .checkout__category {
    margin-right: auto; }

.checkout__group {
  display: flex;
  margin: 0 -8px; }
  @media only screen and (max-width: 767px) {
    .checkout__group {
      display: block; } }

.checkout__line {
  display: flex;
  align-items: center;
  margin-bottom: 40px; }

.checkout__subtitle {
  margin-right: auto;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.checkout__cards {
  display: flex;
  align-items: center; }

.checkout__item {
  display: none; }

.checkout__fieldset > .field {
  margin-bottom: 32px; }

.checkout__row {
  display: flex;
  margin: 0 -8px; }
  .checkout__row .field {
    flex: 0 0 calc(50% - 16px);
    width: calc(50% - 16px);
    margin: 0 8px; }

.checkout .checkbox {
  margin-top: 32px; }

.checkout__message {
  margin-top: 24px; }
  .checkout__message .checkout__category {
    margin-bottom: 24px; }

.checkout__item > .checkout__button {
  margin-top: 32px; }

.checkout__logo img {
  max-height: 24px; }

.checkout__control {
  display: flex;
  align-items: center; }
  .checkout__control .checkout__button {
    margin: 0 16px 0 0; }

.checkout__verified img {
  max-height: 48px; }

.checkout .date-picker-wrapper {
  top: calc(100% + 16px) !important;
  bottom: auto !important; }
  @media only screen and (max-width: 767px) {
    .checkout .date-picker-wrapper {
      top: 100% !important;
      bottom: auto !important; } }

.checkout_stays .price {
  flex-shrink: 0;
  width: 544px; }
  @media only screen and (max-width: 1339px) {
    .checkout_stays .price {
      width: 500px; } }
  @media only screen and (max-width: 1179px) {
    .checkout_stays .price {
      width: 416px; } }
  @media only screen and (max-width: 1023px) {
    .checkout_stays .price {
      width: 100%;
      margin-bottom: 48px; } }

.checkout_tasks .price,
.checkout_cars .price {
  flex-shrink: 0;
  width: 448px; }
  @media only screen and (max-width: 1179px) {
    .checkout_tasks .price,
    .checkout_cars .price {
      width: 416px; } }
  @media only screen and (max-width: 1023px) {
    .checkout_tasks .price,
    .checkout_cars .price {
      width: auto;
      margin-bottom: 48px; } }

.price {
  padding: 32px;
  border-radius: 24px;
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  border: 1px solid #E6E8EC; }
  @media only screen and (max-width: 1179px) {
    .price {
      padding: 24px; } }
  @media only screen and (max-width: 767px) {
    .price {
      padding: 16px; } }
  body.dark .price {
    border-color: #353945; }
  .price__head {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .price__head {
        display: block; } }
  .price__head .price__preview {
    flex-shrink: 0;
    width: 180px;
    height: 180px;
    margin-right: 24px; }
    @media only screen and (max-width: 767px) {
      .price__head .price__preview {
        width: 100%;
        height: 220px;
        margin: 0 0 24px; } }
    .price__head .price__preview img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px; }
  .price__head .price__title {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500; }
  .price__details {
    flex-grow: 1; }
  .price__author {
    display: flex;
    align-items: center;
    margin-bottom: 8px; }
    .price__author .price__text {
      margin-right: 4px;
      white-space: nowrap;
      font-size: 12px;
      line-height: 1.66667;
      color: #777E90; }
  .price__avatar {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 8px; }
    .price__avatar img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover; }
  .price__man {
    font-size: 12px;
    line-height: 1.66667;
    font-weight: 600; }
  .price__top {
    margin-bottom: 24px; }
  .price__top .price__title {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600;
    letter-spacing: -.01em; }
  .price__photo {
    margin-bottom: 16px; }
    .price__photo img {
      width: 100%;
      border-radius: 16px; }
  .price__parameter {
    display: inline;
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
    .price__parameter:not(:first-child) {
      margin-left: 4px; }
      .price__parameter:not(:first-child):before {
        content: "•";
        margin-right: 4px; }
  .price__rating {
    display: flex;
    align-items: center;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #E6E8EC; }
    body.dark .price__rating {
      border-color: #23262F; }
    .price__rating .icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      fill: #FFD166; }
  .price__number {
    font-weight: 500; }
  .price__reviews {
    margin-left: 8px;
    color: #777E90; }
  .price__description {
    margin-top: 32px;
    padding: 8px;
    border-radius: 20px;
    background: #F4F5F6; }
    body.dark .price__description {
      background: #23262F; }
    .price__description_flex {
      display: flex;
      flex-wrap: wrap; }
      @media only screen and (max-width: 767px) {
        .price__description_flex {
          display: block; } }
      .price__description_flex .price__item {
        flex: 0 0 50%; }
        .price__description_flex .price__item:nth-child(2n) {
          position: relative; }
          .price__description_flex .price__item:nth-child(2n):before {
            content: "";
            position: absolute;
            top: 12px;
            left: 0;
            bottom: 12px;
            width: 1px;
            background: #E6E8EC; }
            body.dark .price__description_flex .price__item:nth-child(2n):before {
              background: #777E90; }
            @media only screen and (max-width: 767px) {
              .price__description_flex .price__item:nth-child(2n):before {
                display: none; } }
  .price__item {
    display: flex;
    padding: 12px; }
  .price__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 12px; }
    .price__icon .icon {
      width: 24px;
      height: 24px;
      fill: #B1B5C3; }
  .price__category {
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
  .price__subtitle {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500; }
  .price__body {
    margin-top: 32px; }
  .price__stage {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600;
    letter-spacing: -.01em; }
  .price .subscription {
    margin: -8px 0 16px; }
  .price__row {
    display: flex;
    padding: 8px 12px; }
    .price__row:last-child {
      background: #F4F5F6;
      border-radius: 8px; }
      body.dark .price__row:last-child {
        background: #23262F; }
      .price__row:last-child .price__cell:first-child {
        color: #23262F; }
        body.dark .price__row:last-child .price__cell:first-child {
          color: #FCFCFD; }
    .price__row:not(:last-child) {
      margin-bottom: 4px; }
  .price__cell:first-child {
    margin-right: auto;
    color: #777E90; }
  .price__cell:nth-child(2) {
    flex-shrink: 0;
    margin-left: 20px;
    text-align: right;
    font-weight: 500; }
  .price__note {
    margin-top: 32px;
    text-align: center;
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
    .price__note .icon {
      position: relative;
      top: -1px;
      width: 12px;
      height: 12px;
      margin-right: 8px;
      fill: #777E90; }

.complete .control {
  margin-bottom: 80px; }
  @media only screen and (max-width: 1023px) {
    .complete .control {
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .complete .control {
      margin-bottom: 24px; } }

.complete__row {
  display: flex;
  align-items: flex-start;
  margin: 0 -16px; }
  @media only screen and (max-width: 1023px) {
    .complete__row {
      display: block;
      margin: 0; } }

.complete__col {
  flex: 0 0 calc(50% - 32px);
  width: calc(50% - 32px);
  margin: 0 16px; }
  @media only screen and (max-width: 1023px) {
    .complete__col {
      width: 100%;
      margin: 0; } }
  .complete__col:nth-child(2) {
    padding-left: 48px; }
    @media only screen and (max-width: 1179px) {
      .complete__col:nth-child(2) {
        padding-left: 0; } }
  @media only screen and (max-width: 1023px) {
    .complete__col:not(:last-child) {
      margin-bottom: 48px; } }
  @media only screen and (max-width: 767px) {
    .complete__col:not(:last-child) {
      margin-bottom: 32px; } }

.complete__wrapper {
  margin: 0 -16px; }

.complete__slider {
  padding-bottom: 64px;
  visibility: hidden; }
  .complete__slider.slick-initialized {
    visibility: visible; }

.complete__preview img {
  width: 100%;
  object-fit: cover;
  border-radius: 16px; }
  @media only screen and (max-width: 1023px) {
    .complete__preview img {
      height: 600px; } }
  @media only screen and (max-width: 767px) {
    .complete__preview img {
      height: 300px; } }

.complete__head {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #E6E8EC; }
  body.dark .complete__head {
    border-color: #23262F; }

.complete__title {
  margin-bottom: 4px; }

.complete__info {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 1.5;
  color: #777E90; }

.complete__subtitle {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.complete__author {
  display: flex;
  align-items: center; }
  .complete__author span {
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }

.complete__avatar {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin: 0 8px 0 4px; }
  .complete__avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; }

.complete__man {
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600; }

.complete__line {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 32px; }

.complete__rating {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .complete__rating {
      flex-wrap: wrap; } }
  .complete__rating .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: #FFD166; }

.complete__number {
  font-weight: 500; }

.complete__reviews {
  margin-left: 8px;
  color: #777E90; }

.complete__parameters {
  display: flex;
  flex-wrap: wrap;
  margin-left: 24px; }
  @media only screen and (max-width: 767px) {
    .complete__parameters {
      flex: 0 0 100%;
      margin: 16px 0 0; } }

.complete__parameter {
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }
  .complete__parameter .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    fill: #777E90; }
  .complete__parameter:not(:last-child) {
    margin-right: 20px; }

.complete__list {
  display: flex;
  margin-bottom: 32px; }
  @media only screen and (max-width: 767px) {
    .complete__list {
      display: block; } }

.complete__item {
  display: flex;
  align-items: center;
  flex: 0 0 50%; }
  .complete__item:first-child {
    padding-right: 24px; }
    @media only screen and (max-width: 767px) {
      .complete__item:first-child {
        padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .complete__item:not(:last-child) {
      margin-bottom: 16px; } }

.complete__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid; }
  .complete__icon .icon {
    width: 24px;
    height: 24px;
    fill: #23262F; }
    body.dark .complete__icon .icon {
      fill: #FCFCFD; }

.complete__category {
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.complete__value {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.complete__stage {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em; }

.complete__table {
  margin-bottom: 32px; }

.complete__flex {
  display: flex;
  align-items: center; }
  .complete__flex:not(:last-child) {
    margin-bottom: 20px; }

.complete__cell {
  flex: 0 0 50%; }
  @media only screen and (max-width: 767px) {
    .complete__cell {
      flex: 0 0 auto; } }
  .complete__cell:first-child {
    display: flex;
    align-items: center;
    padding-right: 24px;
    color: #777E90; }
    @media only screen and (max-width: 767px) {
      .complete__cell:first-child {
        margin-right: auto; } }
    .complete__cell:first-child .icon {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      fill: #777E90; }
  .complete__cell:nth-child(2) {
    font-weight: 500; }

.complete__btns {
  display: flex; }

@media only screen and (max-width: 767px) {
  .complete__btns .complete__button {
    padding: 0 16px; } }

.complete__btns .complete__button:not(:last-child) {
  margin-right: 12px; }

.complete .slick-slide {
  margin: 0 16px;
  opacity: 0;
  visibility: hidden;
  transition: all .4s; }
  .complete .slick-slide.slick-active {
    opacity: 1;
    visibility: visible; }

.complete .slick-arrow {
  bottom: 0; }

.complete .slick-prev {
  left: calc(50% - 44px); }

.complete .slick-next {
  right: calc(50% - 44px); }

@media only screen and (max-width: 1023px) {
  .complete_tasks .complete__row {
    display: flex;
    flex-direction: column-reverse; } }

@media only screen and (max-width: 1023px) {
  .complete_tasks .complete__col:not(:last-child) {
    margin: 48px 0 0; } }

@media only screen and (max-width: 767px) {
  .complete_tasks .complete__col:not(:last-child) {
    margin: 32px 0 0; } }

.support {
  position: relative;
  padding: 308px 0 376px;
  overflow: hidden; }
  @media only screen and (max-width: 1179px) {
    .support {
      padding: 252px 0; } }
  @media only screen and (max-width: 767px) {
    .support {
      padding: 200px 0; } }
  .support__center {
    max-width: 746px; }
  .support__title {
    margin-bottom: 16px;
    text-align: center; }
  .support__info {
    max-width: 482px;
    margin: 0 auto 32px;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    color: #777E90; }
  .support .subscription {
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12); }
    @media only screen and (max-width: 1023px) {
      .support .subscription {
        max-width: 480px;
        margin: 0 auto; } }
  .support__preview {
    position: absolute;
    z-index: -1; }
    .support__preview:first-child {
      top: 28px;
      left: calc(50% - 790px); }
      @media only screen and (max-width: 767px) {
        .support__preview:first-child {
          top: -50px;
          left: calc(50% - 240px); }
          .support__preview:first-child img {
            width: 300px; } }
    .support__preview:nth-child(2) {
      top: 45px;
      right: calc(50% - 640px); }
      @media only screen and (max-width: 1179px) {
        .support__preview:nth-child(2) {
          top: 0; } }
      @media only screen and (max-width: 767px) {
        .support__preview:nth-child(2) {
          top: 45px;
          right: calc(50% - 310px); }
          .support__preview:nth-child(2) img {
            width: 300px; } }
    .support__preview:nth-child(3) {
      right: calc(50% - 740px);
      bottom: 100px; }
    .support__preview:nth-child(4) {
      left: calc(50% - 360px);
      bottom: 80px; }
      @media only screen and (max-width: 1179px) {
        .support__preview:nth-child(4) {
          bottom: 10px; } }
      @media only screen and (max-width: 767px) {
        .support__preview:nth-child(4) {
          left: calc(50% - 200px); }
          .support__preview:nth-child(4) img {
            width: 300px; } }

.topics__head {
  margin-bottom: 92px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .topics__head {
      margin-bottom: 40px; } }

.topics .stage {
  margin-bottom: 8px; }

.topics__title {
  margin-bottom: 32px; }

.topics__wrapper {
  margin: 0 -16px; }
  @media only screen and (max-width: 767px) {
    .topics__wrapper {
      margin: 0 -4px; } }

.topics__item {
  padding: 64px 32px;
  border-radius: 20px;
  background: #FCFCFD;
  transition: box-shadow .2s; }
  @media only screen and (max-width: 767px) {
    .topics__item {
      padding: 64px 24px; } }
  body.dark .topics__item {
    background: #23262F; }
  .topics__item:hover {
    box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1); }

.topics__icon {
  display: flex;
  align-items: center;
  height: 48px;
  margin-bottom: 32px; }
  @media only screen and (max-width: 767px) {
    .topics__icon {
      margin-bottom: 24px; } }
  .topics__icon img {
    max-height: 100%; }

.topics__subtitle {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.topics__content {
  color: #777E90; }

.topics__slider {
  padding-bottom: 64px;
  visibility: hidden; }
  .topics__slider.slick-initialized {
    visibility: visible; }

.topics .slick-list {
  overflow: visible; }

.topics .slick-track {
  display: flex; }

.topics .slick-slide {
  height: auto;
  margin: 0 12px;
  opacity: 0;
  visibility: hidden;
  transition: all .4s; }
  @media only screen and (max-width: 767px) {
    .topics .slick-slide {
      margin: 0 4px;
      opacity: 1;
      visibility: visible; } }
  .topics .slick-slide.slick-active {
    opacity: 1;
    visibility: visible; }

.topics .slick-arrow {
  bottom: 0;
  width: 24px;
  height: 24px; }
  .topics .slick-arrow:hover {
    box-shadow: none; }
    .topics .slick-arrow:hover path {
      fill: #23262F; }
      body.dark .topics .slick-arrow:hover path {
        fill: #FCFCFD; }

.topics .slick-prev {
  left: calc(50% - 34px); }

.topics .slick-next {
  right: calc(50% - 34px); }

.faq__top {
  max-width: 640px;
  margin: 0 auto 80px;
  text-align: center; }
  @media only screen and (max-width: 1023px) {
    .faq__top {
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .faq__top {
      margin-bottom: 40px; } }

.faq__stage {
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #777E90; }

.faq__title {
  margin-bottom: 20px; }

.faq__info {
  font-size: 16px;
  line-height: 1.5;
  color: #353945; }
  @media only screen and (max-width: 767px) {
    .faq__info {
      margin-bottom: 40px; } }
  body.dark .faq__info {
    color: #E6E8EC; }
  .faq__info a {
    font-weight: 500;
    color: #3B71FE; }
    .faq__info a:hover {
      text-decoration: underline; }

.faq__row {
  display: flex; }
  @media only screen and (max-width: 767px) {
    .faq__row {
      display: block; } }

.faq__col:first-child {
  flex-shrink: 0;
  width: 160px; }
  @media only screen and (max-width: 767px) {
    .faq__col:first-child {
      display: none; } }

.faq__col:nth-child(2) {
  flex-grow: 1;
  padding-left: 225px; }
  @media only screen and (max-width: 1339px) {
    .faq__col:nth-child(2) {
      padding-left: 128px; } }
  @media only screen and (max-width: 1179px) {
    .faq__col:nth-child(2) {
      padding-left: 64px; } }
  @media only screen and (max-width: 1023px) {
    .faq__col:nth-child(2) {
      padding-left: 32px; } }
  @media only screen and (max-width: 767px) {
    .faq__col:nth-child(2) {
      padding: 0; } }

.faq__nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.faq__link {
  display: flex;
  align-items: center;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777E90;
  cursor: pointer;
  transition: color .2s; }
  .faq__link .icon {
    width: 16px;
    height: 16px;
    margin-right: 16px;
    fill: #777E90;
    transition: fill .2s; }
  .faq__link:hover, .faq__link.active {
    color: #23262F; }
    .faq__link:hover .icon, .faq__link.active .icon {
      fill: #23262F; }
    body.dark .faq__link:hover, body.dark .faq__link.active {
      color: #FCFCFD; }
      body.dark .faq__link:hover .icon, body.dark .faq__link.active .icon {
        fill: #FCFCFD; }
  .faq__link:not(:last-child) {
    margin-bottom: 40px; }

.faq__box {
  display: none; }

.faq__item {
  border-top: 1px solid #E6E8EC; }
  body.dark .faq__item {
    border-color: #353945; }
  .faq__item.active .faq__head:before {
    transform: translateY(-50%) rotate(180deg); }

.faq__head {
  position: relative;
  padding: 32px 40px 32px 0;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color .2s; }
  .faq__head:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    width: 10px;
    height: 6px;
    transform: translateY(-50%);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto;
    transition: transform .2s; }
  .faq__head:hover {
    color: #3B71FE; }

.faq__body {
  display: none;
  padding-bottom: 32px;
  font-size: 16px;
  line-height: 1.5;
  color: #777E90; }

.faq__button {
  margin-top: 24px; }

.host__photo {
  margin: 0 -80px; }
  @media only screen and (max-width: 1179px) {
    .host__photo {
      margin: 0 -40px; } }
  @media only screen and (max-width: 1023px) {
    .host__photo {
      display: none; } }
  .host__photo img {
    width: 100%;
    border-radius: 24px; }

.host__row {
  display: flex;
  align-items: flex-start; }
  @media only screen and (max-width: 1023px) {
    .host__row {
      display: block; } }

.host .profile {
  position: relative;
  z-index: 3;
  flex-shrink: 0;
  width: 352px;
  margin-top: -125px; }
  @media only screen and (max-width: 1179px) {
    .host .profile {
      width: 300px;
      padding: 32px 16px; } }
  @media only screen and (max-width: 1023px) {
    .host .profile {
      width: auto;
      margin: 0 0 48px; } }
  @media only screen and (max-width: 767px) {
    .host .profile {
      margin: 0 -16px 32px; } }

.host__wrapper {
  flex: 0 0 calc(100% - 352px);
  width: calc(100% - 352px);
  padding: 80px 0 0 80px; }
  @media only screen and (max-width: 1339px) {
    .host__wrapper {
      padding-left: 48px; } }
  @media only screen and (max-width: 1179px) {
    .host__wrapper {
      flex: 0 0 calc(100% - 300px);
      width: calc(100% - 300px);
      padding: 48px 0 0 32px; } }
  @media only screen and (max-width: 1023px) {
    .host__wrapper {
      width: 100%;
      padding: 0; } }

.host__container {
  margin-bottom: 104px; }
  @media only screen and (max-width: 767px) {
    .host__container {
      margin-bottom: 64px; } }

.host__section {
  position: relative; }
  .host__section:not(:last-child) {
    margin-bottom: 64px; }

.host__title {
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em; }

.host__content {
  margin-bottom: 24px;
  color: #777E90; }

.host__option {
  display: flex;
  align-items: flex-start; }
  .host__option .host__text {
    font-weight: 500; }
  .host__option:not(:last-child) {
    margin-bottom: 20px; }

.host__category {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 152px;
  padding-right: 24px;
  color: #777E90; }
  @media only screen and (max-width: 767px) {
    .host__category {
      width: 130px; } }
  .host__category .icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    fill: #777E90; }

.host__text[href] {
  color: #3B71FE;
  transition: color .2s; }
  .host__text[href]:hover {
    color: #084cfe; }

.host .nav {
  margin-bottom: 32px; }

.host__inner {
  margin: 0 -16px; }
  @media only screen and (max-width: 1179px) {
    .host__inner {
      margin: 0 -8px; } }

.host__slider {
  position: static;
  visibility: hidden; }
  @media only screen and (max-width: 767px) {
    .host__slider {
      padding-bottom: 72px; } }
  .host__slider.slick-initialized {
    visibility: visible; }

.host .slick-list {
  overflow: visible; }
  @media only screen and (max-width: 474px) {
    .host .slick-list {
      margin: 0 -280px 0 0; } }

.host .slick-slide {
  margin: 0 16px;
  opacity: 0;
  visibility: hidden;
  transition: all .4s; }
  @media only screen and (max-width: 1179px) {
    .host .slick-slide {
      margin: 0 8px; } }
  .host .slick-slide.slick-active {
    opacity: 1;
    visibility: visible; }

.host .slick-arrow {
  top: 0; }
  @media only screen and (max-width: 767px) {
    .host .slick-arrow {
      top: auto;
      bottom: 0; } }

.host .slick-prev {
  right: 48px; }
  @media only screen and (max-width: 767px) {
    .host .slick-prev {
      right: auto;
      left: calc(50% - 44px); } }

.host .slick-next {
  right: 0; }
  @media only screen and (max-width: 767px) {
    .host .slick-next {
      right: calc(50% - 44px); } }

.user__body {
  position: relative;
  z-index: 3;
  padding: 80px 0 136px; }
  @media only screen and (max-width: 1179px) {
    .user__body {
      padding-bottom: 112px; } }
  @media only screen and (max-width: 1023px) {
    .user__body {
      padding: 0 0 92px; } }
  @media only screen and (max-width: 767px) {
    .user__body {
      padding: 0 0 64px; } }

.user__center {
  display: flex;
  align-items: flex-start; }
  @media only screen and (max-width: 1023px) {
    .user__center {
      display: block; } }

.user .profile {
  flex-shrink: 0;
  width: 352px;
  margin-top: -193px;
  text-align: center; }
  @media only screen and (max-width: 1023px) {
    .user .profile {
      width: auto;
      margin: 0 -16px 48px; } }

.user__wrapper {
  flex-grow: 1;
  padding-left: 80px; }
  @media only screen and (max-width: 1179px) {
    .user__wrapper {
      padding-left: 48px; } }
  @media only screen and (max-width: 1023px) {
    .user__wrapper {
      padding-left: 0; } }

.user__details {
  margin-bottom: 64px; }
  @media only screen and (max-width: 767px) {
    .user__details {
      margin-bottom: 40px; } }

.user__head {
  display: flex;
  align-items: center;
  margin-bottom: 32px; }
  @media only screen and (max-width: 767px) {
    .user__head {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-bottom: 24px; } }

.user__title {
  margin-right: auto;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em; }
  @media only screen and (max-width: 767px) {
    .user__title {
      margin: 24px 0 0; } }

.user__content {
  margin-bottom: 32px;
  color: #777E90; }

.user__option {
  display: flex;
  align-items: flex-start; }
  .user__option:not(:last-child) {
    margin-bottom: 20px; }

.user__category {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 152px;
  padding-right: 24px;
  color: #777E90; }
  @media only screen and (max-width: 767px) {
    .user__category {
      width: 130px; } }
  .user__category .icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    fill: #777E90; }

.user__text {
  font-weight: 500; }

@media only screen and (max-width: 1023px) {
  .background {
    display: none; } }

.background__center {
  max-width: 1440px; }
  @media only screen and (max-width: 1419px) {
    .background__center {
      padding: 0 40px; } }

.background__head {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 400px;
  border-radius: 24px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  overflow: hidden; }
  .background__head.active .background__btns {
    opacity: 0; }
  .background__head.active .background__file {
    opacity: 1;
    visibility: visible; }

.background__btns {
  position: absolute;
  right: 16px;
  bottom: 16px;
  transition: opacity .2s; }
  .background__btns .background__button {
    color: #FCFCFD; }
    .background__btns .background__button .icon {
      width: 16px;
      height: 16px;
      fill: #FCFCFD; }
    body.dark .background__btns .background__button {
      box-shadow: inset 0 0 0 2px #E6E8EC; }
      body.dark .background__btns .background__button:hover {
        background: #E6E8EC;
        color: #23262F; }
        body.dark .background__btns .background__button:hover .icon {
          fill: #23262F; }
    .background__btns .background__button:not(:last-child) {
      margin-right: 16px; }

.background__file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #FCFCFD;
  opacity: 0;
  visibility: hidden;
  transition: all .2s; }
  @media only screen and (max-width: 767px) {
    .background__file {
      align-items: flex-start;
      padding-top: 40px; } }
  .background__file:before, .background__file:after {
    content: "";
    position: absolute; }
  .background__file:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(20, 20, 22, 0.3); }
  .background__file:after {
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    border: 2px dashed #E6E8EC;
    border-radius: 20px; }
  .background__file input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    font-size: 1400px;
    opacity: 0; }
  .background__file .icon {
    width: 48px;
    height: 48px;
    margin-bottom: 24px;
    fill: #FCFCFD; }
    @media only screen and (max-width: 767px) {
      .background__file .icon {
        width: 24px;
        height: 24px;
        margin-bottom: 12px; } }
  .background__file .background__button {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 4; }
    @media only screen and (max-width: 1023px) {
      .background__file .background__button {
        bottom: 48px; } }
    @media only screen and (max-width: 767px) {
      .background__file .background__button {
        right: 50%;
        transform: translateX(50%); } }

.background__wrap {
  position: relative;
  z-index: 2; }

.background__info {
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600; }
  @media only screen and (max-width: 767px) {
    .background__info {
      font-size: 16px; } }

.background__text {
  font-size: 16px;
  line-height: 1.5; }
  @media only screen and (max-width: 767px) {
    .background__text {
      font-size: 12px; } }

.reviews__head {
  display: flex;
  align-items: center;
  margin-bottom: 32px; }
  @media only screen and (max-width: 767px) {
    .reviews__head {
      display: block; } }

.reviews__title {
  margin-right: auto;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em; }
  @media only screen and (max-width: 767px) {
    .reviews__title {
      margin: 0 0 32px; } }

.reviews__item {
  display: flex;
  padding-top: 32px;
  border-top: 1px solid #E6E8EC; }
  body.dark .reviews__item {
    border-color: #23262F; }
  .reviews__item:not(:first-child) {
    margin-top: 32px; }

.reviews__avatar {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 20px; }
  .reviews__avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; }

.reviews__details {
  flex-grow: 1; }

.reviews__author {
  margin-bottom: 4px;
  font-weight: 500;
  color: #141416; }
  body.dark .reviews__author {
    color: #FCFCFD; }

.reviews__content {
  margin-bottom: 8px;
  color: #353945; }
  body.dark .reviews__content {
    color: #777E90; }

.reviews__foot {
  display: flex;
  align-items: center; }

.reviews__time {
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.reviews__actions {
  display: flex; }

.reviews__action {
  margin-left: 16px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  transition: color .2s; }
  .reviews__action:hover {
    color: #3B71FE; }
  body.dark .reviews__action {
    color: #FCFCFD; }
    body.dark .reviews__action:hover {
      color: #3B71FE; }

.switch {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0; }
  .switch__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
    .switch__input:checked + .switch__inner .switch__box {
      background: #3B71FE; }
      .switch__input:checked + .switch__inner .switch__box:before {
        transform: translate(24px, -50%);
        background: #FCFCFD; }
  .switch__inner {
    position: relative;
    display: inline-block;
    transition: all .2s; }
  .switch__box {
    position: relative;
    display: block;
    width: 48px;
    height: 24px;
    background: #E6E8EC;
    border-radius: 12px;
    transition: all .2s; }
    body.dark .switch__box {
      background: #353945; }
    .switch__box:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #3B71FE;
      transition: all .2s; }

.setting {
  padding-top: 80px; }
  @media only screen and (max-width: 1179px) {
    .setting {
      padding-top: 64px; } }
  @media only screen and (max-width: 767px) {
    .setting {
      padding-top: 32px; } }
  .setting__center {
    display: flex;
    align-items: flex-start; }
    @media only screen and (max-width: 1023px) {
      .setting__center {
        display: block; } }
  @media only screen and (max-width: 1023px) {
    .setting .select {
      margin-bottom: 48px; } }
  @media only screen and (max-width: 767px) {
    .setting .select {
      margin-bottom: 32px; } }
  .setting__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    width: 256px;
    padding: 48px;
    border-radius: 16px;
    box-shadow: 0px 32px 32px -12px rgba(15, 15, 15, 0.08); }
    @media only screen and (max-width: 1023px) {
      .setting__menu {
        display: none; } }
    body.dark .setting__menu {
      background: #23262F; }
  .setting__link {
    display: flex;
    align-items: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90;
    transition: color .2s; }
    .setting__link .icon {
      width: 16px;
      height: 16px;
      margin-right: 16px;
      fill: #777E90; }
    .setting__link:hover, .setting__link.active {
      color: #23262F; }
      .setting__link:hover .icon, .setting__link.active .icon {
        fill: #23262F; }
      body.dark .setting__link:hover, body.dark .setting__link.active {
        color: #FCFCFD; }
        body.dark .setting__link:hover .icon, body.dark .setting__link.active .icon {
          fill: #FCFCFD; }
    .setting__link:not(:last-child) {
      margin-bottom: 40px; }
  .setting__container {
    padding-left: 128px;
    flex-grow: 1; }
    @media only screen and (max-width: 1339px) {
      .setting__container {
        padding-left: 64px; } }
    @media only screen and (max-width: 1179px) {
      .setting__container {
        padding-left: 32px; } }
    @media only screen and (max-width: 1023px) {
      .setting__container {
        padding-left: 0; } }
  .setting__head {
    display: flex;
    align-items: center;
    margin-bottom: 64px; }
    @media only screen and (max-width: 767px) {
      .setting__head {
        margin-bottom: 32px; } }
    .setting__head .setting__button {
      flex-shrink: 0;
      margin-left: 16px; }
      body.dark .setting__head .setting__button {
        box-shadow: inset 0 0 0 2px #353945; }
  .setting__head .setting__title {
    margin-right: auto; }
    @media only screen and (max-width: 767px) {
      .setting__head .setting__title {
        font-size: 32px; } }
  .setting__item > .setting__title {
    margin-bottom: 64px; }
    @media only screen and (max-width: 767px) {
      .setting__item > .setting__title {
        margin-bottom: 32px;
        font-size: 32px; } }
  .setting__item {
    display: none; }
  .setting__section:not(:last-child) {
    margin-bottom: 48px; }
  .setting__category {
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500; }
  .setting__fieldset > .field:not(:last-child) {
    margin-bottom: 32px; }
  .setting__row {
    display: flex;
    margin: 0 -10px; }
    @media only screen and (max-width: 767px) {
      .setting__row {
        display: block;
        margin: 0; } }
    .setting__row .field {
      flex: 0 0 calc(50% - 20px);
      width: calc(50% - 20px);
      margin: 0 10px; }
      @media only screen and (max-width: 767px) {
        .setting__row .field {
          width: 100%;
          margin: 0; } }
      @media only screen and (max-width: 767px) {
        .setting__row .field:not(:last-child) {
          margin-bottom: 20px; } }
    .setting__row:not(:last-child) {
      margin-bottom: 32px; }
  .setting__controls {
    display: flex;
    align-items: center;
    margin-top: 48px;
    padding-top: 48px;
    border-top: 1px solid #E6E8EC; }
    body.dark .setting__controls {
      border-color: #353945; }
    .setting__controls .setting__button {
      margin-right: 40px; }
  .setting__clear {
    display: flex;
    align-items: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    color: #777E90;
    transition: color .2s; }
    .setting__clear .icon {
      width: 16px;
      height: 16px;
      margin-right: 12px;
      fill: #777E90;
      transition: fill .2s; }
    .setting__clear:hover {
      color: #3B71FE; }
      .setting__clear:hover .icon {
        fill: #3B71FE; }
  .setting__box:not(:last-child) {
    margin-bottom: 64px;
    padding-bottom: 64px;
    border-bottom: 1px solid #E6E8EC; }
    @media only screen and (max-width: 767px) {
      .setting__box:not(:last-child) {
        margin-bottom: 32px;
        padding-bottom: 32px; } }
    body.dark .setting__box:not(:last-child) {
      border-color: #353945; }
  .setting__stage {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600;
    letter-spacing: -.01em; }
  .setting__line {
    display: flex;
    margin: 0 -48px; }
    @media only screen and (max-width: 767px) {
      .setting__line {
        display: block;
        margin: 0; } }
    .setting__line .setting__element {
      flex: 0 0 50%;
      padding: 0 48px; }
      @media only screen and (max-width: 767px) {
        .setting__line .setting__element {
          padding: 0; } }
      .setting__line .setting__element:first-child {
        border-right: 1px solid #E6E8EC; }
        @media only screen and (max-width: 767px) {
          .setting__line .setting__element:first-child {
            margin-bottom: 16px;
            border: none; } }
        body.dark .setting__line .setting__element:first-child {
          border-color: #353945; }
  .setting__group .setting__element:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E6E8EC; }
    body.dark .setting__group .setting__element:not(:last-child) {
      border-color: #353945; }
  .setting__element {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .setting__element {
        align-items: flex-start; } }
  .setting__details {
    flex-grow: 1; }
  .setting__label {
    margin-bottom: 4px;
    font-weight: 500; }
  .setting__text {
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
  .setting__element .setting__button {
    flex-shrink: 0;
    margin-left: 12px; }
    body.dark .setting__element .setting__button {
      box-shadow: inset 0 0 0 2px #353945; }
  .setting__top {
    display: flex;
    align-items: center;
    margin: 32px 0; }
  .setting__top .setting__category {
    margin: 0 auto 0 0; }
  .setting__cards {
    display: flex;
    align-items: center; }
  .setting__notification:not(:last-child) {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #E6E8EC; }
    body.dark .setting__notification:not(:last-child) {
      border-color: #353945; }
  .setting__main {
    display: flex;
    margin-bottom: 4px; }
  .setting__subtitle {
    margin-right: auto;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500; }
  .setting .switch {
    flex-shrink: 0;
    margin-left: 24px; }
  .setting__content {
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }

.flights .control {
  margin-bottom: 32px;
  padding: 0; }

.flights__sorting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 64px;
  padding-top: 32px;
  border-top: 1px solid #E6E8EC; }
  @media only screen and (max-width: 767px) {
    .flights__sorting {
      display: block;
      margin-bottom: 32px;
      padding-top: 0;
      border: none; } }
  body.dark .flights__sorting {
    border-color: #353945; }
  .flights__sorting .flights__button {
    display: none; }
    @media only screen and (max-width: 767px) {
      .flights__sorting .flights__button {
        display: flex;
        width: 100%; } }

@media only screen and (max-width: 1023px) {
  .flights .nav {
    display: none; } }

.flights__select {
  flex-shrink: 0;
  width: 256px; }
  @media only screen and (max-width: 767px) {
    .flights__select {
      width: 100%; } }
  @media only screen and (max-width: 767px) {
    .flights__select:not(:last-child) {
      margin-bottom: 16px; } }

.flights__row {
  display: flex; }
  @media only screen and (max-width: 767px) {
    .flights__row {
      display: block; } }

.flights__filters {
  flex-shrink: 0;
  width: 256px; }
  @media only screen and (max-width: 767px) {
    .flights__filters {
      display: none;
      width: 100%;
      padding-bottom: 40px; }
      .flights__filters.show {
        display: block; } }

.flights__box {
  padding-bottom: 48px;
  border-bottom: 1px solid #E6E8EC; }
  @media only screen and (max-width: 767px) {
    .flights__box {
      padding-bottom: 24px; } }
  body.dark .flights__box {
    border-color: #353945; }
  .flights__box:not(:last-child) {
    margin-bottom: 48px; }
    @media only screen and (max-width: 767px) {
      .flights__box:not(:last-child) {
        margin-bottom: 24px; } }

.flights .range:not(:last-child) {
  margin-bottom: 32px; }
  @media only screen and (max-width: 767px) {
    .flights .range:not(:last-child) {
      margin-bottom: 24px; } }

.flights__variants {
  display: flex;
  flex-direction: column; }
  .flights__variants .checkbox {
    width: 100%; }
    .flights__variants .checkbox:not(:last-child) {
      margin-bottom: 16px; }

.flights__reset {
  display: inline-flex;
  align-items: center;
  margin-top: 24px;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777E90;
  transition: color .2s; }
  .flights__reset .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: #777E90;
    transition: fill .2s; }
  .flights__reset:hover {
    color: #23262F; }
    .flights__reset:hover .icon {
      fill: #23262F; }
    body.dark .flights__reset:hover {
      color: #E6E8EC; }
      body.dark .flights__reset:hover .icon {
        fill: #E6E8EC; }

.flights__container {
  flex-grow: 1;
  padding-left: 80px; }
  @media only screen and (max-width: 1339px) {
    .flights__container {
      padding-left: 40px; } }
  @media only screen and (max-width: 1179px) {
    .flights__container {
      padding-left: 32px; } }
  @media only screen and (max-width: 767px) {
    .flights__container {
      margin: 0 -16px;
      padding: 0; } }

.flights .flight:not(:last-child) {
  margin-bottom: 24px; }

.flights__btns {
  margin-top: 40px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .flights__btns {
      margin-top: 32px; } }

.flights__btns .flights__button .loader {
  margin: 0 20px 0 4px; }

.range__label {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #B1B5C3; }

.range__indicators {
  display: flex;
  justify-content: space-between;
  margin-top: 8px; }

.range__text {
  font-weight: 500; }

.range__info {
  font-weight: 500;
  color: #777E90; }

.range__note {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.range_time .range__text {
  margin-top: 12px; }

.noUi-horizontal {
  height: 8px; }

.noUi-target {
  border: none;
  background: #E6E8EC;
  box-shadow: none; }
  body.dark .noUi-target {
    background: #353945; }

.noUi-connect {
  background: #3B71FE; }

.noUi-handle {
  border: none;
  box-shadow: none;
  background: #3B71FE;
  border-radius: 50%;
  cursor: pointer; }
  .noUi-handle:before, .noUi-handle:after {
    display: none; }

.noUi-horizontal .noUi-handle {
  right: -12px;
  top: -8px;
  width: 24px;
  height: 24px;
  border: 4px solid #FCFCFD; }

.noUi-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  padding: 4px 8px;
  background: #141416;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #FCFCFD;
  visibility: hidden;
  opacity: 0;
  transition: all .2s; }
  .noUi-tooltip:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #141416 transparent transparent transparent; }
  body.dark .noUi-tooltip {
    background: #353945; }
    body.dark .noUi-tooltip:before {
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 4px 4px 0 4px;
      border-color: #353945 transparent transparent transparent; }

.noUi-handle:hover .noUi-tooltip {
  visibility: visible;
  opacity: 1; }

.noUi-horizontal .noUi-tooltip {
  bottom: 24px; }

.range_time .noUi-horizontal {
  height: 2px; }

.range_time .noUi-handle {
  top: -11px; }

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -12px; }

.nav {
  display: flex; }
  .nav__link {
    display: flex;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90;
    transition: all .2s; }
    .nav__link .icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      fill: #777E90;
      transition: fill .2s; }
    .nav__link:hover {
      color: #353945; }
      .nav__link:hover .icon {
        fill: #353945; }
      body.dark .nav__link:hover {
        color: #E6E8EC; }
        body.dark .nav__link:hover .icon {
          fill: #E6E8EC; }
    .nav__link.active {
      background: #353945;
      color: #FCFCFD; }
      .nav__link.active .icon {
        fill: #FCFCFD; }
      body.dark .nav__link.active {
        background: #E6E8EC;
        color: #23262F; }
        body.dark .nav__link.active .icon {
          fill: #23262F; }
    .nav__link:not(:last-child) {
      margin-right: 8px; }

.flight {
  display: flex;
  align-items: flex-end;
  padding: 32px;
  border-radius: 24px;
  box-shadow: inset 0 0 0 1px #E6E8EC;
  transition: all .2s; }
  @media only screen and (max-width: 1179px) {
    .flight {
      display: block;
      padding: 24px; } }
  .flight:hover {
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08), inset 0 0 0 2px #FCFCFD;
    background: #F4F5F6; }
  body.dark .flight {
    box-shadow: inset 0 0 0 1px #353945; }
    body.dark .flight:hover {
      box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08);
      background: #23262F; }
  .flight__item {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 1023px) {
      .flight__item {
        display: block; } }
    .flight__item:not(:last-child) {
      margin-bottom: 24px; }
      @media only screen and (max-width: 1023px) {
        .flight__item:not(:last-child) {
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #E6E8EC; }
          body.dark .flight__item:not(:last-child) {
            border-color: #353945; } }
  .flight__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 160px;
    height: 80px;
    padding: 10px;
    border-radius: 8px;
    background: #F4F5F6; }
    @media only screen and (max-width: 1023px) {
      .flight__logo {
        width: 100%;
        height: 60px;
        margin-bottom: 12px; } }
    body.dark .flight__logo {
      background: #B1B5C3; }
    .flight__logo img {
      max-width: 100%;
      max-height: 100%; }
  .flight__details {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 0 20px; }
  .flight__box {
    flex-grow: 1;
    text-align: center; }
  .flight__title {
    margin-bottom: 3px;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600;
    letter-spacing: -.01em; }
  .flight__time {
    font-weight: 500;
    color: #777E90; }
  .flight__note {
    flex-shrink: 0;
    padding: 8px 7px 0;
    border-top: 1px solid #E6E8EC;
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
    body.dark .flight__note {
      border-color: #353945; }
  .flight__wrap {
    flex-grow: 1; }
  .flight__control {
    flex-shrink: 0;
    width: 180px; }
    @media only screen and (max-width: 1339px) {
      .flight__control {
        width: 160px; } }
    @media only screen and (max-width: 1179px) {
      .flight__control {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #E6E8EC; }
        body.dark .flight__control {
          border-color: #353945; } }
  .flight__info {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
    @media only screen and (max-width: 1179px) {
      .flight__info {
        margin: 0 auto 0 0; } }
    .flight__info .icon {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      fill: #777E90; }
  .flight__button {
    width: 100%;
    padding: 0 10px; }
    @media only screen and (max-width: 1179px) {
      .flight__button {
        flex-shrink: 0;
        width: 160px;
        margin-left: 24px; } }
    .flight__button:hover {
      box-shadow: inset 0 0 0 1px #3B71FE;
      background: #3B71FE;
      color: #FCFCFD; }
      .flight__button:hover .flight__price {
        display: none; }
      .flight__button:hover .flight__more {
        display: inline-flex; }
        .flight__button:hover .flight__more .icon {
          width: 16px;
          height: 16px; }
      body.dark .flight__button:hover {
        box-shadow: inset 0 0 0 1px #3B71FE;
        background: #3B71FE; }
  .flight__price {
    color: #58C27D; }
  .flight__more {
    display: none;
    align-items: center;
    color: #FCFCFD; }

.messages {
  position: relative;
  display: flex;
  padding-left: 396px; }
  @media only screen and (max-width: 1339px) {
    .messages {
      padding-left: 360px; } }
  @media only screen and (max-width: 1179px) {
    .messages {
      padding-left: 148px; } }
  .messages__users {
    position: fixed;
    top: 88px;
    left: 0;
    bottom: 0;
    width: 396px;
    padding: 105px 0 0;
    box-shadow: inset -1px 0 0 0 #E6E8EC; }
    @media only screen and (max-width: 1339px) {
      .messages__users {
        width: 360px;
        padding-top: 80px; } }
    @media only screen and (max-width: 1179px) {
      .messages__users {
        width: 148px; } }
    @media only screen and (max-width: 767px) {
      .messages__users {
        top: 112px;
        width: 100%; } }
    body.dark .messages__users {
      box-shadow: inset -1px 0 0 0 #23262F; }
  .messages__wrapper {
    position: relative;
    height: calc(100vh - 88px);
    flex-grow: 1;
    padding-bottom: 104px; }
    @media only screen and (max-width: 1339px) {
      .messages__wrapper {
        padding-bottom: 80px; } }
    @media only screen and (max-width: 767px) {
      .messages__wrapper {
        position: fixed;
        top: 112px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        height: auto;
        background: #FCFCFD;
        transform: translateX(100%);
        transition: transform .3s; }
        body.dark .messages__wrapper {
          background: #141416; }
        .messages__wrapper.show {
          transform: translateX(0); } }
  .messages .place {
    flex-shrink: 0;
    width: 496px;
    padding: 48px 48px 48px 0;
    height: calc(100vh - 88px);
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
    @media only screen and (max-width: 1339px) {
      .messages .place {
        width: 364px;
        padding: 32px 32px 32px 0; } }
    @media only screen and (max-width: 1023px) {
      .messages .place {
        display: none; } }
  .messages__toggle {
    position: absolute;
    top: 48px;
    left: 48px;
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600;
    letter-spacing: -.01em;
    color: #23262F;
    transition: color .2s; }
    @media only screen and (max-width: 1339px) {
      .messages__toggle {
        top: 24px;
        left: 32px; } }
    @media only screen and (max-width: 1179px) {
      .messages__toggle {
        left: 50%;
        transform: translateX(-50%);
        font-size: 0; } }
    @media only screen and (max-width: 767px) {
      .messages__toggle {
        left: 32px;
        transform: translateX(0);
        font-size: 24px; } }
    .messages__toggle .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      transition: fill .2s; }
      @media only screen and (max-width: 1179px) {
        .messages__toggle .icon {
          margin-right: 0; } }
      @media only screen and (max-width: 767px) {
        .messages__toggle .icon {
          margin-right: 8px; } }
    body.dark .messages__toggle {
      color: #FCFCFD; }
      body.dark .messages__toggle .icon {
        fill: #FCFCFD; }
    .messages__toggle:hover {
      color: #3B71FE; }
      .messages__toggle:hover .icon {
        fill: #3B71FE; }
  .messages__group {
    max-height: 100%;
    padding: 0 48px 48px;
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
    @media only screen and (max-width: 1339px) {
      .messages__group {
        padding: 0 32px 32px; } }
    @media only screen and (max-width: 1179px) {
      .messages__group {
        display: flex;
        align-items: center;
        flex-direction: column; } }
    @media only screen and (max-width: 767px) {
      .messages__group {
        display: block;
        padding: 0 32px 32px; } }
  .messages__item {
    display: flex;
    align-items: flex-start;
    padding: 24px 20px;
    border-radius: 16px;
    cursor: pointer;
    transition: all .2s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #23262F; }
    @media only screen and (max-width: 1179px) {
      .messages__item {
        padding: 8px;
        border-radius: 50%; } }
    @media only screen and (max-width: 767px) {
      .messages__item {
        padding: 24px 20px;
        border-radius: 16px; } }
    body.dark .messages__item {
      color: #FCFCFD; }
    .messages__item:not(:last-child) {
      margin-bottom: 12px; }
      @media only screen and (max-width: 1179px) {
        .messages__item:not(:last-child) {
          margin-bottom: 24px; } }
      @media only screen and (max-width: 767px) {
        .messages__item:not(:last-child) {
          margin-bottom: 12px; } }
    .messages__item:hover, .messages__item.active {
      box-shadow: 0px 40px 32px -8px rgba(15, 15, 15, 0.12); }
      body.dark .messages__item:hover, body.dark .messages__item.active {
        background: #23262F; }
  .messages__avatar {
    flex-shrink: 0;
    margin-right: 16px; }
    .messages__avatar img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%; }
  .messages__item .messages__avatar {
    width: 48px;
    height: 48px; }
    @media only screen and (max-width: 1179px) {
      .messages__item .messages__avatar {
        margin-right: 0; } }
    @media only screen and (max-width: 767px) {
      .messages__item .messages__avatar {
        margin-right: 16px; } }
  .messages__details {
    flex: 0 0 calc(100% - 64px);
    max-width: calc(100% - 64px); }
    @media only screen and (max-width: 1179px) {
      .messages__details {
        display: none; } }
    @media only screen and (max-width: 767px) {
      .messages__details {
        display: block; } }
  .messages__head {
    display: flex;
    margin-bottom: 4px; }
  .messages__man {
    margin-right: auto;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500; }
  .messages__rating {
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 50px;
    height: 24px;
    border-radius: 12px;
    background: #FCFCFD;
    box-shadow: inset 0 0 0 1px #E6E8EC;
    font-size: 12px;
    line-height: 1.66667;
    font-weight: 600; }
    body.dark .messages__rating {
      background: #23262F;
      box-shadow: inset 0 0 0 1px #353945; }
    .messages__rating .icon {
      width: 12px;
      height: 12px;
      margin-right: 4px;
      fill: #FFD166; }
  .messages__item .messages__date {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
  .messages__content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    line-height: 1.66667; }
  .messages__inner {
    max-height: 100%;
    padding: 48px 48px 40px;
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
    @media only screen and (max-width: 1339px) {
      .messages__inner {
        padding: 32px 24px; } }
    @media only screen and (max-width: 1023px) {
      .messages__inner {
        padding: 32px; } }
  .messages__back {
    display: none; }
    @media only screen and (max-width: 767px) {
      .messages__back {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        font-size: 24px;
        line-height: 1.33333;
        font-weight: 600;
        letter-spacing: -.01em; }
        .messages__back .icon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          fill: #23262F; }
        body.dark .messages__back {
          color: #FCFCFD; }
          body.dark .messages__back .icon {
            fill: #FCFCFD; } }
  .messages .subscription {
    position: absolute;
    left: 48px;
    right: 48px;
    bottom: 48px; }
    @media only screen and (max-width: 1339px) {
      .messages .subscription {
        left: 24px;
        right: 24px;
        bottom: 24px; } }
  .messages__wrapper .messages__date {
    margin-bottom: 24px;
    text-align: center;
    font-size: 12px;
    line-height: 1.66667;
    font-weight: 600; }
    @media only screen and (max-width: 1339px) {
      .messages__wrapper .messages__date {
        margin-bottom: 0; } }
  .messages__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .messages__author {
    display: flex;
    margin-top: 24px; }
    .messages__author .messages__text {
      margin-top: 0;
      background: #F4F5F6;
      color: #23262F; }
      body.dark .messages__author .messages__text {
        background: #23262F;
        color: #FCFCFD; }
  .messages__author .messages__avatar {
    width: 32px;
    height: 32px; }
  .messages__text {
    margin-top: 24px;
    margin-left: auto;
    padding: 16px 24px;
    border-radius: 32px;
    background: #3B71FE;
    color: #FCFCFD; }
  .messages__time {
    width: 100%;
    margin-top: 8px;
    text-align: right;
    font-size: 12px;
    line-height: 1.66667;
    font-weight: 600;
    color: #B1B5C3; }

.place__wrapper {
  border: 1px solid #E6E8EC;
  border-radius: 24px; }
  body.dark .place__wrapper {
    border-color: #23262F; }

.place__preview {
  position: relative;
  padding: 3px; }
  .place__preview img {
    width: 100%;
    border-radius: 20px; }
    @media only screen and (max-width: 1339px) {
      .place__preview img {
        height: 300px;
        object-fit: cover; } }
  .place__preview > .place__button {
    position: absolute;
    top: 11px;
    right: 11px; }
    .place__preview > .place__button .icon {
      width: 16px;
      height: 16px; }

.place__body {
  padding: 40px 32px 24px; }
  @media only screen and (max-width: 1339px) {
    .place__body {
      padding: 32px 24px; } }

.place__title {
  margin-bottom: 12px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em; }

.place__profile {
  display: flex;
  align-items: center;
  margin-bottom: 24px; }
  .place__profile span {
    margin-right: 4px;
    color: #777E90; }

.place__avatar {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px; }
  .place__avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; }

.place__name {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.place__description {
  margin-bottom: 32px;
  padding: 8px;
  border-radius: 20px;
  background: #F4F5F6; }
  body.dark .place__description {
    background: #23262F; }
  .place__description_flex {
    display: flex;
    flex-wrap: wrap; }
    @media only screen and (max-width: 1339px) {
      .place__description_flex {
        display: block; } }
    .place__description_flex .place__item {
      flex: 0 0 50%; }
      .place__description_flex .place__item:nth-child(2n) {
        position: relative; }
        .place__description_flex .place__item:nth-child(2n):before {
          content: "";
          position: absolute;
          top: 12px;
          left: 0;
          bottom: 12px;
          width: 1px;
          background: #E6E8EC; }
          @media only screen and (max-width: 1339px) {
            .place__description_flex .place__item:nth-child(2n):before {
              top: 0;
              right: 0;
              bottom: auto;
              width: auto;
              height: 1px; } }
          body.dark .place__description_flex .place__item:nth-child(2n):before {
            background: #353945; }

.place__item {
  display: flex;
  align-items: center;
  padding: 12px; }

.place__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 12px; }
  .place__icon .icon {
    width: 24px;
    height: 24px;
    fill: #B1B5C3; }

.place__category {
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.place__subtitle {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.place__parameters {
  display: flex;
  flex-wrap: wrap;
  margin: -10px 0 32px -16px; }

.place__parameter {
  display: flex;
  align-items: center;
  margin: 10px 0 0 16px;
  color: #777E90; }
  .place__parameter .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: #777E90; }

.place__text {
  margin: 32px 0;
  padding-top: 32px;
  border-top: 1px solid #E6E8EC;
  color: #777E90; }
  @media only screen and (max-width: 1339px) {
    .place__text {
      display: none; } }
  body.dark .place__text {
    border-color: #353945; }

.upload .control {
  margin-bottom: 80px; }
  @media only screen and (max-width: 1023px) {
    .upload .control {
      margin-bottom: 48px; } }

.upload__inner {
  display: flex;
  align-items: flex-start; }
  @media only screen and (max-width: 1023px) {
    .upload__inner {
      display: block; } }

.upload__wrapper {
  flex: 0 0 calc(100% - 352px);
  width: calc(100% - 352px);
  padding-right: 128px; }
  @media only screen and (max-width: 1339px) {
    .upload__wrapper {
      padding-right: 64px; } }
  @media only screen and (max-width: 1179px) {
    .upload__wrapper {
      flex: 0 0 calc(100% - 304px);
      width: calc(100% - 304px);
      padding-right: 32px; } }
  @media only screen and (max-width: 1023px) {
    .upload__wrapper {
      width: 100%;
      padding: 0; } }

.upload__preview {
  flex-shrink: 0;
  width: 352px; }
  @media only screen and (max-width: 1179px) {
    .upload__preview {
      width: 304px; } }
  @media only screen and (max-width: 1023px) {
    .upload__preview {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 48px;
      background: rgba(20, 20, 22, 0.9);
      overflow: auto;
      z-index: 999;
      visibility: hidden;
      opacity: 0;
      transition: all .4s; }
      body.dark .upload__preview {
        background: rgba(35, 38, 47, 0.9); }
      .upload__preview.visible {
        visibility: visible;
        opacity: 1; } }
  @media only screen and (max-width: 767px) {
    .upload__preview {
      padding: 32px 16px; } }

.upload__head {
  display: flex;
  align-items: center;
  margin-bottom: 40px; }
  @media only screen and (max-width: 1179px) {
    .upload__head {
      flex-direction: column-reverse;
      align-items: flex-start; } }
  @media only screen and (max-width: 767px) {
    .upload__head {
      margin-bottom: 32px; } }
  .upload__head .upload__button {
    flex-shrink: 0;
    margin-left: 24px; }
    @media only screen and (max-width: 1179px) {
      .upload__head .upload__button {
        margin: 0 0 16px; } }
    body.dark .upload__head .upload__button {
      box-shadow: inset 0 0 0 2px #353945; }

.upload__title {
  margin-right: auto; }
  @media only screen and (max-width: 767px) {
    .upload__title {
      font-size: 32px; } }

.upload__list {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #E6E8EC; }
  @media only screen and (max-width: 767px) {
    .upload__list {
      margin-bottom: 32px;
      padding-bottom: 32px; } }
  body.dark .upload__list {
    border-color: #353945; }

.upload__item:not(:last-child) {
  margin-bottom: 40px; }
  @media only screen and (max-width: 767px) {
    .upload__item:not(:last-child) {
      margin-bottom: 32px; } }

.upload__note {
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.upload__file {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 182px;
  margin-top: 16px;
  border-radius: 16px;
  overflow: hidden;
  background: #F4F5F6; }
  body.dark .upload__file {
    background: #23262F; }

.upload__input {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 400px;
  opacity: 0; }

.upload__icon {
  margin-bottom: 10px; }
  .upload__icon .icon {
    width: 24px;
    height: 24px;
    fill: #777E90; }

.upload__format {
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.upload__category {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.upload__item .upload__button .icon {
  width: 16px;
  height: 16px; }

body.dark .upload__item .upload__button {
  box-shadow: inset 0 0 0 2px #353945; }

.upload__item:not(:first-child) .upload__category {
  margin-bottom: 24px; }

.upload__fieldset .field:not(:last-child) {
  margin-bottom: 24px; }
  @media only screen and (max-width: 767px) {
    .upload__fieldset .field:not(:last-child) {
      margin-bottom: 20px; } }

.upload__label {
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #B1B5C3; }

.upload__row {
  display: flex;
  margin: 0 -10px; }
  @media only screen and (max-width: 767px) {
    .upload__row {
      display: block;
      margin: 0; } }
  .upload__row:not(:last-child) {
    margin-bottom: 24px; }
    @media only screen and (max-width: 767px) {
      .upload__row:not(:last-child) {
        margin-bottom: 20px; } }

.upload__col {
  margin: 0 10px; }
  .upload__col_w30 {
    flex: 0 0 calc(30% - 20px);
    width: calc(30% - 20px); }
    @media only screen and (max-width: 767px) {
      .upload__col_w30 {
        width: 100%;
        margin: 0; }
        .upload__col_w30:not(:last-child) {
          margin-bottom: 20px; } }
  .upload__col_w33 {
    flex: 0 0 calc(33.333% - 20px);
    width: calc(33.333% - 20px); }
    @media only screen and (max-width: 767px) {
      .upload__col_w33 {
        width: 100%;
        margin: 0; }
        .upload__col_w33:not(:last-child) {
          margin-bottom: 20px; } }
  .upload__col_w50 {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px); }
    @media only screen and (max-width: 767px) {
      .upload__col_w50 {
        width: 100%;
        margin: 0; }
        .upload__col_w50:not(:last-child) {
          margin-bottom: 20px; } }
  .upload__col_w70 {
    flex: 0 0 calc(70% - 20px);
    width: calc(70% - 20px); }
    @media only screen and (max-width: 767px) {
      .upload__col_w70 {
        width: 100%;
        margin: 0; }
        .upload__col_w70:not(:last-child) {
          margin-bottom: 20px; } }

.upload__line {
  display: flex;
  height: 48px;
  box-shadow: inset 0 0 0 2px #E6E8EC;
  border-radius: 12px;
  align-items: center; }
  body.dark .upload__line {
    box-shadow: inset 0 0 0 2px #353945; }

.upload__cell {
  flex: 1; }
  .upload__cell .field {
    width: 100%; }
  .upload__cell:not(:last-child) {
    border-right: 1px solid #E6E8EC; }
    body.dark .upload__cell:not(:last-child) {
      border-color: #353945; }

.upload__foot {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .upload__foot {
      display: block;
      text-align: center; } }
  .upload__foot .upload__button .icon {
    width: 10px;
    height: 10px; }
  @media only screen and (max-width: 767px) {
    .upload__foot .upload__button {
      width: 100%; } }
  .upload__foot .upload__button:first-child {
    display: none; }
    @media only screen and (max-width: 1023px) {
      .upload__foot .upload__button:first-child {
        display: inline-block; } }
  .upload__foot .upload__button:not(:last-child) {
    margin-right: 24px; }
    @media only screen and (max-width: 767px) {
      .upload__foot .upload__button:not(:last-child) {
        margin: 0 0 12px; } }

.upload__saving {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1; }
  .upload__saving .loader {
    margin-left: 24px; }

@media only screen and (max-width: 1023px) {
  .upload__wrap {
    position: relative;
    max-width: 455px;
    margin: auto;
    padding: 24px;
    border-radius: 24px;
    background: #FCFCFD; } }

@media only screen and (max-width: 767px) {
  .upload__wrap {
    padding: 24px 16px; } }

body.dark .upload__wrap {
  background: #141416; }

.upload__subtitle {
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em; }

.upload__close {
  display: none;
  position: absolute;
  top: 20px;
  right: 24px;
  width: 40px;
  height: 40px;
  background: #FCFCFD;
  border: 2px solid #E6E8EC;
  border-radius: 50%;
  font-size: 0; }
  @media only screen and (max-width: 1023px) {
    .upload__close {
      display: inline-block; } }
  .upload__close .icon {
    width: 14px;
    height: 14px;
    fill: #23262F;
    transition: transform .2s; }
  body.dark .upload__close {
    border-color: #353945;
    background: #141416; }
    body.dark .upload__close .icon {
      fill: #FCFCFD; }

.upload .card {
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08); }

.interest__head {
  margin-bottom: 64px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .interest__head {
      margin-bottom: 32px; } }

.interest__category {
  display: inline-block;
  margin-bottom: 12px;
  padding: 8px 8px 6px;
  box-shadow: inset 0 0 0 2px #58C27D;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #58C27D; }
  @media only screen and (max-width: 767px) {
    .interest__category {
      display: none; } }

.interest__title {
  margin-bottom: 48px; }
  @media only screen and (max-width: 767px) {
    .interest__title {
      margin-bottom: 32px; } }

.interest .nav {
  justify-content: center; }
  @media only screen and (max-width: 767px) {
    .interest .nav {
      display: none; } }

.interest .select {
  width: 100%;
  text-align: left; }

.interest__wrapper {
  margin: 0 -12px; }
  @media only screen and (max-width: 767px) {
    .interest__wrapper {
      margin: 0 -8px; } }

.interest__slider {
  padding-bottom: 88px;
  visibility: hidden; }
  @media only screen and (max-width: 767px) {
    .interest__slider {
      padding-bottom: 72px; } }
  .interest__slider.slick-initialized {
    visibility: visible; }

.interest .slick-list {
  overflow: visible; }

.interest .slick-track {
  display: flex; }

.interest .slick-slide {
  height: auto;
  margin: 0 12px;
  opacity: 0;
  visibility: hidden;
  transition: all .4s; }
  @media only screen and (max-width: 767px) {
    .interest .slick-slide {
      margin: 0 8px; } }
  .interest .slick-slide.slick-active {
    opacity: 1;
    visibility: visible; }

.interest .slick-arrow {
  bottom: 0;
  width: 24px;
  height: 24px; }
  .interest .slick-arrow:hover {
    box-shadow: none; }
    .interest .slick-arrow:hover path {
      fill: #23262F; }

.interest .slick-prev {
  left: calc(50% - 44px); }

.interest .slick-next {
  right: calc(50% - 44px); }

.popup {
  width: 100%;
  margin: auto;
  background: #FCFCFD;
  border-radius: 16px;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08);
  z-index: 2; }
  body.dark .popup {
    background: #141416; }
  .popup .mfp-close {
    position: absolute;
    top: -16px;
    right: -16px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    line-height: normal;
    opacity: 1;
    border: 1px solid transparent;
    box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.1);
    background: #FCFCFD url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='15' fill='none' viewBox='0 0 14 15'%3E%3Cpath fill-rule='evenodd' d='M.293 1.289a1 1 0 0 1 1.414 0L7 6.582l5.293-5.293a1 1 0 0 1 1.414 1.414L8.414 7.996l5.293 5.293a1 1 0 0 1-1.414 1.414L7 9.41l-5.293 5.293a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414l5.293-5.293L.293 2.703a1 1 0 0 1 0-1.414z' fill='%23777E90'/%3E%3C/svg%3E") no-repeat 50% 48%/12px auto;
    font-size: 0;
    transition: all .2s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
    body.dark .popup .mfp-close {
      border-color: #353945;
      background-color: #23262F;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23FCFCFD' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E"); }
    .popup .mfp-close:hover {
      box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.2);
      border-color: #E6E8EC; }
      body.dark .popup .mfp-close:hover {
        border-color: #FCFCFD; }
  .popup_login {
    max-width: 544px;
    padding: 72px 96px; }
    @media only screen and (max-width: 767px) {
      .popup_login {
        padding: 32px 24px; } }
  .popup_avatars {
    max-width: 663px;
    padding: 8px 8px 32px; }

.mfp-container {
  padding: 20px; }
  @media only screen and (max-width: 767px) {
    .mfp-container {
      padding: 20px 16px; } }

@media (max-width: 900px) {
  .mfp-container {
    padding: 20px 16px; } }

.mfp-bg {
  background: rgba(20, 20, 22, 0.3); }
  body.dark .mfp-bg {
    background: rgba(35, 38, 47, 0.8); }

.mfp-zoom-in .popup {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8); }

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.2s ease-out; }

.mfp-zoom-in.mfp-ready .popup {
  opacity: 1;
  transform: scale(1); }

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 1; }

.mfp-zoom-in.mfp-removing .popup {
  transform: scale(0.8);
  opacity: 0; }

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0; }

.login__item {
  display: none; }
  .login__item:first-child {
    display: block; }

.login__title {
  text-align: center; }

.login__info {
  margin-top: 12px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  color: #777E90; }

.login__btns {
  display: flex;
  justify-content: center;
  margin-top: 32px; }
  .login__btns .login__button .icon {
    width: 16px;
    height: 16px; }
  .login__btns .login__button:not(:last-child) {
    margin-right: 12px; }

.login__note {
  margin-top: 32px;
  text-align: center;
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.login .subscription {
  margin-top: 32px; }

.login__form {
  margin-top: 32px; }
  .login__form > .button {
    width: 100%;
    margin-top: 12px; }
  .login__form .field__input {
    border-radius: 24px; }

.login__foot {
  margin-top: 32px;
  text-align: center;
  font-size: 12px;
  line-height: 1.66667;
  color: #353945; }
  body.dark .login__foot {
    color: #E6E8EC; }

.login__link, .login__password {
  font-weight: 600;
  transition: color .2s; }

.login__link {
  color: #3B71FE; }
  .login__link:hover {
    color: #084cfe; }

.login__password {
  color: #353945; }
  .login__password:hover {
    color: #141416; }
  body.dark .login__password {
    color: #E6E8EC; }
    body.dark .login__password:hover {
      color: #3B71FE; }

.login__variants {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; }

.login .radio:not(:last-child) {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E6E8EC; }
  body.dark .login .radio:not(:last-child) {
    border-color: #353945; }

.login__code {
  display: flex;
  justify-content: center; }

.login__number {
  flex-shrink: 0;
  width: 48px; }
  .login__number input {
    width: 100%;
    height: 64px;
    border-radius: 8px;
    border: 1px solid #F4F5F6;
    background: #F4F5F6;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 32px;
    font-weight: 600;
    color: #23262F;
    transition: all .2s; }
    .login__number input:focus {
      border-color: #E6E8EC;
      background: #FCFCFD; }
    body.dark .login__number input {
      background: #23262F;
      border-color: #23262F;
      color: #FCFCFD; }
      body.dark .login__number input:focus {
        background: none;
        border-color: #353945; }
  .login__number:not(:last-child) {
    margin-right: 16px; }

.avatars__file {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 342px;
  padding: 32px;
  border-radius: 16px;
  border: 2px dashed #B1B5C3;
  overflow: hidden;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .avatars__file {
      height: 280px; } }
  body.dark .avatars__file {
    background: #23262F;
    border: 2px dashed #353945; }

.avatars__load {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 400px;
  opacity: 0; }

.avatars__icon {
  margin-bottom: 24px; }
  .avatars__icon .icon {
    width: 48px;
    height: 48px;
    fill: #3B71FE; }

.avatars__format {
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em; }
  @media only screen and (max-width: 767px) {
    .avatars__format {
      font-size: 18px; } }

.avatars__note {
  font-size: 16px;
  line-height: 1.5;
  color: #777E90; }
  @media only screen and (max-width: 767px) {
    .avatars__note {
      margin-top: 8px;
      font-size: 14px; } }

.avatars__gallery {
  margin-top: 32px; }

.avatars__info {
  margin-bottom: 16px;
  text-align: center;
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.avatars__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -24px -16px 0; }
  @media only screen and (max-width: 767px) {
    .avatars__list {
      margin: -16px -8px 0; } }

.avatars__item {
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  margin: 24px 16px 0; }
  @media only screen and (max-width: 767px) {
    .avatars__item {
      width: 40px;
      height: 40px;
      margin: 16px 8px 0; } }
  .avatars__item img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; }

.datepicker {
  position: relative; }
  .datepicker__list {
    display: flex; }
  .datepicker__item {
    position: relative;
    height: 96px; }
    @media only screen and (max-width: 1023px) {
      .datepicker__item {
        height: 64px; } }
  .datepicker__list .datepicker__item {
    flex: 0 0 50%;
    width: 50%; }
  .datepicker__input {
    width: 100%;
    height: 100%;
    padding: 0 16px 24px 56px;
    border-radius: 16px;
    background: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600;
    letter-spacing: -.01em;
    color: #23262F;
    cursor: pointer; }
    @media only screen and (max-width: 1339px) {
      .datepicker__input {
        padding-right: 0; } }
    @media only screen and (max-width: 1179px) {
      .datepicker__input {
        padding-left: 48px;
        font-size: 18px; } }
    @media only screen and (max-width: 1023px) {
      .datepicker__input {
        padding: 0 0 16px 0; } }
    @media only screen and (max-width: 767px) {
      .datepicker__input {
        padding: 0 0 16px 32px; } }
    .datepicker__input::placeholder {
      color: #23262F; }
    body.dark .datepicker__input {
      color: #FCFCFD; }
      body.dark .datepicker__input::placeholder {
        color: #FCFCFD; }
  .datepicker__icon {
    position: absolute;
    top: 20px;
    left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    pointer-events: none; }
    @media only screen and (max-width: 1179px) {
      .datepicker__icon {
        left: 10px; } }
    @media only screen and (max-width: 1023px) {
      .datepicker__icon {
        display: none; } }
    @media only screen and (max-width: 767px) {
      .datepicker__icon {
        top: 8px;
        left: 0;
        display: flex;
        width: 24px; } }
    .datepicker__icon .icon {
      width: 24px;
      height: 24px;
      fill: #B1B5C3; }
      @media only screen and (max-width: 1179px) {
        .datepicker__icon .icon {
          width: 20px;
          height: 20px; } }
  .datepicker__description {
    position: absolute;
    left: 56px;
    bottom: 20px;
    pointer-events: none;
    font-size: 16px;
    line-height: 1.5;
    color: #777E90; }
    @media only screen and (max-width: 1179px) {
      .datepicker__description {
        left: 48px;
        font-size: 14px; } }
    @media only screen and (max-width: 1023px) {
      .datepicker__description {
        bottom: 4px;
        left: 0; } }
    @media only screen and (max-width: 767px) {
      .datepicker__description {
        left: 32px; } }
  .datepicker_small .datepicker__item {
    height: 72px; }
    @media only screen and (max-width: 1023px) {
      .datepicker_small .datepicker__item {
        height: 64px; } }
  .datepicker_small .datepicker__input {
    padding-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .datepicker_small .datepicker__icon {
      top: 50%;
      transform: translateY(-50%); } }
  .datepicker_down .date-picker-wrapper {
    top: calc(100% + 16px) !important;
    bottom: auto !important; }
    @media only screen and (max-width: 767px) {
      .datepicker_down .date-picker-wrapper {
        top: 100% !important;
        bottom: auto !important; } }

.date-picker-wrapper {
  top: auto !important;
  left: 50% !important;
  bottom: calc(100% + 16px) !important;
  transform: translateX(-50%);
  padding: 0;
  border: none;
  border-radius: 24px;
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  background: #FCFCFD;
  z-index: 20;
  overflow: hidden;
  font-family: 'Poppins', sans-serif; }
  @media only screen and (max-width: 767px) {
    .date-picker-wrapper {
      bottom: 100% !important;
      width: 262px !important;
      padding: 0 19px; } }
  body.dark .date-picker-wrapper {
    background: #141416; }
  .date-picker-wrapper.single-month .month-wrapper {
    width: 252px !important;
    padding: 0 19px; }
    @media only screen and (max-width: 767px) {
      .date-picker-wrapper.single-month .month-wrapper {
        margin: 0 5px;
        padding: 0; } }
    .date-picker-wrapper.single-month .month-wrapper table {
      width: 100% !important; }
  @media only screen and (min-width: 768px) {
    .date-picker-wrapper.two-months .month-wrapper {
      display: flex;
      width: 600px !important; }
      .date-picker-wrapper.two-months .month-wrapper table {
        flex: 0 0 calc(50% - 48px);
        width: calc(50% - 48px);
        margin: 0 24px; } }
  .date-picker-wrapper.single-date .day {
    border-radius: 50% !important; }
  .date-picker-wrapper.single-date .day.first-date-selected:before,
  .date-picker-wrapper.single-date .day.last-date-selected:before {
    display: none; }
  .date-picker-wrapper .month-wrapper {
    background: none;
    border: none;
    box-shadow: none; }
    .date-picker-wrapper .month-wrapper table {
      display: block; }
      @media only screen and (max-width: 767px) {
        .date-picker-wrapper .month-wrapper table {
          width: 100%; } }
      .date-picker-wrapper .month-wrapper table thead,
      .date-picker-wrapper .month-wrapper table tbody {
        display: block;
        width: 100%; }
      .date-picker-wrapper .month-wrapper table tr {
        display: flex;
        align-items: center;
        height: 36px; }
      .date-picker-wrapper .month-wrapper table th, .date-picker-wrapper .month-wrapper table td {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex: 1;
        font-weight: 500; }
      .date-picker-wrapper .month-wrapper table thead tr:first-child {
        height: 36px; }
        .date-picker-wrapper .month-wrapper table thead tr:first-child th:first-child, .date-picker-wrapper .month-wrapper table thead tr:first-child th:nth-child(3) {
          flex: 0 0 24px; }
      .date-picker-wrapper .month-wrapper table .week-name,
      .date-picker-wrapper .month-wrapper table .caption {
        height: auto; }
      .date-picker-wrapper .month-wrapper table .week-name {
        height: 40px; }
        .date-picker-wrapper .month-wrapper table .week-name th {
          height: auto; }
      .date-picker-wrapper .month-wrapper table .day {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        padding: 0;
        transition: background .2s, color .2s; }
        .date-picker-wrapper .month-wrapper table .day.valid.toMonth {
          color: #23262F; }
          body.dark .date-picker-wrapper .month-wrapper table .day.valid.toMonth {
            color: #FCFCFD; }
        .date-picker-wrapper .month-wrapper table .day:hover {
          background: #E6E8EC;
          color: #23262F !important; }
          body.dark .date-picker-wrapper .month-wrapper table .day:hover {
            color: #23262F !important; }
        .date-picker-wrapper .month-wrapper table .day.checked {
          background: #E6E8EC;
          border-radius: 0; }
          body.dark .date-picker-wrapper .month-wrapper table .day.checked {
            color: #23262F !important; }
        .date-picker-wrapper .month-wrapper table .day.hovering {
          border-radius: 0;
          background: #E6E8EC !important; }
          body.dark .date-picker-wrapper .month-wrapper table .day.hovering {
            color: #23262F !important; }
        .date-picker-wrapper .month-wrapper table .day.real-today {
          background: #3B71FE !important;
          color: #FCFCFD !important; }
          body.dark .date-picker-wrapper .month-wrapper table .day.real-today {
            color: #FCFCFD !important; }
        .date-picker-wrapper .month-wrapper table .day.day.real-today.checked, .date-picker-wrapper .month-wrapper table .day.day.real-today.hovering {
          background: #3B71FE; }
        .date-picker-wrapper .month-wrapper table .day.first-date-selected, .date-picker-wrapper .month-wrapper table .day.last-date-selected {
          position: relative;
          border-radius: 50% !important;
          background: #23262F !important;
          color: #FCFCFD !important; }
          body.dark .date-picker-wrapper .month-wrapper table .day.first-date-selected, body.dark .date-picker-wrapper .month-wrapper table .day.last-date-selected {
            background: #FCFCFD !important;
            color: #23262F !important; }
          .date-picker-wrapper .month-wrapper table .day.first-date-selected:before, .date-picker-wrapper .month-wrapper table .day.last-date-selected:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: -1;
            width: 50%;
            background: #E6E8EC; }
            body.dark .date-picker-wrapper .month-wrapper table .day.first-date-selected:before, body.dark .date-picker-wrapper .month-wrapper table .day.last-date-selected:before {
              background: #FCFCFD; }
        .date-picker-wrapper .month-wrapper table .day.first-date-selected:before {
          right: 0; }
        .date-picker-wrapper .month-wrapper table .day.last-date-selected:before {
          left: 0; }
  .date-picker-wrapper .month-name {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    text-transform: capitalize;
    color: #23262F; }
    body.dark .date-picker-wrapper .month-name {
      color: #FCFCFD; }
  .date-picker-wrapper .month-element:not(:last-child) {
    margin-right: 5px; }
  .date-picker-wrapper .week-name {
    font-size: 14px;
    color: #777E90; }
    .date-picker-wrapper .week-name th {
      font-weight: 500;
      text-transform: capitalize; }
  .date-picker-wrapper .gap {
    display: none; }
  .date-picker-wrapper table .caption .next:hover, .date-picker-wrapper table .caption .prev:hover {
    background: none; }
    .date-picker-wrapper table .caption .next:hover path,
    .date-picker-wrapper table .caption .prev:hover path {
      fill: #3B71FE; }
  .date-picker-wrapper .footer {
    display: none; }

.guests {
  position: relative;
  width: 107px;
  height: 40px; }
  .guests.active {
    overflow: visible; }
    .guests.active .guests__head .icon {
      transform: rotate(180deg); }
    .guests.active .guests__body {
      visibility: visible;
      opacity: 1; }
  .guests__head {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding-left: 4px;
    border-radius: 20px;
    box-shadow: inset 0 0 0 2px #E6E8EC;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    user-select: none;
    transition: all .2s;
    cursor: pointer; }
    body.dark .guests__head {
      box-shadow: inset 0 0 0 2px #777E90; }
    .guests__head .icon {
      width: 20px;
      height: 20px;
      margin-left: 4px;
      fill: #777E90;
      transition: transform .2s; }
  .guests__body {
    position: absolute;
    bottom: calc(100% + 4px);
    left: 0;
    right: 0;
    z-index: 5;
    border-radius: 24px;
    box-shadow: inset 0 0 0 2px #E6E8EC;
    background: #FCFCFD;
    visibility: hidden;
    opacity: 0;
    transition: all .2s; }
    body.dark .guests__body {
      background: #23262F;
      box-shadow: inset 0 0 0 2px #777E90; }
  .guests .counter {
    width: 100%;
    height: 39px;
    padding: 1px 12px 0; }

.destinations__inner {
  position: relative; }

.destinations__head {
  margin-bottom: 48px;
  padding-right: 120px; }
  @media only screen and (max-width: 767px) {
    .destinations__head {
      padding-right: 0; } }

.destinations__wrapper {
  margin: 0 -16px; }

.destinations__item {
  display: block;
  color: #23262F; }
  body.dark .destinations__item {
    color: #FCFCFD; }
  .destinations__item:hover .destinations__preview img {
    transform: scale(1.1); }

.destinations__preview {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 24px; }
  .destinations__preview:before {
    content: "";
    display: block;
    padding-bottom: 72.7%; }
    @media only screen and (max-width: 1179px) {
      .destinations__preview:before {
        padding-bottom: 96%; } }
    @media only screen and (max-width: 767px) {
      .destinations__preview:before {
        padding-bottom: 82%; } }
  .destinations__preview img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s; }

.destinations__category {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 2; }

.destinations__subtitle {
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -.01em; }

.destinations__content {
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

.destinations__button {
  margin-top: 48px; }
  .destinations__button .icon {
    width: 16px;
    height: 16px; }

.destinations__list {
  display: flex;
  flex-wrap: wrap;
  margin: -48px -16px 0; }
  @media only screen and (max-width: 767px) {
    .destinations__list {
      display: block;
      margin: -32px 0 0; } }

.destinations__list .destinations__item {
  flex: 0 0 calc(33.333% - 32px);
  width: calc(33.333% - 32px);
  margin: 48px 16px 0; }
  @media only screen and (max-width: 1023px) {
    .destinations__list .destinations__item {
      flex: 0 0 calc(50% - 32px);
      width: calc(50% - 32px); } }
  @media only screen and (max-width: 767px) {
    .destinations__list .destinations__item {
      width: 100%;
      margin: 32px 0 0; } }
  @media only screen and (max-width: 767px) {
    .destinations__list .destinations__item:nth-child(n+5) {
      display: none; } }
  @media only screen and (max-width: 1023px) {
    .destinations__list .destinations__item:nth-child(n+9) {
      display: none; } }
  @media only screen and (max-width: 767px) {
    .destinations__list .destinations__item:nth-child(n+9) {
      display: block; } }

.destinations__slider {
  position: static;
  visibility: hidden; }
  @media only screen and (max-width: 767px) {
    .destinations__slider {
      position: relative;
      padding-bottom: 88px; } }
  .destinations__slider.slick-initialized {
    visibility: visible; }

.destinations .slick-list {
  overflow: visible; }
  @media only screen and (max-width: 474px) {
    .destinations .slick-list {
      margin: 0 -246px 0 0; } }

.destinations .slick-slide {
  padding: 0 16px; }

.destinations .slick-arrow {
  top: 8px; }
  @media only screen and (max-width: 767px) {
    .destinations .slick-arrow {
      top: auto;
      bottom: 0; } }

.destinations .slick-prev {
  right: 48px; }
  @media only screen and (max-width: 1023px) {
    .destinations .slick-prev {
      right: auto;
      left: 16px; } }

.destinations .slick-next {
  right: 0; }
  @media only screen and (max-width: 767px) {
    .destinations .slick-next {
      right: auto;
      left: 64px; } }

.services__head {
  margin-bottom: 92px;
  text-align: center; }
  @media only screen and (max-width: 1023px) {
    .services__head {
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .services__head {
      margin-bottom: 48px; } }

.services .stage {
  margin-bottom: 8px; }

.services__title {
  margin-bottom: 32px; }
  @media only screen and (max-width: 767px) {
    .services__title {
      font-size: 32px; } }

.services__list {
  display: flex;
  margin: 0 -12px; }
  @media only screen and (max-width: 1023px) {
    .services__list {
      display: block; } }

.services__item {
  flex: 0 0 calc(33.333% - 24px);
  width: calc(33.333% - 24px);
  margin: 0 12px;
  padding: 64px 32px;
  border-radius: 20px;
  background: #F4F5F6; }
  @media only screen and (max-width: 1023px) {
    .services__item {
      width: 100%;
      margin: 0; } }
  @media only screen and (max-width: 767px) {
    .services__item {
      padding: 48px 24px; } }
  body.dark .services__item {
    background: #23262F; }
  @media only screen and (max-width: 1023px) {
    .services__item:not(:last-child) {
      margin-bottom: 24px; } }

.services__icon {
  display: flex;
  align-items: center;
  height: 48px;
  margin-bottom: 32px; }
  .services__icon img {
    max-height: 100%; }

.services__subtitle {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500; }

.services__content {
  color: #777E90; }

.brands__wrap {
  overflow: hidden; }

.brands__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1px -1px 0; }
  @media only screen and (max-width: 767px) {
    .brands__list {
      display: block; } }

.brands__item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 33.333%;
  width: 33.333%;
  height: 150px;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: #E6E8EC; }
  @media only screen and (max-width: 767px) {
    .brands__item {
      width: 100%;
      height: 112px; } }
  body.dark .brands__item {
    border-color: #353945; }
  .brands__item img {
    max-width: 166px;
    max-height: 64px; }

.faq1__title {
  margin-bottom: 48px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .faq1__title {
      margin-bottom: 40px; } }

.faq1 .nav {
  justify-content: center;
  margin-bottom: 40px; }
  @media only screen and (max-width: 767px) {
    .faq1 .nav {
      display: none; } }

.faq1 .select {
  display: none;
  text-align: left; }
  @media only screen and (max-width: 767px) {
    .faq1 .select {
      display: block;
      margin-bottom: 24px; } }

.faq1__list {
  max-width: 546px;
  margin: 0 auto; }

.faq1__item:hover .faq1__head {
  color: #3B71FE; }

.faq1__item:hover .faq1__number {
  color: #3B71FE; }

.faq1__item.active .faq1__number {
  color: #3B71FE; }

.faq1__item.active .faq1__arrow .icon {
  transform: rotate(180deg); }

.faq1__item:not(:last-child) .faq1__head {
  border-bottom: 1px solid #E6E8EC; }
  body.dark .faq1__item:not(:last-child) .faq1__head {
    border-color: #353945; }

.faq1__head {
  position: relative;
  display: flex;
  align-items: center;
  padding: 24px 0;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  cursor: pointer;
  transition: color .2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.faq1__number {
  flex-shrink: 0;
  width: 40px;
  color: #777E90;
  transition: color .2s; }

.faq1__subtitle {
  flex-grow: 1; }

.faq1__arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  margin-left: 16px; }
  .faq1__arrow .icon {
    width: 10px;
    height: 10px;
    fill: #777E90;
    transition: transform .2s; }

.faq1__body {
  display: none;
  padding: 24px 0 0 40px;
  color: #777E90; }

.wishlists .control {
  margin-bottom: 32px; }
  @media only screen and (max-width: 767px) {
    .wishlists .control {
      margin-bottom: 24px; } }

.wishlists__line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 64px; }
  @media only screen and (max-width: 767px) {
    .wishlists__line {
      display: block;
      margin-bottom: 32px; } }

@media only screen and (max-width: 767px) {
  .wishlists__box {
    margin-bottom: 32px; } }

.wishlists__details {
  margin-bottom: 64px; }
  @media only screen and (max-width: 767px) {
    .wishlists__details {
      margin-bottom: 32px; } }

.wishlists__title {
  margin-bottom: 12px; }

.wishlists__center > .wishlists__title {
  margin-bottom: 64px; }
  @media only screen and (max-width: 767px) {
    .wishlists__center > .wishlists__title {
      margin-bottom: 32px; } }

.wishlists__counter {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #777E90; }

.wishlists__sorting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 64px; }
  @media only screen and (max-width: 767px) {
    .wishlists__sorting {
      display: block;
      margin-bottom: 32px; } }

@media only screen and (max-width: 767px) {
  .wishlists__sorting .wishlists__select:not(:last-child) {
    margin-bottom: 12px; } }

@media only screen and (max-width: 1023px) {
  .wishlists .nav {
    display: none; } }

.wishlists__item {
  display: none; }

.wishlists__list {
  display: flex;
  flex-wrap: wrap;
  margin: -32px -16px 0; }
  @media only screen and (max-width: 767px) {
    .wishlists__list {
      display: block;
      margin: 0; } }

.wishlists__list .card {
  flex: 0 0 calc(33.333% - 32px);
  width: calc(33.333% - 32px);
  margin: 32px 16px 0; }
  @media only screen and (max-width: 1023px) {
    .wishlists__list .card {
      flex: 0 0 calc(50% - 32px);
      width: calc(50% - 32px); } }
  @media only screen and (max-width: 767px) {
    .wishlists__list .card {
      width: 100%;
      margin: 32px 0 0; } }

.wishlists__wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -32px -16px 0; }
  @media only screen and (max-width: 767px) {
    .wishlists__wrap {
      display: block;
      margin: 0; } }

.wishlists__wrap .card {
  flex: 0 0 calc(50% - 32px);
  width: calc(50% - 32px);
  margin: 32px 16px 0; }
  @media only screen and (max-width: 767px) {
    .wishlists__wrap .card {
      width: 100%;
      margin: 32px 0 0; } }

.wishlists__btns {
  margin-top: 48px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .wishlists__btns {
      margin-top: 32px; } }

.wishlists__btns .wishlists__button .loader {
  margin: 0 16px 0 2px;
  transform: scale(0.8); }

.wishlists__group .flight:not(:last-child) {
  margin-bottom: 24px; }

.smile {
  position: relative; }
  .smile.active .smile__button .icon:first-child {
    display: none; }
  .smile.active .smile__button .icon:nth-child(2) {
    display: inline-block; }
  .smile.active .smile__body {
    visibility: visible;
    opacity: 1; }
  .smile__button .icon {
    width: 24px;
    height: 24px;
    fill: #777E90;
    transition: fill .2s; }
    .smile__button .icon:nth-child(2) {
      display: none; }
  .smile__button:hover .icon {
    fill: #3B71FE; }
  .smile__body {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 10px);
    width: 188px;
    padding: 0 10px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%);
    border-radius: 32px;
    background: #FCFCFD;
    box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.2);
    visibility: hidden;
    opacity: 0;
    transition: all .2s; }
    .smile__body:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: #FCFCFD transparent transparent transparent; }
    body.dark .smile__body {
      background: #23262F; }
      body.dark .smile__body:after {
        border-color: #23262F transparent transparent transparent; }
  .smile__item {
    margin: 12px 6px 6px;
    cursor: pointer; }
