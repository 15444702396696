.footer {
    border-top: 1px solid $n6;
    @include dark {
        border-color: $n2; }
    &__row {
        display: flex;
        padding: 80px 0 48px;
        @include m {
            display: block;
            padding-top: 64px; } }
    &__col {
        &:first-child {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 256px;
            @include m {
                flex-direction: row;
                width: 100%;
                margin-bottom: 48px; } }
        &:nth-child(2) {
            flex-grow: 1;
            padding: 0 16px;
            @include m {
                margin-bottom: 48px;
                padding: 0; } }
        &:nth-child(3) {
            width: 256px;
            @include m {
                width: 100%; } } }
    &__logo {
        margin: -8px 0 16px;
        @include m {
            margin-right: auto; } }
    &__theme {
        display: flex;
        align-items: center;
        @include button-2;
        color: $n4;
        .icon {
            width: 16px;
            height: 16px;
            margin-right: 8px;
            fill: $n4; }
        .theme {
            margin-left: 8px; } }
    &__menu {
        display: flex;
        flex-wrap: wrap;
        margin-top: -32px;
        @include m {
            margin: -32px -16px 0; } }
    &__link {
        flex: 0 0 calc(33.333% - 32px);
        width: calc(33.333% - 32px);
        margin: 32px 16px 0;
        @include button-2;
        color: $n4;
        transition: color .2s;
        @include t {
            flex: 0 0 calc(50% - 32px);
            width: calc(50% - 32px); }
        &:hover {
            color: $n2;
            @include dark {
                color: $n6; } }
        &:nth-child(3) {
            @include t {
                order: 5; } } }
    &__info {
        margin-bottom: 16px;
        @include hairline-2; }
    &__bottom {
        padding: 24px 0;
        border-top: 1px solid $n7;
        @include dark {
            border-color: $n2; } }
    &__copyright {
        @include caption-2;
        @include dark {
            color: $n4; } } }
