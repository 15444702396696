.login {
    &__item {
        display: none;
        &:first-child {
            display: block; } }
    &__title {
        text-align: center; }
    &__info {
        margin-top: 12px;
        text-align: center;
        @include body-2;
        color: $n4; }
    &__btns {
        display: flex;
        justify-content: center;
        margin-top: 32px;
        .login__button {
            .icon {
                width: 16px;
                height: 16px; }
            &:not(:last-child) {
                margin-right: 12px; } } }
    &__note {
        margin-top: 32px;
        text-align: center;
        @include caption-2;
        color: $n4; }
    .subscription {
        margin-top: 32px; }
    &__form {
        margin-top: 32px;
        & > .button {
            width: 100%;
            margin-top: 12px; }
        .field__input {
            border-radius: 24px; } }
    &__foot {
        margin-top: 32px;
        text-align: center;
        @include caption-2;
        color: $n3;
        @include dark {
            color: $n6; } }
    &__link,
    &__password {
        font-weight: 600;
        transition: color .2s; }
    &__link {
        color: $p1;
        &:hover {
            color: darken($p1, 10); } }
    &__password {
        color: $n3;
        &:hover {
            color: $n1; }
        @include dark {
            color: $n6;
            &:hover {
                color: $p1; } } }
    &__variants {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px; }
    .radio {
        &:not(:last-child) {
            margin-bottom: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid $n6;
            @include dark {
                border-color: $n3; } } }
    &__code {
        display: flex;
        justify-content: center; }
    &__number {
        flex-shrink: 0;
        width: 48px;
        input {
            width: 100%;
            height: 64px;
            border-radius: 8px;
            border: 1px solid $n7;
            background: $n7;
            text-align: center;
            @include dm-sans;
            font-size: 32px;
            font-weight: 600;
            color: $n2;
            transition: all .2s;
            &:focus {
                border-color: $n6;
                background: $n8; }
            @include dark {
                background: $n2;
                border-color: $n2;
                color: $n8;
                &:focus {
                    background: none;
                    border-color: $n3; } } }
        &:not(:last-child) {
            margin-right: 16px; } } }
