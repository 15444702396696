.description {
    padding-bottom: 136px;
    border-bottom: 1px solid $n6;
    @include d {
        margin-bottom: 80px;
        padding-bottom: 80px; }
    @include m {
        margin-bottom: 64px;
        padding-bottom: 64px; }
    @include dark {
        border-color: $n2; }
    &__center {
        display: flex;
        align-items: flex-start;
        @include t {
            display: block; } }
    &__wrapper {
        flex: 0 0 calc(100% - 448px);
        max-width: calc(100% - 448px);
        padding-right: 48px;
        overflow: hidden;
        @include d {
            flex: 0 0 calc(100% - 400px);
            max-width: calc(100% - 400px);
            padding-right: 32px; }
        @include t {
            max-width: 100%;
            margin-bottom: 48px;
            padding-right: 0;
            overflow: visible; } }
    &__title {
        margin-bottom: 8px; }
    &__profile {
        display: flex;
        align-items: center;
        span {
            color: $n4; } }
    &__avatar {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin: 0 8px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover; } }
    &__name {
        @include body-bold-2; }
    &__parameters {
        display: flex;
        flex-wrap: wrap;
        margin: 24px 0 40px 0;
        padding-top: 14px;
        border-top: 1px solid $n6;
        @include dark {
            border-color: $n2; } }
    &__parameter {
        display: flex;
        align-items: center;
        margin-top: 10px;
        color: $n4;
        .icon {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            fill: $n4; }
        @include nf {
            margin-left: 16px; } }
    &__content {
        margin-bottom: 48px;
        @include body-2;
        p {
            color: $n4;
            &:not(:last-child) {
                margin-bottom: 24px; } }
        h4 {
            margin-bottom: 32px;
            @include dm-sans;
            font-size: 32px;
            line-height: 40px; }
        &_hide {
            display: none; } }
    &__full &__content {
        margin: 0; }
    &__info {
        margin-bottom: 32px;
        @include body-bold-1; }
    &__options {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: -24px 0 48px;
        @include m {
            display: block;
            margin-top: 0; }
        &:before {
            content: "";
            position: absolute;
            top: 24px;
            left: 44%;
            bottom: 0;
            width: 1px;
            background: $n6;
            @include dark {
                background: $n2; }
            @include m {
                display: none; } } }
    &__option {
        display: flex;
        margin-top: 24px;
        color: $n4;
        .icon {
            width: 24px;
            height: 24px;
            margin-right: 16px;
            fill: $n4; }
        &:nth-child(2n+1) {
            flex: 0 0 44%;
            padding-right: 40px;
            @include x {
                padding-right: 24px; }
            @include m {
                padding: 0; } }
        &:nth-child(2n) {
            flex: 0 0 56%;
            padding-left: 80px;
            @include x {
                padding-left: 24px; }
            @include m {
                padding: 0; } } }
    &__button {
        @include dark {
            box-shadow: inset 0 0 0 2px $n3;
            &:hover {
                background: $n3; } } }
    &__list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0 -20px;
        @include m {
            display: block;
            margin: 24px 0 0; } }
    &__item {
        display: flex;
        align-items: center;
        flex: 0 0 calc(50% - 40px);
        width: calc(50% - 40px);
        margin: 32px 20px 0;
        @include body-2;
        color: $n4;
        @include d {
            flex: 0 0 calc(100% - 40px);
            width: calc(100% - 40px); }
        @include t {
            flex: 0 0 calc(50% - 40px);
            width: calc(50% - 40px); }
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 16px; } } }
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        margin-right: 20px;
        border-radius: 50%;
        border-width: 2px;
        border-style: solid;
        .icon {
            width: 24px;
            height: 24px;
            fill: $n2;
            @include dark {
                fill: $n8; } } }
    &__section {
        margin-top: 64px;
        padding-top: 64px;
        border-top: 1px solid $n6;
        @include d {
            margin-top: 32px;
            padding-top: 32px; }
        @include dark {
            border-color: $n3; } }
    &__section &__content {
        margin-bottom: 24px; }
    &__section &__title {
        margin-bottom: 32px; }
    &__inner {
        margin: 0 -16px;
        @include m {
            margin: 0 -8px; } }
    &__slider {
        padding-bottom: 72px;
        visibility: hidden;
        &.slick-initialized {
            visibility: visible; } }
    &__slide {
        padding: 32px;
        border-radius: 24px;
        @include d {
            padding: 24px; }
        @include dark {
            color: $n2; } }
    &__preview {
        margin-bottom: 64px;
        @include t {
            margin-bottom: 32px; }
        .icon {
            width: 32px;
            height: 32px; } }
    &__subtitle {
        margin-bottom: 4px;
        @include body-bold-1; }
    &__slide &__text {
        @include caption-2; }
    .slick-list {
        overflow: visible;
        @include d {
            margin-right: -80px; }
        @include t {
            margin-right: 0; }
        @include m {
            margin-right: -160px; } }
    .slick-track {
        display: flex; }
    .slick-slide {
        height: auto;
        margin: 0 16px;
        opacity: 0;
        visibility: hidden;
        transition: all .4s;
        @include m {
            margin: 0 8px; }
        &.slick-active {
            opacity: 1;
            visibility: visible; } }
    .slick-arrow {
        bottom: 0; }
    .slick-prev {
        left: 16px; }
    .slick-next {
        left: 64px; } }

