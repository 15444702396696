.messages {
    position: relative;
    display: flex;
    padding-left: 396px;
    @include x {
        padding-left: 360px; }
    @include d {
        padding-left: 148px; }
    &__users {
        position: fixed;
        top: 88px;
        left: 0;
        bottom: 0;
        width: 396px;
        padding: 105px 0 0;
        box-shadow: inset -1px 0 0 0 $n6;
        @include x {
            width: 360px;
            padding-top: 80px; }
        @include d {
            width: 148px; }
        @include m {
            top: 112px;
            width: 100%; }
        @include dark {
            box-shadow: inset -1px 0 0 0 $n2; } }
    &__wrapper {
        position: relative;
        height: calc(100vh - 88px);
        flex-grow: 1;
        padding-bottom: 104px;
        @include x {
            padding-bottom: 80px; }
        @include m {
            position: fixed;
            top: 112px;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 3;
            height: auto;
            background: $n8;
            transform: translateX(100%);
            transition: transform .3s;
            @include dark {
                background: $n1; }
            &.show {
                transform: translateX(0); } } }
    .place {
        flex-shrink: 0;
        width: 496px;
        padding: 48px 48px 48px 0;
        height: calc(100vh - 88px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        @include x {
            width: 364px;
            padding: 32px 32px 32px 0; }
        @include t {
            display: none; } }
    &__toggle {
        position: absolute;
        top: 48px;
        left: 48px;
        display: flex;
        align-items: center;
        @include body-bold-1;
        color: $n2;
        transition: color .2s;
        @include x {
            top: 24px;
            left: 32px; }
        @include d {
            left: 50%;
            transform: translateX(-50%);
            font-size: 0; }
        @include m {
            left: 32px;
            transform: translateX(0);
            font-size: 24px; }
        .icon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            transition: fill .2s;
            @include d {
                margin-right: 0; }
            @include m {
                margin-right: 8px; } }
        @include dark {
            color: $n8;
            .icon {
                fill: $n8; } }
        &:hover {
            color: $p1;
            .icon {
                fill: $p1; } } }
    &__group {
        max-height: 100%;
        padding: 0 48px 48px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        @include x {
            padding: 0 32px 32px; }
        @include d {
            display: flex;
            align-items: center;
            flex-direction: column; }
        @include m {
            display: block;
            padding: 0 32px 32px; } }
    &__item {
        display: flex;
        align-items: flex-start;
        padding: 24px 20px;
        border-radius: 16px;
        cursor: pointer;
        transition: all .2s;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        color: $n2;
        @include d {
            padding: 8px;
            border-radius: 50%; }
        @include m {
            padding: 24px 20px;
            border-radius: 16px; }
        @include dark {
            color: $n8; }
        &:not(:last-child) {
            margin-bottom: 12px;
            @include d {
                margin-bottom: 24px; }
            @include m {
                margin-bottom: 12px; } }
        &:hover,
        &.active {
            box-shadow: 0px 40px 32px -8px rgba(15, 15, 15, 0.12);
            @include dark {
                background: $n2; } } }
    &__avatar {
        flex-shrink: 0;
        margin-right: 16px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%; } }
    &__item &__avatar {
        width: 48px;
        height: 48px;
        @include d {
            margin-right: 0; }
        @include m {
            margin-right: 16px; } }
    &__details {
        flex: 0 0 calc(100% - 64px);
        max-width: calc(100% - 64px);
        @include d {
            display: none; }
        @include m {
            display: block; } }
    &__head {
        display: flex;
        margin-bottom: 4px; }
    &__man {
        margin-right: auto;
        @include body-bold-2; }
    &__rating {
        margin-left: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 50px;
        height: 24px;
        border-radius: 12px;
        background: $n8;
        box-shadow: inset 0 0 0 1px $n6;
        @include caption-bold-2;
        @include dark {
            background: $n2;
            box-shadow: inset 0 0 0 1px $n3; }
        .icon {
            width: 12px;
            height: 12px;
            margin-right: 4px;
            fill: $s3; } }
    &__item &__date {
        margin-bottom: 4px;
        @include caption-2;
        color: $n4; }
    &__content {
        @include text-overflow;
        @include caption-2; }
    &__inner {
        max-height: 100%;
        padding: 48px 48px 40px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        @include x {
            padding: 32px 24px; }
        @include t {
            padding: 32px; } }
    &__back {
        display: none;
        @include m {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            @include body-bold-1;
            .icon {
                width: 24px;
                height: 24px;
                margin-right: 10px;
                fill: $n2; }
            @include dark {
                color: $n8;
                .icon {
                    fill: $n8; } } } }
    .subscription {
        position: absolute;
        left: 48px;
        right: 48px;
        bottom: 48px;
        @include x {
            left: 24px;
            right: 24px;
            bottom: 24px; } }
    &__wrapper &__date {
        margin-bottom: 24px;
        text-align: center;
        @include caption-bold-2;
        @include x {
            margin-bottom: 0; } }
    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
    &__author {
        display: flex;
        margin-top: 24px;
        .messages__text {
            margin-top: 0;
            background: $n7;
            color: $n2;
            @include dark {
                background: $n2;
                color: $n8; } } }
    &__author &__avatar {
        width: 32px;
        height: 32px; }
    &__text {
        margin-top: 24px;
        margin-left: auto;
        padding: 16px 24px;
        border-radius: 32px;
        background: $p1;
        color: $n8; }
    &__time {
        width: 100%;
        margin-top: 8px;
        text-align: right;
        @include caption-bold-2;
        color: $n5; } }






