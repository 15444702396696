.flights {
    .control {
        margin-bottom: 32px;
        padding: 0; }
    &__sorting {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 64px;
        padding-top: 32px;
        border-top: 1px solid $n6;
        @include m {
            display: block;
            margin-bottom: 32px;
            padding-top: 0;
            border: none; }
        @include dark {
            border-color: $n3; }
        .flights__button {
            display: none;
            @include m {
                display: flex;
                width: 100%; } } }
    .nav {
        @include t {
            display: none; } }
    &__select {
        flex-shrink: 0;
        width: 256px;
        @include m {
            width: 100%; }
        @include nl {
            @include m {
                margin-bottom: 16px; } } }
    &__row {
        display: flex;
        @include m {
            display: block; } }
    &__filters {
        flex-shrink: 0;
        width: 256px;
        @include m {
            display: none;
            width: 100%;
            padding-bottom: 40px;
            &.show {
                display: block; } } }
    &__box {
        padding-bottom: 48px;
        border-bottom: 1px solid $n6;
        @include m {
            padding-bottom: 24px; }
        @include dark {
            border-color: $n3; }
        &:not(:last-child) {
            margin-bottom: 48px;
            @include m {
                margin-bottom: 24px; } } }
    .range {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include m {
                margin-bottom: 24px; } } }
    &__variants {
        display: flex;
        flex-direction: column;
        .checkbox {
            width: 100%;
            &:not(:last-child) {
                margin-bottom: 16px; } } }
    &__reset {
        display: inline-flex;
        align-items: center;
        margin-top: 24px;
        @include button-2;
        color: $n4;
        transition: color .2s;
        .icon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            fill: $n4;
            transition: fill .2s; }
        &:hover {
            color: $n2;
            .icon {
                fill: $n2; }
            @include dark {
                color: $n6;
                .icon {
                    fill: $n6; } } } }
    &__container {
        flex-grow: 1;
        padding-left: 80px;
        @include x {
            padding-left: 40px; }
        @include d {
            padding-left: 32px; }
        @include m {
            margin: 0 -16px;
            padding: 0; } }
    .flight {
        &:not(:last-child) {
            margin-bottom: 24px; } }
    &__btns {
        margin-top: 40px;
        text-align: center;
        @include m {
            margin-top: 32px; } }
    &__btns &__button {
        .loader {
            margin: 0 20px 0 4px; } } }


