.browse {
    &__inner {
        position: relative; }
    &__head {
        margin-bottom: 80px;
        padding-right: 120px;
        @include d {
            margin-bottom: 64px; }
        @include m {
            margin-bottom: 40px;
            padding: 0; }
        &_small {
            margin-bottom: 48px;
            @include m {
                margin-bottom: 40px; } } }
    &__info {
        margin-top: 12px; }
    &__wrapper {
        margin: 0 -16px;
        @include m {
            margin: 0 -8px; } }
    &__card {
        display: block;
        border-radius: 24px;
        color: $n2;
        transition: color .2s;
        &:hover {
            color: $p1;
            .browse__preview {
                img {
                    transform: scale(1.1); } } }
        @include dark {
            color: $n8;
            &:hover {
                color: $p1; } } }
    &__preview {
        position: relative;
        margin-bottom: 20px;
        overflow: hidden;
        border-radius: 24px;
        &:before {
            content: "";
            display: block;
            padding-bottom: 100%; }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: transform 1s; } }
    &__category {
        position: absolute;
        top: 16px;
        left: 16px;
        z-index: 2; }
    &__subtitle {
        margin-bottom: 8px;
        @include body-bold-2; }
    &__content {
        display: flex;
        align-items: center;
        @include caption-bold-2;
        color: $n4;
        .icon {
            width: 16px;
            height: 16px;
            margin: -2px 6px 0 0;
            fill: $n4; } }
    &__slider {
        position: static;
        visibility: hidden;
        @include m {
            padding-bottom: 80px; }
        &.slick-initialized {
            visibility: visible; } }
    .slick-list {
        overflow: visible;
        @include d {
            margin: 0 -180px 0 0; }
        @include m {
            margin-right: -260px; } }
    .slick-slide {
        margin: 0 16px;
        opacity: 0;
        visibility: hidden;
        transition: all .4s;
        @include m {
            margin: 0 8px; }
        &.slick-active {
            opacity: 1;
            visibility: visible; } }
    .slick-arrow {
       top: 4px;
       @include m {
            top: auto;
            bottom: 0; } }
    .slick-prev {
        right: 48px;
        @include m {
            right: auto;
            left: 0; } }
    .slick-next {
        right: 0;
        @include m {
            right: auto;
            left: 48px; } } }
