.categories {
    &__head {
        margin-bottom: 64px;
        text-align: center;
        @include d {
            margin-bottom: 48px; } }
    &__title {
        margin-bottom: 12px; }
    &__wrapper {
        margin: 0 -16px;
        @include m {
            margin: 0 -8px; } }
    &__slider {
        visibility: hidden;
        padding-bottom: 106px;
        @include d {
            padding-bottom: 88px; }
        @include m {
            padding-bottom: 80px; }
        &.slick-initialized {
            visibility: visible; } }
    &__item {
        display: block;
        padding: 32px;
        border-radius: 24px;
        border: 1px solid $n7;
        color: $n2;
        transition: all .2s;
        &:hover {
            border-color: $n6; }
        @include dark {
            background: $n2;
            border-color: $n2;
            color: $n8;
            &:hover {
                background: $n1; } } }
    &__counter {
        margin-bottom: 74px; }
    &__icon {
        display: flex;
        align-items: center;
        height: 32px;
        margin-bottom: 24px;
        .icon {
            width: 32px;
            height: 32px;
            fill: $n4;
            @include dark {
                fill: $n8; } } }
    &__subtitle {
        margin-bottom: 4px;
        @include body-bold-2; }
    &__content {
        @include caption-2;
        color: $n4; }
    .slick-list {
        overflow: visible;
        @include t {
            margin: 0 -150px 0 0; }
        @include m {
            margin-right: -220px; } }
    .slick-slide {
        margin: 0 16px;
        @include m {
            margin: 0 8px; } }
    .slick-arrow {
        bottom: 0; }
    .slick-prev {
        left: calc(50% - 44px); }
    .slick-next {
        right: calc(50% - 44px); } }
