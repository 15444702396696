.planning {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 750px;
    padding: 50px 0 150px;
    @include d {
        min-height: 700px; }
    @include t {
        min-height: 600px;
        padding: 50px 0 100px; }
    @include m {
        min-height: auto;
        padding: 0; }
    &__wrap {
        max-width: 440px;
        @include m {
            max-width: 100%;
            margin-bottom: 48px; } }
    &__title {
        margin-bottom: 32px; }
    &__info {
        margin-bottom: 40px;
        @include body-2;
        color: $n4; }
    .subscription {
        max-width: 300px; }
    &__gallery {
        position: absolute;
        top: 0;
        right: calc(50% - 600px);
        width: 643px;
        z-index: -1;
        @include d {
            width: 600px; }
        @include t {
            width: 500px; }
        @include m {
            position: relative;
            top: auto;
            right: auto;
            width: 100%; } }
    &__preview {
        &:nth-child(2) {
            left: 1.5%;
            bottom: 16.5%;
            width: 39.7%;
            filter: drop-shadow(0px 40px 64px rgba(15, 15, 15, 0.1)); }
        &:nth-child(3) {
            right: 10%;
            bottom: 27.5%;
            width: 30.8%;
            filter: drop-shadow(0px 40px 32px rgba(15, 15, 15, 0.12)); }
        &:not(:first-child) {
            position: absolute; }
        img {
            width: 100%; } } }
