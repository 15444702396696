.hosts {
    &__inner {
        position: relative; }
    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 48px;
        padding-right: 120px;
        @include t {
            flex-direction: column;
            align-items: flex-start; }
        @include m {
            margin-bottom: 32px;
            padding: 0; } }
    &__title {
        margin-right: 12px;
        @include m {
            font-size: 32px; } }
    .select-empty {
        width: auto;
        @include t {
            margin-left: -24px; } }
    &__wrapper {
        margin: 0 -16px;
        @include s {
            margin: 0 -8px; } }
    &__slider {
        position: static;
        visibility: hidden;
        @include m {
            position: relative;
            padding-bottom: 80px; }
        &.slick-initialized {
            visibility: visible; } }
    &__item {
        display: block;
        overflow: hidden;
        border-radius: 24px;
        border: 1px solid $n6;
        text-align: center;
        color: $n2;
        transition: border-color .2s;
        @include dark {
            background: $n2;
            border-color: $n3;
            color: $n8; }
        &:hover {
            border-color: $n5;
            @include dark {
                border-color: $n4; }
            .hosts__preview {
                img {
                    transform: scale(1.1); } } } }
    &__preview {
        position: relative;
        overflow: hidden;
        &:before {
            content: "";
            display: block;
            padding-bottom: 77%; }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: transform 1s; } }
    &__rating {
        position: absolute;
        top: 16px;
        left: 16px;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 50px;
        height: 24px;
        background: $n8;
        border-radius: 12px;
        @include caption-bold-2;
        @include dark {
            background: $n2;
            color: $n8; }
        .icon {
            width: 12px;
            height: 12px;
            margin-right: 4px;
            fill: $s3; } }
    &__body {
        padding: 0 16px 24px; }
    &__avatar {
        position: relative;
        z-index: 3;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 0 0 2px $n8;
        margin: -40px auto 16px;
        @include dark {
            box-shadow: 0 0 0 2px $n2; }
        img {
            width: 100%;
            height: 100%;
            transform: scale(1.02);
            border-radius: 50%; } }
    &__subtitle {
        margin-bottom: 4px;
        @include body-bold-2; }
    &__location {
        @include caption-2;
        color: $n4; }
    .slick-list {
        overflow: visible;
        @include t {
            margin: 0 -160px 0 0; }
        @include m {
            margin-right: -230px; } }
    .slick-slide {
        margin: 0 16px;
        opacity: 0;
        visibility: hidden;
        transition: all .4s;
        @include m {
            margin: 0 8px; }
        &.slick-active {
            opacity: 1;
            visibility: visible; } }
    .slick-arrow {
        top: 12px;
        @include m {
            top: auto;
            bottom: 0; } }
    .slick-prev {
        right: 48px;
        @include m {
            right: auto;
            left: 8px; } }
    .slick-next {
        right: 0;
        @include m {
            right: auto;
            left: 56px; } } }
