.work {
	&__head {
	    margin-bottom: 64px;
	    text-align: center;
	    @include m {
	        margin-bottom: 32px; } }
	&__body {
	    position: relative;
	    max-width: 1024px;
	    margin: 0 auto; }
	&__line {
	    position: absolute;
	    top: 66px;
	    left: 50%;
	    transform: translateX(-50%);
	    @include t {
	        top: 24px; }
	    @include m {
	        display: none; }
	    @include dark {
	        path {
	            stroke: $n3 !important; } } }
	&__list {
	    position: relative;
	    z-index: 3;
	    display: flex;
	    @include m {
	        display: block;
	        max-width: 256px;
	        margin: 0 auto; } }
	&__item {
	    flex: 0 0 256px;
	    text-align: center;
	    @include t {
	        flex: 0 0 200px; }
	    &:not(:last-child) {
	        margin-right: auto;
	        @include m {
	            margin: 0 0 64px; } } }
	&__preview {
	    img {
	        width: 100%; } }
	&__subtitle {
	    margin-bottom: 8px;
	    @include body-bold-1; }
	&__content {
	    color: $n4; } }
