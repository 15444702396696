.view {
    &__head {
        display: flex;
        align-items: flex-end;
        margin-bottom: 80px;
        @include d {
            margin-bottom: 64px; }
        @include m {
            display: block;
            margin-bottom: 32px; } }
    &__details {
        max-width: 540px;
        margin-right: auto; }
    &__button {
        @include m {
            width: 100%;
            margin-top: 32px; } }
    &__preview {
        display: block;
        position: relative;
        margin: 0 -80px;
        border-radius: 16px;
        overflow: hidden;
        @include x {
            margin: 0 -40px; }
        @include t {
            border-radius: 0; }
        @include m {
            margin: 0 -32px;
            &:before {
                content: "";
                display: block;
                padding-bottom: 65%; } }
        img {
            width: 100%;
            @include m {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                object-fit: cover; } } } }
