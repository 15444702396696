// common styles
body {
    min-width: 375px;
    background: $n8;
    @include poppins;
    font-size: 14px;
    line-height: (24/14);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $n2;
    &.dark {
        background: $n1;
        color: $n8; } }

a {
    text-decoration: none; }

svg,
img {
    vertical-align: middle; }

.outer {
    overflow: hidden;
    min-height: 100vh; }

.center {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 80px;
    @include t {
        padding: 0 40px; }
    @include m {
        padding: 0 32px; } }

.some-icon {
    @include dark {
        display: none; } }

.some-icon-dark {
    display: none;
    @include dark {
        display: inline-block; } }

.slick-arrow {
    position: absolute;
    z-index: 2;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 0;
    transition: all .2s;
    path {
        position: relative;
        z-index: 2;
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        box-shadow: inset 0 0 0 2px $n6;
        @include dark {
            box-shadow: inset 0 0 0 2px $n3;
            path {
                fill: $n6; } } } }

.rating {
    z-index: 1; }

.select {
    float: none;
    width: 100%;
    height: 48px;
    padding: 0 48px 0 16px;
    box-shadow: inset 0 0 0 2px $n6;
    background: $n8;
    border-radius: 12px;
    border: none;
    opacity: 1;
    font-size: 14px;
    font-weight: 500;
    line-height: 48px;
    &:after {
        display: none; }
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 8px;
        width: 32px;
        height: 32px;
        transform: translateY(-50%);
        border-radius: 50%;
        box-shadow: inset 0 0 0 2px $n6;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50% / 10px auto;
        transition: transform .2s; }
    @include dark {
        background: $n1;
        box-shadow: inset 0 0 0 2px $n3;
        -webkit-appearance: none;
        &:before {
            box-shadow: inset 0 0 0 2px $n3;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23FCFCFD'/%3E%3C/svg%3E"); } }
    &.open {
        box-shadow: inset 0 0 0 2px $n4;
        @include dark {
            box-shadow: inset 0 0 0 2px $n4; }
        &:before {
            transform: translateY(-50%) rotate(180deg); } }
    .current {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .list {
        right: 0;
        margin-top: 2px;
        border-radius: 12px;
        background: $n8;
        border: 2px solid $n6;
        box-shadow: 0 4px 12px rgba($n2, .1);
        @include dark {
            background: $n1;
            border-color: $n3;
            box-shadow: 0 4px 12px rgba($n1, .1); } }
    .option {
        min-height: auto;
        padding: 10px 14px;
        font-weight: 500;
        line-height: 1.4;
        &:hover,
        &.focus,
        &.selected.focus {
            background: $n7;
            @include dark {
                background: $n2; } }
        &.selected {
            font-weight: 500;
            color: $p1; } } }

.select-empty {
    float: none;
    width: 100%;
    height: 56px;
    padding: 0 64px 0 24px;
    box-shadow: none;
    background: transparent;
    border-radius: 32px;
    border: none;
    opacity: 1;
    @include dm-sans;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: $p1;
    @include m {
        height: 40px;
        font-size: 32px;
        line-height: 40px; }
    &:after {
        display: none; }
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 27px;
        width: 15px;
        height: 15px;
        transform: translateY(-50%);
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%233B71FE'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        transition: transform .2s; }
    &.open {
        background: $n8;
        @include dark {
            background: $n1; }
        &:before {
            transform: translateY(-50%) rotate(180deg); } }
    .current {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .list {
        right: 0;
        margin-top: 4px;
        border: none;
        border-radius: 24px;
        background: $n8;
        box-shadow: 0px 24px 32px 0 rgba(15, 15, 15, 0.15);
        @include dark {
            border: 2px solid $n1;
            background: $n1;
            box-shadow: 0 4px 12px rgba($n1, .1); } }
    .option {
        min-height: auto;
        padding: 10px 24px;
        border: none;
        @include dm-sans;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.2;
        color: $n4;
        @include m {
            font-size: 32px; }
        @include dark {
            padding: 10px 22px;
            color: $n8; }
        &:hover,
        &.focus,
        &.selected.focus {
            background: $n7;
            @include dark {
                background: lighten($n1, 2); } }
        &.selected {
            color: $p1;
            @include dark {
                color: $p1; } } } }

.play {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    width: 80px;
    height: 80px;
    transform: translate(-50%,-50%);
    box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1);
    border-radius: 50%;
    background: $n8;
    @include m {
        width: 48px;
        height: 48px; }
    .icon {
        width: 24px;
        height: 24px;
        fill: $n4;
        transition: all .2s;
        @include m {
            width: 12px;
            height: 12px; } }
    &:hover {
        .icon {
            transform: scale(1.2);
            fill: $p1; } }
    &_small {
        width: 48px;
        height: 48px;
        .icon {
            width: 12px;
            height: 12px; } } }

.fancybox {
    &-caption__body {
        @include body-bold-2; }
    &-image {
        border-radius: 16px; }
    &-infobar {
        @include dm-sans;
        font-size: 14px;
        font-weight: 700;
        color: $n4; }
    &-bg {
        background: $n1; }
    &-is-open &-bg {
        opacity: 1; } }
