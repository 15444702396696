.avatars {
    &__file {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 342px;
        padding: 32px;
        border-radius: 16px;
        border: 2px dashed $n5;
        overflow: hidden;
        text-align: center;
        @include m {
            height: 280px; }
        @include dark {
            background: $n2;
            border: 2px dashed $n3; } }
    &__load {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 400px;
        opacity: 0; }
    &__icon {
        margin-bottom: 24px;
        .icon {
            width: 48px;
            height: 48px;
            fill: $p1; } }
    &__format {
        @include body-bold-1;
        @include m {
            font-size: 18px; } }
    &__note {
        @include body-2;
        color: $n4;
        @include m {
            margin-top: 8px;
            font-size: 14px; } }
    &__gallery {
        margin-top: 32px; }
    &__info {
        margin-bottom: 16px;
        text-align: center;
        @include caption-2;
        color: $n4; }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -24px -16px 0;
        @include m {
            margin: -16px -8px 0; } }
    &__item {
        flex-shrink: 0;
        width: 64px;
        height: 64px;
        margin: 24px 16px 0;
        @include m {
            width: 40px;
            height: 40px;
            margin: 16px 8px 0; }
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover; } } }
