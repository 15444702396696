.topics {
    &__head {
        margin-bottom: 92px;
        text-align: center;
        @include m {
            margin-bottom: 40px; } }
    .stage {
        margin-bottom: 8px; }
    &__title {
        margin-bottom: 32px; }
    &__wrapper {
        margin: 0 -16px;
        @include m {
            margin: 0 -4px; } }
    &__item {
        padding: 64px 32px;
        border-radius: 20px;
        background: $n8;
        transition: box-shadow .2s;
        @include m {
            padding: 64px 24px; }
        @include dark {
            background: $n2; }
        &:hover {
            box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1); } }
    &__icon {
        display: flex;
        align-items: center;
        height: 48px;
        margin-bottom: 32px;
        @include m {
            margin-bottom: 24px; }
        img {
            max-height: 100%; } }
    &__subtitle {
        margin-bottom: 16px;
        @include body-bold-2; }
    &__content {
        color: $n4; }
    &__slider {
        padding-bottom: 64px;
        visibility: hidden;
        &.slick-initialized {
            visibility: visible; } }
    .slick-list {
        overflow: visible; }
    .slick-track {
        display: flex; }
    .slick-slide {
        height: auto;
        margin: 0 12px;
        opacity: 0;
        visibility: hidden;
        transition: all .4s;
        @include m {
            margin: 0 4px;
            opacity: 1;
            visibility: visible; }
        &.slick-active {
            opacity: 1;
            visibility: visible; } }
    .slick-arrow {
        bottom: 0;
        width: 24px;
        height: 24px;
        &:hover {
            box-shadow: none;
            path {
                fill: $n2;
                @include dark {
                    fill: $n8; } } } }
    .slick-prev {
        left: calc(50% - 34px); }
    .slick-next {
        right: calc(50% - 34px); } }
