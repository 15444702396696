.field {
    &__label {
        margin-bottom: 12px;
        @include hairline-2;
        color: $n5; }
    &__wrap {
        position: relative; }
    &__input,
    &__textarea {
        width: 100%;
        border-radius: 12px;
        border: 2px solid $n6;
        box-shadow: none;
        background: none;
        @include poppins;
        @include caption-bold-1;
        color: $n2;
        transition: border-color .2s;
        @include dark {
            border-color: $n3;
            color: $n8; }
        @include placeholder {
            color: $n4; }
        &:focus {
            border-color: $n4;
            @include dark {
                border-color: $n4; } } }
    &__input {
        height: 48px;
        padding: 0 14px; }
    &__textarea {
        height: 140px;
        padding: 10px 14px;
        resize: none; }
    &__button {
        position: absolute;
        right: 12px;
        bottom: 8px;
        height: 32px;
        padding: 0 12px;
        font-size: 14px;
        @include dark {
            box-shadow: inset 0 0 0 2px $n3; } }
    &__map {
        position: absolute;
        right: 16px;
        bottom: 16px;
        @include button-2;
        transition: color .2s;
        @include m {
            bottom: calc(100% + 10px);
            right: 0; }
        @include dark {
            color: $n8; }
        &:hover {
            color: $p1;
            @include dark {
                color: $p1; } } }
    &__view {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 48px;
        .icon {
            width: 24px;
            height: 24px;
            fill: $n5;
            transition: fill .2s; }
        &:hover {
            .icon {
                fill: $n4; } }
        &.active {
            .icon {
                fill: $n1; } }
        @include dark {
            .icon {
                fill: $n4; }
            &:hover {
                .icon {
                    fill: $n5; } }
            &.active {
                .icon {
                    fill: $n8; } } } }
    &_map &__input {
        padding-right: 115px; }
    &_button &__input {
        padding-right: 150px; }
    &_textarea {
        font-size: 0; }
    &_empty {
        .field__input {
            border: none;
            height: 26px; }
        .select {
            height: 26px;
            padding: 0 36px 0 14px;
            box-shadow: none;
            background: none;
            line-height: 26px;
            @include dark {
                box-shadow: none;
                background: none; }
            &:before {
                right: 12px;
                width: 24px;
                height: 24px;
                box-shadow: none;
                @include dark {
                    box-shadow: none; } } }
        .option {
            padding: 8px 14px; } }
    &_view {
        .field__input {
            padding-right: 48px; } } }

