.travel {
    &__head {
        max-width: 640px;
        margin: 0 auto 127px;
        text-align: center;
        @include x {
            margin-bottom: 80px; }
        @include m {
            margin-bottom: 48px;
            text-align: left; } }
    &__row {
        display: flex;
        align-items: center;
        @include t {
            display: block; } }
    &__col {
        &:first-child {
            flex-grow: 1;
            padding-right: 128px;
            @include t {
                margin-bottom: 64px;
                padding: 0; } }
        &:nth-child(2) {
            flex-shrink: 0;
            width: 454px;
            @include t {
                width: 100%; } } }
    &__list {
        max-width: 352px;
        margin-bottom: 40px;
        @include t {
            max-width: 100%; } }
    &__item {
        &:not(:last-child) {
            margin-bottom: 40px; } }
    &__number {
        display: inline-block;
        min-width: 38px;
        margin-bottom: 24px;
        padding: 0 8px;
        border-radius: 12px;
        text-align: center;
        font-weight: 500;
        line-height: 24px;
        color: $n8; }
    &__subtitle {
        margin-bottom: 16px;
        @include body-bold-1; }
    &__text {
        color: $n4; }
    &__slider {
        visibility: hidden;
        &.slick-initialized {
            visibility: visible; } }
    &__gallery {
        position: relative;
        img {
            width: 100%; } }
    &__bg {
        overflow: hidden;
        border-radius: 32px;
        img {
            transition: all 1s cubic-bezier(0.7, 0, 0.3, 1); } }
    &__preview {
        position: absolute;
        width: 70.5%;
        filter: drop-shadow(0px 40px 64px rgba(15, 15, 15, 0.1));
        transform: scale(.8);
        opacity: 0;
        transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        @include t {
            width: 50%; }
        @include m {
            width: 60%; }
        &:first-child {
            top: 40%;
            left: -24%;
            transition-delay: .3s;
            @include d {
                left: -8%; } }
        &:nth-child(2) {
            top: 48%;
            right: -25%;
            transition-delay: .6s;
            @include t {
                right: -15%; } }
        &:nth-child(3) {
            left: -40%;
            bottom: 17%;
            transition-delay: .9s;
            @include d {
                left: -22%; }
            @include t {
                left: -10%; }
            @include m {
                left: 5%; } } }
    .slick-list {
        overflow: visible; }
    .slick-slide {
        opacity: 0;
        visibility: hidden;
        transition: all .4s;
        &.slick-active {
            opacity: 1;
            visibility: visible;
            .travel__preview {
                opacity: 1; } } }
    .slick-arrow {
        top: 24px;
        path {
            fill: $n8; }
        @include dark {
            &:hover {
                box-shadow: inset 0 0 0 2px $n6; } } }
    .slick-prev {
        right: 72px; }
    .slick-next {
        right: 24px; } }
