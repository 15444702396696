.faq1 {
    &__title {
        margin-bottom: 48px;
        text-align: center;
        @include m {
            margin-bottom: 40px; } }
    .nav {
        justify-content: center;
        margin-bottom: 40px;
        @include m {
            display: none; } }
    .select {
        display: none;
        text-align: left;
        @include m {
            display: block;
            margin-bottom: 24px; } }
    &__list {
        max-width: 546px;
        margin: 0 auto; }
    &__item {
        &:hover {
            .faq1__head {
                color: $p1; }
            .faq1__number {
                color: $p1; } }
        &.active {
            .faq1__number {
                color: $p1; }
            .faq1__arrow {
                .icon {
                    transform: rotate(180deg); } } }
        &:not(:last-child) {
            .faq1__head {
                border-bottom: 1px solid $n6;
                @include dark {
                    border-color: $n3; } } } }
    &__head {
        position: relative;
        display: flex;
        align-items: center;
        padding: 24px 0;
        @include body-bold-2;
        cursor: pointer;
        transition: color .2s;
        -webkit-tap-highlight-color: rgba(0,0,0,0); }
    &__number {
        flex-shrink: 0;
        width: 40px;
        color: $n4;
        transition: color .2s; }
    &__subtitle {
        flex-grow: 1; }
    &__arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 24px;
        margin-left: 16px;
        .icon {
            width: 10px;
            height: 10px;
            fill: $n4;
            transition: transform .2s; } }
    &__body {
        display: none;
        padding: 24px 0 0 40px;
        color: $n4; } }
